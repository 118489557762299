import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import { ClipLoader } from "react-spinners";

import { connect } from "react-redux";
import oldproject_data from "actions/survey/oldproject_data.js";

// import surveyType from "actions/survey/setSurveyType.js";
// import project_datatable from "actions/survey/psurvey_editproj.js";
// import loading_datatable from "actions/survey/loading_datatable.js";
import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.js";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    var width;
    if (this.props.steps.length === 1) {
      width = "100%";
    } else {
      if (window.innerWidth < 600) {
        if (this.props.steps.length !== 3) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      } else {
        if (this.props.steps.length === 2) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      }
    }
    this.state = {
      currentStep: 0,
      isResubmiting: false,
      color: this.props.color,
      nextButton: this.props.steps.length > 1 ? true : false,
      previousButton: false,
      finishButton: this.props.steps.length === 1 ? true : false,
      width: width,
      movingTabStyle: {
        transition: "transform 0s",
      },
      allStates: {},
      btLoading: false,
    };
    this.navigationStepChange = this.navigationStepChange.bind(this);
    this.refreshAnimation = this.refreshAnimation.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.finishButtonClick = this.finishButtonClick.bind(this);
    this.updateWidth = this.updateWidth.bind(this);
  }
  wizard = React.createRef();
  async componentDidMount() {
    // console.log('isResubmiting?')
    // console.log(this.state.isResubmiting)
    // console.log(await this[this.props.steps[this.state.currentStep].stepId].isResubmiting())
    const resubmitState = await this[
      this.props.steps[this.state.currentStep].stepId
    ].isResubmiting();
    if (this.state.isResubmiting !== resubmitState) {
      // console.log('this is resubmit componentDidMount')
      this.setState({ isResubmiting: resubmitState });
    }
    this.refreshAnimation(0);
    window.addEventListener("resize", this.updateWidth);
  }

  async componentDidUpdate(previousProps, previousStates) {
    // console.log(this.props.proj_dataTable)
    // console.log(this.props.project_id)
    if (
      previousProps.project_id !== this.props.project_id &&
      previousProps.project_id !== undefined &&
      this.props.proj_dataTable
    ) {
      // window.location.replace("/admin/survey");
    }
    if (
      previousProps.proj_dataTable !== this.props.proj_dataTable &&
      this.props.proj_dataTable === undefined
    ) {
      // window.location.replace("/admin/survey");
    }
    if (
      this.props.proj_dataTable &&
      this.props.proj_dataTable.data &&
      this.props.proj_dataTable.data[0]
    ) {
      if (
        this.props.proj_dataTable.data[0].household_projectid &&
        this.props.project_id &&
        this.props.proj_dataTable.data[0].household_projectid !==
          this.props.project_id
      ) {
        // window.location.replace("/admin/survey");
      }
      if (
        this.props.proj_dataTable.data[0].condo_projectid &&
        this.props.project_id &&
        this.props.proj_dataTable.data[0].condo_projectid !==
          this.props.project_id
      ) {
        // window.location.replace("/admin/survey");
      }
      if (
        this.props.proj_dataTable.data[0].villa_projectid &&
        this.props.project_id &&
        this.props.proj_dataTable.data[0].villa_projectid !==
          this.props.project_id
      ) {
        // window.location.replace("/admin/survey");
      }
    }
    // console.log(this.state.currentStep)
    // console.log(this.props.steps[this.state.currentStep].stepId)
    // if(previousProps.steps[this.state.currentStep].stepId!==this.props.steps[this.state.currentStep].stepId){
    //if(this.state.isResubmiting!==previousStates.isResubmiting){
    if (
      this.state.isResubmiting !==
      (await this[
        this.props.steps[this.state.currentStep].stepId
      ].isResubmiting())
    ) {
      // console.log("this is resubmit");
      this.setState({
        isResubmiting: await this[
          this.props.steps[this.state.currentStep].stepId
        ].isResubmiting(),
      });
    }
    //}
    // }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  }
  updateWidth() {
    this.refreshAnimation(this.state.currentStep);
  }
  async navigationStepChange(key) {
    // console.log(key);
    // console.log(this.state.currentStep)
    // if(Math.abs(key-this.state.currentStep)!==1){
    //   return;
    // }
    // if (key > this.state.currentStep) {
    //   key = this.state.currentStep + 1;
    // } else if (key < this.state.currentStep) {
    //   key = this.state.currentStep - 1;
    // }

    if (this.props.steps) {
      var validationState = true;
      if (key > this.state.currentStep) {
        for (var i = this.state.currentStep; i < key; i++) {
          if (this[this.props.steps[i].stepId].sendState !== undefined) {
            this.setState({
              allStates: {
                ...this.state.allStates,
                [this.props.steps[i].stepId]: this[
                  this.props.steps[i].stepId
                ].sendState(),
              },
            });
          }
          // console.log(this.state.isResubmiting)
          if (
            (await this[
              this.props.steps[this.state.currentStep].stepId
            ].isEditable()) &&
            !this.state.isResubmiting
          ) {
            validationState = false;
            break;
          }
        }
        // console.log("validationState")
      }
      // console.log(validationState)
      if (
        validationState &&
        (await this[
          this.props.steps[this.state.currentStep].stepId
        ].isValidated())
      ) {
        this.setState({
          currentStep: key,
          nextButton: this.props.steps.length > key + 1 ? true : false,
          previousButton: key > 0 ? true : false,
          finishButton: this.props.steps.length === key + 1 ? true : false,
        });
        this.refreshAnimation(key);
      }
    }
  }
  async nextButtonClick() {
    this.setState({
      btLoading: true,
    });
    var result = false;
    if (this.state.currentStep === 0) {
      if (
        //this[this.props.steps[this.state.currentStep].stepId].check_projecyIdExist(this.props.project_id) &&
        await this[
          this.props.steps[this.state.currentStep].stepId
        ].checkFullBodyNoImage()
      ) {
        // console.log('save b4 advance')
        result = true;
        await this[
          this.props.steps[this.state.currentStep].stepId
        ].savebutton();
      } else {
        // if(this[this.props.steps[this.state.currentStep].stepId].isResubmiting()){
        //   this.finishButtonClick()
        // }else{
        await this[this.props.steps[this.state.currentStep].stepId].surveyModal(
          "save"
        );
        // }
        return this.setState({
          btLoading: false,
        });
      }
    } else {
      result = await this[
        this.props.steps[this.state.currentStep].stepId
      ].isValidated();
    }
    // if (result === false) {
    //   return this.setState({
    //     btLoading: false
    //   });
    // }
    // this.setState({
    //   btLoading: true
    // });

    // if(this.state.currentStep===0 && result){

    //   await this[this.props.steps[this.state.currentStep].stepId].submit_save();
    //   this[this.props.steps[this.state.currentStep].stepId].surveyModal('save');

    // }

    if (
      (this.props.validate &&
        ((this[this.props.steps[this.state.currentStep].stepId].isValidated !==
          undefined &&
          result) ||
          this[this.props.steps[this.state.currentStep].stepId].isValidated ===
            undefined)) ||
      this.props.validate === undefined
    ) {
      if (
        this[this.props.steps[this.state.currentStep].stepId].sendState !==
        undefined
      ) {
        this.setState({
          allStates: {
            ...this.state.allStates,
            [this.props.steps[this.state.currentStep].stepId]: await this[
              this.props.steps[this.state.currentStep].stepId
            ].sendState(),
          },
        });
      }
      var key = this.state.currentStep + 1;
      this.setState({
        // btLoading: false,
        currentStep: key,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false,
      });
      await this.refreshAnimation(key);
    }
    if (this.props.onNextStepClick) {
      await this.props.onNextStepClick(this.state.currentStep);
    }
    // if (result === false) {
    this.setState({
      btLoading: false,
    });
    // }
  }
  async previousButtonClick() {
    var result = true;
    this.setState({
      btLoading: true,
    });

    // result = await this[
    //     this.props.steps[this.state.currentStep].stepId
    //   ].isValidated();

    //   if(result){

    //   await this[this.props.steps[this.state.currentStep].stepId].submit_save();

    //   }else{

    //   }

    if (
      this[this.props.steps[this.state.currentStep].stepId].sendState !==
        undefined &&
      result
    ) {
      this.setState({
        allStates: {
          ...this.state.allStates,
          [this.props.steps[this.state.currentStep].stepId]: this[
            this.props.steps[this.state.currentStep].stepId
          ].sendState(),
        },
        btLoading: false,
      });
    } else {
      this.setState({
        btLoading: false,
      });
    }
    var key = this.state.currentStep - 1;
    if (key >= 0) {
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false,
      });
      this.refreshAnimation(key);
    }
  }
  async finishButtonClick() {
    this.setState({
      btLoading: true,
    });
    const result = await this[
      this.props.steps[this.state.currentStep].stepId
    ].isValidated();

    if (
      (this.props.validate === false &&
        this.props.finishButtonClick !== undefined) ||
      (this.props.validate &&
        ((this[this.props.steps[this.state.currentStep].stepId].isValidated !==
          undefined &&
          result) ||
          this[this.props.steps[this.state.currentStep].stepId].isValidated ===
            undefined) &&
        this.props.finishButtonClick !== undefined)
    ) {
      this.setState(
        {
          btLoading: false,
          allStates: {
            ...this.state.allStates,
            [this.props.steps[this.state.currentStep].stepId]: this[
              this.props.steps[this.state.currentStep].stepId
            ].sendState(),
          },
        },
        () => {
          this.props.finishButtonClick(this.state.allStates);
        }
      );
    }
    if (result === false) {
      this.setState({
        btLoading: false,
      });
    }
  }
  refreshAnimation(index) {
    var total = this.props.steps.length;
    var li_width = 100 / total;
    var total_steps = this.props.steps.length;
    var move_distance =
      this.wizard.current.children[0].offsetWidth / total_steps;
    var index_temp = index;
    var vertical_level = 0;

    var mobile_device = window.innerWidth < 600 && total > 3;

    if (mobile_device) {
      move_distance = this.wizard.current.children[0].offsetWidth / 2;
      index_temp = index % 2;
      li_width = 50;
    }

    this.setState({ width: li_width + "%" });

    var step_width = move_distance;
    move_distance = move_distance * index_temp;

    var current = index + 1;

    if (current === 1 || (mobile_device === true && index % 2 === 0)) {
      move_distance -= 8;
    } else if (
      current === total_steps ||
      (mobile_device === true && index % 2 === 1)
    ) {
      move_distance += 8;
    }

    if (mobile_device) {
      vertical_level = parseInt(index / 2, 10);
      vertical_level = vertical_level * 38;
    }
    var movingTabStyle = {
      width: step_width,
      transform:
        "translate3d(" + move_distance + "px, " + vertical_level + "px, 0)",
      transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)",
    };
    this.setState({ movingTabStyle: movingTabStyle });
  }
  loadingButton(isLoading) {
    if (isLoading) {
      return (
        <ClipLoader
          css={"margin-right: 10px;"}
          sizeUnit={"px"}
          size={20}
          color={"#ffffff"}
          loading={this.state.loading}
        />
      );
    } else {
      return null;
    }
  }
  autoFill = (value) => {
    var self = this;

    if (value === null) {
      // console.log('auto fill')
      // this.props.project_datatable(undefined)
      // console.log(this.props.surveytype)
      if (this.props.surveytype === "condo") {
        self.props.oldproject_data({
          code: 200,
          message: "Success",
          data: [
            {
              condo_dateconstruct: "2020-05-05T17:00:00.000Z",
              condo_datefirstsell: "2020-05-05T17:00:00.000Z",
              condo_datefinish: "2020-05-05T17:00:00.000Z",
              condo_explorerdate: "2020-05-05T17:00:00.000Z",
              condo_nameth: "เลขที่ 111",
              condo_nameeng: "",
              condo_typeowner: "lease",
              condo_forelder: "1",
              condo_mixeduse: "0",
              condo_typearea: "",
              condo_rai: 1,
              condo_ngan: 1,
              condo_sqwa: 1,
              condo_address: "1",
              condo_moo: "1",
              condo_soi: "1",
              condo_road: "1",
              condo_subdistrict: "งิ้วราย",
              condo_district: "นครชัยศรี",
              condo_province: "นครปฐม",
              condo_postalcode: "73120",
              letying: "2",
              condo_projectstatus: 3,
              condo_minprice: "1111111",
              condo_maxprice: "2222222",
              condo_averageprice: "1113111",
              condo_constructapprove: "222",
              condo_envapprove: "2",
              condo_registered: "222",
              condo_saleimprove: "",
              condo_projectprice: "20000000",
              condo_tel_saleoffice: "02222",
              condo_projectwebsite: "www.mahawongvilla.com",
              condo_note: "",
              condo_explorers: "ศิริไพ",
              condo_frontprojectplaceimg: [
                "/Hbg/images/presurvey/condo/631-4C-73-7919/condo_frontprojectplaceimg/631-4C-73-7919condo_frontprojectplaceimg-0.png",
              ],
              condo_saleofficeimg: [""],
              condo_exhouseimg: [
                '{"house":[],"twinhouse":[],"townhouse":[],"commercialbuilding":[],"land":[]}',
              ],
              condo_currentconstructimg: [""],
              condo_brochuremapimg: [""],
              condo_googlemapimg: [""],
              condo_projectplanimg: [""],
              condo_presaleproject: "0",
              condo_presalephase: "0",
              condo_presaleamoutbuilding: 0,
              condo_filesurvey: "",
              condo_filebrochure: "",
              condo_filequotation: "",
              c_name: "บมจ.ไทยชูการ์ เทอร์มิเนิ้ล",
              c_address: "90",
              c_floor: "-",
              c_namebuilding: "-",
              c_soi: "",
              c_road: "ปู่เจ้าสมิงพราย",
              c_subdistrict: "สำโรง",
              c_district: "พระประแดง",
              c_province: "สมุทรปราการ",
              t_value: "110414",
              d_value: "1104",
              p_value: "11",
              c_tel: "083-099-8295",
              c_instm: 1,
            },
          ],
          unity: [{ unity_detail: "security" }, { unity_detail: "clubhouse" }],
          // "bs":[{"bs_name":"","bs_totalfloor":0,"bs_totalunit":0,"bs_notbuild":"0","letying":"1"}],
          // "sellroom":[{"sellroom_buildingname":"","sellroom_minarea":0,"sellroom_maxarea":0,"sellroom_minprice":"0","sellroom_maxprice":"0","sellroom_discount":0,"sellroom_type":0,"sellroom_allunitinproject":0}],
          // "st":[{"st_buildingname":"","srpprice_min":null,"srpprice_max":null,"st_pricelenid":0,"st_allunit":0,"st_allsigned":0,"st_remaining":0,"st_newsigned":0,"st_transfered":0,"st_newtransfered":0}]
        });
      } else if (this.props.surveytype === "household") {
        self.props.oldproject_data({
          code: 200,
          message: "Success",
          data: [
            {
              // "household_projectid":"631-0H-10-49804",

              household_nameth: "ทดสอบ",
              household_nameeng: "",
              household_typeowner: "free",
              household_forelder: "0",
              household_typesell: "0",
              household_typearea: "-",
              household_rai: 1,
              household_ngan: 1,
              household_sqwa: 1,
              household_address: "",
              household_moo: "",
              household_soi: "",
              household_road: "-",
              household_subdistrict: "ดินแดง",
              household_district: "ดินแดง",
              household_province: "กรุงเทพมหานคร",
              household_postalcode: "10400",
              household_datefirstsell: "2020-05-11 00:00:00",
              household_projectstatus: 3,
              household_dateconstruct: "2020-05-11 00:00:00",
              household_datefinish: "2021-05-11 00:00:00",
              household_minprice: "1000000",
              household_maxprice: "5000000",
              household_allocate: "3",
              household_saleimprove: "",
              household_projectprice: "60000000",
              household_tel_saleoffice: "1222",
              household_projectwebsite: "www.supalai.com",
              household_note: "",
              household_explorers: "ก",
              household_explorerdate: "2020-05-11 00:00:00",
              household_frontprojectplaceimg: [
                "/Hbg/images/presurvey/household/631-0H-10-49804/household_frontprojectplaceimg/631-0H-10-49804household_frontprojectplaceimg-0.png",
              ],
              household_saleofficeimg: [
                "/Hbg/images/presurvey/household/631-0H-10-49804/household_saleofficeimg/631-0H-10-49804household_saleofficeimg-0.png",
              ],
              household_exhouseimg: [
                '{"house":[],"twinhouse":[],"townhouse":[],"commercialbuilding":[],"land":[]}',
              ],
              household_currentconstructimg: [
                "/Hbg/images/presurvey/household/631-0H-10-49804/household_currentconstructimg/631-0H-10-49804household_currentconstructimg-0.png",
              ],
              household_brochuremapimg: [
                "/Hbg/images/presurvey/household/631-0H-10-49804/household_brochuremapimg/631-0H-10-49804household_brochuremapimg-0.png",
              ],
              household_googlemapimg: [
                "/Hbg/images/presurvey/household/631-0H-10-49804/google_map/631-0H-10-49804google_map-0.png",
              ],
              household_projectplanimg: [
                "/Hbg/images/presurvey/household/631-0H-10-49804/household_projectplanimg/631-0H-10-49804household_projectplanimg-0.png",
              ],
              household_filesurvey: "",
              household_filebrochure: "",
              household_filequotation: "",
              c_name: "บมจ.ศุภาลัย",
              c_address: "1011",
              c_floor: "-",
              c_namebuilding: "ศุภลัยแกรนด์ ทาวเวอร์",
              c_soi: "พระราม3",
              c_road: "-",
              c_subdistrict: "ช่องนนทรี",
              c_district: "ยานนาวา",
              c_province: "กรุงเทพมหานคร",
              t_value: "101203",
              d_value: "1012",
              p_value: "10",
              c_tel: "1720",
              c_fax: "",
              c_instm: 1,
              lat: 13.7620383,
              lon: 100.567236,
            },
          ],
          cat: [
            {
              cat_type: 1,
              cat_floor: 2,
              cat_width: 4,
              cat_minarea: 40,
              cat_maxarea: 40,
              cat_minuseablearea: 30,
              cat_maxuseablearea: 30,
              cat_bedroom: 2,
              cat_bathroom: 2,
              cat_minprice: "10000",
              cat_maxprice: "10000",
              cat_discount: 0,
              cat_allunitinproject: "10",
            },
          ],
          unity: [{ unity_detail: "security" }],
          stc: [
            {
              stc_type: "บ้านเดี่ยว 2 ชั้น กว้าง 4 ม.",
              srpprice_min: "0",
              srpprice_max: "1000000",
              stc_pricelenid: 1,
              stc_unit: 10,
              stc_sellsigned: 0,
              stc_remaining: 10,
              stc_newsigned: 0,
              stc_transfered: 0,
              stc_newtransfer: 0,
              stc_notconstruct: 0,
              stc_underconstruct: 0,
              stc_finished: 0,
              stc_newconstruct: 0,
              stc_newfinished: 0,
              stc_sellnotconstruct: 0,
              stc_sellunderconstruct: 0,
              stc_sellfinished: 0,
              stc_rmnotconstruct: 0,
              stc_rmunderconstruct: 0,
              stc_rmfinished: 0,
            },
          ],
          percentage: "100.0",
        });
      } else if (this.props.surveytype === "villa") {
      }
    }
  };
  passThrouh = () => {
    // console.log('press through')
    this.setState({
      btLoading: true,
    });
    const result = true;
    if (
      (this.props.validate &&
        ((this[this.props.steps[this.state.currentStep].stepId].isValidated !==
          undefined &&
          result) ||
          this[this.props.steps[this.state.currentStep].stepId].isValidated ===
            undefined)) ||
      this.props.validate === undefined
    ) {
      if (
        this[this.props.steps[this.state.currentStep].stepId].sendState !==
        undefined
      ) {
        this.setState({
          allStates: {
            ...this.state.allStates,
            [this.props.steps[this.state.currentStep].stepId]: this[
              this.props.steps[this.state.currentStep].stepId
            ].sendState(),
          },
        });
      }
      var key = this.state.currentStep + 1;
      this.setState({
        btLoading: false,
        currentStep: key,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        finishButton: this.props.steps.length === key + 1 ? true : false,
      });
      this.refreshAnimation(key);
    }
    if (this.props.onNextStepClick) {
      this.props.onNextStepClick(this.state.currentStep);
    }
    if (result === false) {
      this.setState({
        btLoading: false,
      });
    }
  };
  render() {
    const { classes, title, subtitle, color, steps } = this.props;
    return (
      <div className={classes.wizardContainer} ref={this.wizard}>
        <Card className={classes.card}>
          {/* <button onClick={()=>this.passThrouh()}>
          through
        </button>  */}
          {/* <button onClick={()=>this.autoFill(null)}>
          auto fill
        </button> 
        <button onClick={()=>this.props.finishButtonClick(this.state.allStates)}>
          submit (household)
        </button>  */}
          <div className={classes.wizardHeader}>
            <h3 className={classes.title}>{title}</h3>
            <h5 className={classes.subtitle}>{subtitle}</h5>
          </div>
          <div className={classes.wizardNavigation}>
            <ul className={classes.nav}>
              {steps.map((prop, key) => {
                return (
                  <li
                    className={classes.steps}
                    key={key}
                    style={{ width: this.state.width }}
                  >
                    <a
                      href="#pablo"
                      className={classes.stepsAnchor}
                      // add is validate here
                      onClick={(e) => {
                        e.preventDefault();
                        this.navigationStepChange(key);
                      }}
                    >
                      {prop.stepName}
                    </a>
                  </li>
                );
              })}
            </ul>
            <div
              className={classes.movingTab + " " + classes[color]}
              style={this.state.movingTabStyle}
            >
              {steps[this.state.currentStep].stepName}
            </div>
          </div>
          <div className={classes.content}>
            {steps.map((prop, key) => {
              const stepContentClasses = cx({
                [classes.stepContentActive]: this.state.currentStep === key,
                [classes.stepContent]: this.state.currentStep !== key,
              });
              return (
                <div className={stepContentClasses} key={key}>
                  <prop.stepComponent
                    innerRef={(node) => (this[prop.stepId] = node)}
                    allStates={this.state.allStates}
                  />
                </div>
              );
            })}
          </div>
          <div className={classes.footer}>
            <div className={classes.left}>
              {this.state.previousButton ? (
                <Button
                  className={this.props.previousButtonClasses}
                  onClick={() => this.previousButtonClick()}
                >
                  {this.props.previousButtonText}
                </Button>
              ) : null}
            </div>
            <div className={classes.right}>
              {this.state.nextButton && !this.state.isResubmiting ? (
                <Button
                  color="rose"
                  className={this.props.nextButtonClasses}
                  disabled={this.state.btLoading}
                  onClick={() => this.nextButtonClick()}
                >
                  {this.loadingButton(this.state.btLoading)}
                  {this.state.currentStep === 0
                    ? "บันทึก และต่อไป"
                    : this.props.nextButtonText}
                </Button>
              ) : null}
              {this.state.finishButton &&
              "isEditable" in
                this[this.props.steps[this.state.currentStep].stepId] &&
              this[
                this.props.steps[this.state.currentStep].stepId
              ].isEditable() ? (
                <Button
                  color="rose"
                  className={this.finishButtonClasses}
                  disabled={this.state.btLoading}
                  onClick={() =>
                    // this.state.isResubmiting?
                    // this.finishButtonClickWithValidate()
                    // :
                    this.finishButtonClick()
                  }
                >
                  {this.loadingButton(this.state.btLoading)}
                  {this.props.finishButtonText}
                </Button>
              ) : null}
            </div>
            <div className={classes.clearfix} />
          </div>
        </Card>
      </div>
    );
  }
}

Wizard.defaultProps = {
  color: "rose",
  title: "Here should go your title",
  subtitle: "And this would be your subtitle",
  previousButtonText: "Previous",
  previousButtonClasses: "",
  nextButtonClasses: "",
  nextButtonText: "Next",
  finishButtonClasses: "",
  finishButtonText: "Finish",
};

Wizard.propTypes = {
  classes: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.string.isRequired,
      stepComponent: PropTypes.object.isRequired,
      stepId: PropTypes.string.isRequired,
    })
  ).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "ghbank",
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  previousButtonClasses: PropTypes.string,
  previousButtonText: PropTypes.string,
  nextButtonClasses: PropTypes.string,
  nextButtonText: PropTypes.string,
  finishButtonClasses: PropTypes.string,
  finishButtonText: PropTypes.string,
  finishButtonClick: PropTypes.func,
  validate: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  // surveyType: payload => dispatch(surveyType(payload)),
  // project_datatable: payload => dispatch(project_datatable(payload)),
  // loading_datatable: payload => dispatch(loading_datatable(payload)),
  oldproject_data: (payload) => dispatch(oldproject_data(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(wizardStyle)(Wizard));
