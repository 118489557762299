import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import { connect } from "react-redux";
import * as config from "configure/configServer.js";
import CryptoJS from "crypto-js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Accordion from "components/Accordion/Accordion.js";
class Dashboard extends React.Component {
  state = {
    value: 0,
    faqs: [],
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  componentDidMount() {
    this.getSecretKey();
  }

  getSecretKey() {
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          this.setState({
            key: responseJson.data,
          });
          this.getFAQ(responseJson.data);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  getFAQ = (key) => {
    let self = this;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("sessionP");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;
    var request = new Request(config.API_FAQ, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify({
        form: "psurvey",
      }),
    });
    fetch(request)
      .then(function (response) {
        response.json().then(function (data) {
          let code = data.code;
          if (code === 200) {
            self.setState({ faqs: data.data });
          }
        });
      })
      .catch(function (err) {
        // console.log(err);
      });
  };

  render() {
    const { classes } = this.props;
    let faq = this.state.faqs.map((props) => {
      let data = {
        title: props.ques,
        content: props.ans,
      };
      return data;
    });

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <h4 className={classes.cardTitle}>คำถามที่พบบ่อย</h4>
            </CardHeader>
            <CardBody>
              <Accordion active={0} collapses={faq} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(withStyles(dashboardStyle)(Dashboard));
