/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import Recaptcha from "react-recaptcha";
import { connect } from "react-redux";
import * as config from "configure/configServer.js";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import PictureUpload from "components/CustomUpload/PictureUpload.js";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import SweetAlert from "react-bootstrap-sweetalert";
import { ClipLoader, PulseLoader } from "react-spinners";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

// switch language function
import switchIntl from "configure/switchIntl.js";

import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      alert: null,
      show: false,
      classicModal: false,
      // check company exist
      hasCompany: true,
      hasCompanyState: "",
      renderCompany: [],
      // register form
      registerFname: "",
      registerFnameState: "",
      registerLname: "",
      registerLnameState: "",
      registerPhone: "",
      registerPhoneState: "",
      registerEmail: "",
      registerEmailState: "",
      registerUsername: "",
      registerUsernameState: "",
      registerPassword: "",
      registerPasswordState: "",
      registerConfirmPassword: "",
      registerConfirmPasswordState: "",
      registerOrganization: "",
      registerOrganizationState: "",
      registerCheckbox: false,
      registerCheckboxState: "",
      registerCaptcha: "",
      registerCaptchaState: "",
      // addorg form
      addOrgGuild: "",
      addOrgGuildState: "",
      addOrgName: "",
      addOrgNameState: "",
      addOrgNo: "",
      addOrgNoState: "",
      addOrgBuilding: "",
      addOrgBuildingState: "",
      addOrgFloor: "",
      addOrgFloorState: "",
      addOrgSoi: "",
      addOrgSoiState: "",
      addOrgStreet: "",
      addOrgStreetState: "",
      addOrgProv: "",
      addOrgProvState: "",
      addOrgSubdist: "",
      addOrgSubdistState: "",
      addOrgDist: "",
      addOrgDistState: "",
      addOrgPhone: "",
      addOrgPhoneState: "",
      addOrgFax: "",
      addOrgFaxState: "",
      addOrgSalePhone: "",
      addOrgSalePhoneState: "",
      addOrgUrl: "",
      addOrgUrlState: "",
      // add new company
      newCompanyAsso: "",
      province: [],
      amphoe: [],
      tambon: [],
      guild: [],
      // bt loading
      btLoading: false,
      orgLoading: false,
      disButton: false,
      popperOpen: false,
      orgnameValue: "",
      easypass: false,
      togglehide: true,
    };

    this.hideAlert = this.hideAlert.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.validateRegister = this.validateRegister.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.getProvince = this.getProvince.bind(this);
  }

  static propTypes = {
    toggleLng: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.getProvince();
    this.getGuild();
  }

  // start of recaptcha callback function
  recaptchaCallback = () => {
    return null;
  };
  verifyCallback = (recaptchaToken) => {
    if (recaptchaToken != null) {
      this.setState({
        registerCaptcha: recaptchaToken,
        registerCaptchaState: "success",
      });
    }
  };
  expiredCallback = () => {
    this.setState({ registerCaptcha: "", registerCaptchaState: "error" });
  };
  // end of recaptcha callback function

  // loading function
  loadingButton(isLoading) {
    if (isLoading) {
      return (
        <ClipLoader
          css={"margin-right: 10px;"}
          sizeUnit={"px"}
          size={20}
          color={"#ffffff"}
          loading={this.state.loading}
        />
      );
    } else {
      return null;
    }
  }
  loadingOrg(isLoading) {
    if (isLoading) {
      return (
        <PulseLoader size={8} color={"gray"} loading={this.state.loading} />
      );
    } else {
      return null;
    }
  }
  // end of loading function

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }

  // validate forms
  change(event, stateName, type, stateNameEqualTo, maxValue) {
    let value = event.target.value;
    switch (type) {
      case "name":
        if (this.verifyName(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "phone":
        if (
          this.verifyPhone(event.target.value) &&
          value.length >= stateNameEqualTo &&
          value.length <= maxValue
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "username":
        if (this.verifyUser(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "password":
        if (this.verifyPassword(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "address":
        if (this.verifyAddress(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that returns true if value is meet profile details, false otherwise
  verifyName(value) {
    var nameRex = /^[A-Za-zก-๙]+$/;
    if (nameRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that returns true if value is phone, false otherwise
  verifyPhone(value) {
    //var phoneRex = /^0[0-9/-]+$/;
    var phoneRex = /^[#*0-9, /-]+$/;

    if (phoneRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that returns true if value is username, false otherwise
  verifyUser(value) {
    var userRex = /^[a-zA-Z0-9]+([a-zA-Z0-9](_|-| )[a-zA-Z0-9])*[a-zA-Z0-9]+$/;
    if (userRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that returns true if value is password, false otherwise
  verifyPassword(value) {
    var passwordRex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,32})"
    );
    // var passwordRex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,32})$/;
    if (passwordRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that returns true if value is address, false otherwise
  verifyAddress(value) {
    var addressRex = /^[a-zA-Zก-๙0-9._-\s/]+$/;
    if (addressRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that returns true if value is address, false otherwise
  verifyUrl(value) {
    //var urlRex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/;
    var urlRex = /^[a-z:;/A-Zก-๙0-9.,_-\s/]+$/;
    if (urlRex.test(value) || value === "-") {
      return true;
    }
    return false;
  }
  // function that verifies if two strings are equal
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  // === end of handle input forms ===
  // validate forms

  // handle trigger function
  // check add org trigger?
  validateRegister() {
    if (this.state.registerFnameState === "") {
      this.setState({ registerFnameState: "error" });
    }
    if (this.state.registerLnameState === "") {
      this.setState({ registerLnameState: "error" });
    }
    if (this.state.registerPhoneState === "") {
      this.setState({ registerPhoneState: "error" });
    }
    if (this.state.registerEmailState === "") {
      this.setState({ registerEmailState: "error" });
    }
    if (this.state.registerUsernameState === "") {
      this.setState({ registerUsernameState: "error" });
    }
    if (this.state.registerPasswordState === "") {
      this.setState({ registerPasswordState: "error" });
    }
    if (this.state.registerConfirmPasswordState === "") {
      this.setState({ registerConfirmPasswordState: "error" });
    }
    if (this.state.registerOrganizationState === "") {
      this.setState({ registerOrganizationState: "error" });
    }
    if (this.state.registerCheckboxState === "") {
      this.setState({ registerCheckboxState: "error" });
    }
    if (this.state.registerCaptchaState === "") {
      this.setState({ registerCaptchaState: "error" });
    }

    if (this.state.hasCompany) {
      // === Register with exist org ===
      //
      if (
        this.state.registerFnameState === "success" &&
        this.state.registerLnameState === "success" &&
        this.state.registerPhoneState === "success" &&
        this.state.registerEmailState === "success" &&
        this.state.registerUsernameState === "success" &&
        this.state.registerPasswordState === "success" &&
        this.state.registerConfirmPasswordState === "success" &&
        this.state.registerOrganizationState === "success" &&
        this.state.registerCheckboxState === "success" &&
        this.state.registerCaptchaState === "success" &&
        this.state.registerPassword === this.state.registerConfirmPassword
      ) {
        this.callRegister();
      } else {
        this.dynamicAlert(
          "warning",
          switchIntl(this.props.toggleLng).sweetalert.registerError.title,
          switchIntl(this.props.toggleLng).sweetalert.registerError.content,
          switchIntl(this.props.toggleLng).sweetalert.registerError.conBtTxt,
          "",
          false,
          this.hideAlert
        );
      }
    } else {
      this.setState({ registerOrganizationState: "" });
      // === Register with new org ===
      //
      if (this.state.addOrgNameState === "") {
        this.setState({ addOrgNameState: "error" });
      }
      if (this.state.addOrgNoState === "") {
        this.setState({ addOrgNoState: "error" });
      }
      if (this.state.addOrgBuildingState === "") {
        this.setState({ addOrgBuildingState: "error" });
      }
      if (this.state.addOrgFloorState === "") {
        this.setState({ addOrgFloorState: "error" });
      }
      if (this.state.addOrgSoiState === "") {
        this.setState({ addOrgSoiState: "error" });
      }
      if (this.state.addOrgStreetState === "") {
        this.setState({ addOrgStreetState: "error" });
      }
      if (this.state.addOrgProvState === "") {
        this.setState({ addOrgProvState: "error" });
      }
      if (this.state.addOrgSubdistState === "") {
        this.setState({ addOrgSubdistState: "error" });
      }
      if (this.state.addOrgDistState === "") {
        this.setState({ addOrgDistState: "error" });
      }
      if (this.state.addOrgPhoneState === "") {
        this.setState({ addOrgPhoneState: "error" });
      }
      if (this.state.addOrgFaxState === "") {
        this.setState({ addOrgFaxState: "error" });
      }
      if (this.state.addOrgSalePhoneState === "") {
        this.setState({ addOrgSalePhoneState: "error" });
      }
      if (this.state.addOrgUrlState === "") {
        this.setState({ addOrgUrlState: "error" });
      }
      if (this.state.addOrgGuildState === "") {
        this.setState({ addOrgGuildState: "error" });
      }

      if (
        this.state.registerFnameState === "success" &&
        this.state.registerLnameState === "success" &&
        this.state.registerPhoneState === "success" &&
        this.state.registerEmailState === "success" &&
        this.state.registerUsernameState === "success" &&
        this.state.registerPasswordState === "success" &&
        this.state.registerConfirmPasswordState === "success" &&
        this.state.registerCheckboxState === "success" &&
        this.state.registerCaptchaState === "success" &&
        this.state.addOrgGuildState === "success" &&
        this.state.addOrgNameState === "success" &&
        this.state.addOrgNoState === "success" &&
        this.state.addOrgBuildingState === "success" &&
        this.state.addOrgFloorState === "success" &&
        this.state.addOrgSoiState === "success" &&
        this.state.addOrgStreetState === "success" &&
        this.state.addOrgProvState === "success" &&
        this.state.addOrgSubdistState === "success" &&
        this.state.addOrgDistState === "success" &&
        this.state.addOrgPhoneState === "success" &&
        this.state.addOrgFaxState === "success" &&
        this.state.addOrgSalePhoneState === "success" &&
        this.state.addOrgUrlState === "success"
      ) {
        this.callNewOrgRegister();
      } else {
        this.dynamicAlert(
          "warning",
          switchIntl(this.props.toggleLng).sweetalert.registerError.title,
          switchIntl(this.props.toggleLng).sweetalert.registerError.content,
          switchIntl(this.props.toggleLng).sweetalert.registerError.conBtTxt,
          "",
          false,
          this.hideAlert
        );
      }
    }
  }

  // handle close addorg
  setDefault() {
    this.setState({
      hasCompany: true,
      registerOrganization: "",
      registerOrganizationState: "",
      orgnameValue: "",
      addOrgNameState: "",
      addOrgNoState: "",
      addOrgBuildingState: "",
      addOrgFloorState: "",
      addOrgSoiState: "",
      addOrgStreetState: "",
      addOrgProvState: "",
      addOrgSubdistState: "",
      addOrgDistState: "",
      addOrgPhoneState: "",
      addOrgFaxState: "",
      addOrgSalePhoneState: "",
      addOrgUrlState: "",
      addOrgGuildState: "",
    });
  }

  // check easy password
  easyPassword(password) {
    var body = {
      password: password,
    };

    var request = new Request(config.API_easypassword, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          this.setState({
            easypass: false,
          });
        } else {
          this.setState({
            easypass: true,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  // register function
  callRegister() {
    this.setState({ btLoading: true, disButton: true });
    this.easyPassword(this.state.registerPassword);

    if (!this.state.easypass) {
      var body = {
        member_name: this.state.registerFname,
        member_lname: this.state.registerLname,
        member_tel: this.state.registerPhone,
        member_email: this.state.registerEmail,
        member_username: this.state.registerUsername,
        member_passwd: this.state.registerPassword,
        member_status: 0,
        member_verify: 0,
        // eslint-disable-next-line react/prop-types
        member_profimg: this.props.getBase64,
        member_org: this.state.registerOrganization,
        member_type: 2,
      };

      var request = new Request(config.API_register, {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(body),
      });

      fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({ btLoading: false, disButton: false });
          if (responseJson.code === 200) {
            this.dynamicAlert(
              "success",
              switchIntl(this.props.toggleLng).sweetalert.registerSuccess.title,
              switchIntl(this.props.toggleLng).sweetalert.registerSuccess
                .content,
              switchIntl(this.props.toggleLng).sweetalert.registerSuccess
                .conBtTxt,
              "",
              false,
              this.hideAlert
            );
            setTimeout(() => {
              window.location.replace("/auth/login");
            }, 2000);
          } else {
            this.dynamicAlert(
              "warning",
              switchIntl(this.props.toggleLng).sweetalert.registerExist.title,
              switchIntl(this.props.toggleLng).sweetalert.registerExist.content,
              switchIntl(this.props.toggleLng).sweetalert.registerExist
                .conBtTxt,
              "",
              false,
              this.hideAlert
            );
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }

  // register with new orgfunction
  callNewOrgRegister() {
    this.setState({ btLoading: true, disButton: true });
    this.easyPassword(this.state.registerPassword);

    var org_body = {
      org_name: this.state.addOrgName,
      org_address: this.state.addOrgNo,
      org_namebuilding: this.state.addOrgBuilding,
      org_floor: this.state.addOrgFloor,
      org_soi: this.state.addOrgSoi,
      org_road: this.state.addOrgStreet,
      org_subdistrict: this.state.addOrgDist,
      org_district: this.state.addOrgSubdist,
      org_province: this.state.addOrgProv,
      org_tel: this.state.addOrgPhone,
      org_fax: this.state.addOrgFax,
      org_saletel: this.state.addOrgSalePhone,
      org_website: this.state.addOrgUrl,
      org_guild: this.state.addOrgGuild,
    };

    var request = new Request(config.API_addOrg, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(org_body),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ btLoading: false, disButton: false });
        if (responseJson.code === 200) {
          this.setState({
            registerOrganization: responseJson.data.org_id,
          });
          this.callRegister();
        } else {
          this.dynamicAlert(
            "warning",
            switchIntl(this.props.toggleLng).sweetalert.companyExist.title,
            switchIntl(this.props.toggleLng).sweetalert.companyExist.content,
            switchIntl(this.props.toggleLng).sweetalert.companyExist.conBtTxt,
            "",
            false,
            this.hideAlert
          );
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  // start of dynsmic alert handled
  dynamicAlert(
    alertType,
    title,
    content,
    conTxt,
    canTxt,
    showCancel,
    confirmFunction,
    cancelFunction
  ) {
    this.setState({
      alert: (
        <SweetAlert
          type={alertType}
          style={{
            display: "block",
            color: "black",
            fontSize: "18px",
          }}
          customClass="modalContent"
          title={title}
          onConfirm={confirmFunction}
          onCancel={cancelFunction}
          confirmBtnCssClass="modalBt"
          cancelBtnCssClass="modalBt modalBtCancel"
          confirmBtnText={conTxt}
          cancelBtnText={canTxt}
          showCancel={showCancel}
        >
          {content}
        </SweetAlert>
      ),
    });
  }
  hideAlert() {
    this.setState({
      alert: null,
    });
  }
  // end of dynsmic alert handled

  // auto complete org field
  getOrg(event) {
    let value = event.target.value;
    this.setState({ orgnameValue: value, registerOrganizationState: "error" });

    var body = {
      org_name: value,
    };

    if (value.length >= 2) {
      this.setState({ orgLoading: true });
      var request = new Request(config.API_get_orgreg, {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(body),
      });

      fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          let data = responseJson.data;

          if (data.length >= 1) {
            this.setState({
              popperOpen: true,
              renderCompany: responseJson.data,
              orgLoading: false,
            });
          } else {
            this.setState({
              popperOpen: false,
              orgLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            popperOpen: false,
            renderCompany: [],
            registerOrganizationState: "error",
            orgLoading: false,
          });
        });
    } else {
      this.setState({
        popperOpen: false,
        renderCompany: [],
        registerOrganizationState: "error",
        orgLoading: false,
      });
    }
  }
  // end of auto complete org field

  // auto complete guild field
  getGuild(event) {
    var request = new Request(config.API_get_guild, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        let data = responseJson.data;
        this.setState({
          guild: data,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }
  // end of auto complete guild field

  // get province
  getProvince() {
    this.setState({ amphoe: [], tambon: [] });
    var body = {
      pro_id: 0,
      amp_id: 0,
      tam_id: 0,
    };

    var request = new Request(config.API_get_prov, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        let prov = responseJson.data.sort((a, b) =>
          a.label > b.label ? 1 : b.label > a.label ? -1 : 0
        );
        this.setState({
          province: prov,
        });
      })
      .catch((error) => {
        //
      });
  }
  getAmphoe(prov_val) {
    this.setState({ amphoe: [], addOrgSubdist: "" });
    var body = {
      pro_id: prov_val,
      amp_id: 0,
      tam_id: 0,
    };

    var request = new Request(config.API_get_prov, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          amphoe: responseJson.data,
        });
      })
      .catch((error) => {
        //
      });
  }
  getTambon(prov_val, amp_val) {
    this.setState({ tambon: [], addOrgDist: "" });
    var body = {
      pro_id: prov_val,
      amp_id: amp_val,
      tam_id: 0,
    };

    var request = new Request(config.API_get_prov, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          tambon: responseJson.data,
        });
      })
      .catch((error) => {
        //
      });
  }

  handleClose() {
    this.setState({ popperOpen: false });
  }
  handleSelect(value) {
    this.setState({
      popperOpen: false,
      registerOrganization: value.org_id,
      registerOrganizationState: "success",
      orgnameValue: value.org_name,
    });
  }

  handleSelectedProv = (event) => {
    this.setState({
      addOrgSubdist: "",
      addOrgDist: "",
      [event.target.name]: event.target.value,
      addOrgProvState: "success",
    });
    this.getAmphoe(event.target.value);
  };
  handleSelectedSub = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      addOrgSubdistState: "success",
    });
    this.getTambon(this.state.addOrgProv, event.target.value);
  };
  handleSelectedDist = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      addOrgDistState: "success",
    });
  };
  handleSelectedGuild = (event) => {
    // console.log(event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
      addOrgGuildState: "success",
    });
  };
  showpassword = (toggle) => {
    if (toggle) {
      return "password";
    } else {
      return "text";
    }
  };
  handleOpenModal(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }
  handleCloseModal(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  render() {
    const { classes } = this.props;

    let style =
      this.state.registerOrganizationState === "error" ? "red" : "green";

    let style_prov = this.state.addOrgProvState === "error" ? "red" : "green";
    let style_amp = this.state.addOrgSubdistState === "error" ? "red" : "green";
    let style_tam = this.state.addOrgDistState === "error" ? "red" : "green";

    let optionItems = this.state.renderCompany.map((com) => (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={com.org_id}
        value={com.org_id}
        style={{ backgroundColor: "#fff" }}
        onClick={() => this.handleSelect(com)}
      >
        {com.org_name}
      </MenuItem>
    ));

    let provinceItems = this.state.province.map((prov) => (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={prov.label}
        value={prov.value}
        style={{ backgroundColor: "#fff" }}
      >
        {prov.label}
      </MenuItem>
    ));

    let amphoneItems = this.state.amphoe.map((amp) => (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={amp.label}
        value={amp.value}
        style={{ backgroundColor: "#fff" }}
      >
        {amp.label}
      </MenuItem>
    ));

    let tambonItems = this.state.tambon.map((tam) => (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={tam.label}
        value={tam.value}
        style={{ backgroundColor: "#fff" }}
      >
        {tam.label}
      </MenuItem>
    ));

    let guildItems = this.state.guild.map((guild) => (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={guild.guild_id}
        value={guild.guild_id}
        style={{ backgroundColor: "#fff" }}
      >
        {guild.guild_name}
      </MenuItem>
    ));

    return (
      <div className={classes.container} style={{ marginBottom: "35px" }}>
        {this.state.alert}
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} md={6}>
            <form>
              <Card>
                <CardBody style={{ alignItems: "center" }}>
                  <h3 className={classes.infoText}>
                    {switchIntl(this.props.toggleLng).register.header}
                  </h3>
                  <GridContainer alignItems="center">
                    <GridItem xs={12} sm={5} md={5}>
                      <PictureUpload />
                    </GridItem>
                    <GridItem xs={12} sm={7} md={7}>
                      <CustomInput
                        success={this.state.registerFnameState === "success"}
                        error={this.state.registerFnameState === "error"}
                        labelText={
                          switchIntl(this.props.toggleLng).register.firstname
                        }
                        id="registerFname"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) =>
                            this.change(event, "registerFname", "name"),
                          type: "text",
                          endAdornment:
                            this.state.registerFnameState === "error" ? (
                              <InputAdornment position="end">
                                <Close
                                  className={classes.danger}
                                  style={{ color: "red" }}
                                />
                              </InputAdornment>
                            ) : (
                              undefined
                            ),
                        }}
                      />
                      <CustomInput
                        success={this.state.registerLnameState === "success"}
                        error={this.state.registerLnameState === "error"}
                        labelText={
                          switchIntl(this.props.toggleLng).register.lastname
                        }
                        id="registerLname"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) =>
                            this.change(event, "registerLname", "name"),
                          type: "text",
                          endAdornment:
                            this.state.registerLnameState === "error" ? (
                              <InputAdornment position="end">
                                <Close
                                  className={classes.danger}
                                  style={{ color: "red" }}
                                />
                              </InputAdornment>
                            ) : (
                              undefined
                            ),
                        }}
                      />
                      <CustomInput
                        success={this.state.registerPhoneState === "success"}
                        error={this.state.registerPhoneState === "error"}
                        labelText={
                          switchIntl(this.props.toggleLng).register.phone
                        }
                        id="registerPhone"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) =>
                            this.change(event, "registerPhone", "phone", 9, 10),
                          type: "number",
                          endAdornment:
                            this.state.registerPhoneState === "error" ? (
                              <InputAdornment position="end">
                                <Close
                                  className={classes.danger}
                                  style={{ color: "red" }}
                                />
                              </InputAdornment>
                            ) : (
                              undefined
                            ),
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <CustomInput
                    success={this.state.registerEmailState === "success"}
                    error={this.state.registerEmailState === "error"}
                    labelText={switchIntl(this.props.toggleLng).register.email}
                    id="registerEmail"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) =>
                        this.change(event, "registerEmail", "email"),
                      type: "email",
                      endAdornment:
                        this.state.registerEmailState === "error" ? (
                          <InputAdornment position="end">
                            <Close
                              className={classes.danger}
                              style={{ color: "red" }}
                            />
                          </InputAdornment>
                        ) : (
                          undefined
                        ),
                    }}
                  />
                  <CustomInput
                    success={this.state.registerUsernameState === "success"}
                    error={this.state.registerUsernameState === "error"}
                    labelText={
                      switchIntl(this.props.toggleLng).register.username
                    }
                    id="username"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => {
                        return this.change(
                          event,
                          "registerUsername",
                          "username"
                        );
                      },
                      type: "text",
                      endAdornment:
                        this.state.registerUsernameState === "error" ? (
                          <InputAdornment position="end">
                            <Close
                              className={classes.danger}
                              style={{ color: "red" }}
                            />
                          </InputAdornment>
                        ) : (
                          undefined
                        ),
                    }}
                  />
                  <CustomInput
                    success={this.state.registerPasswordState === "success"}
                    error={this.state.registerPasswordState === "error"}
                    labelText={
                      switchIntl(this.props.toggleLng).register.password
                    }
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) =>
                        this.change(event, "registerPassword", "password"),
                      type: this.showpassword(this.state.togglehide),
                      autoComplete: "off",
                      endAdornment: (
                        <InputAdornment position="end">
                          {this.state.togglehide ? (
                            <VisibilityIcon
                              onClick={() =>
                                this.setState({
                                  togglehide: !this.state.togglehide,
                                })
                              }
                              className={classes.danger}
                              style={{ color: "#D2D2D2", cursor: "pointer" }}
                            />
                          ) : (
                            <VisibilityOffIcon
                              onClick={() =>
                                this.setState({
                                  togglehide: !this.state.togglehide,
                                })
                              }
                              className={classes.danger}
                              style={{ color: "#D2D2D2", cursor: "pointer" }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {this.state.registerPasswordState === "error" ? (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "red",
                        textAlign: "start",
                      }}
                    >
                      <p style={{ margin: 0 }}>
                        - รหัสผ่านจำเป็นต้องมีทั้งตัวเลขและตัวหนังสือ
                      </p>
                      <p style={{ margin: 0 }}>
                        -
                        รหัสผ่านจำเป็นต้องมีทั้งตัวหนังสือพิมพ์เล็กและพิมพ์ใหญ่และมีตัวเลขอย่างน้อย
                        1 ตัว
                      </p>
                      <p style={{ margin: 0 }}>
                        - รหัสผ่านจำเป็นต้องมีตัวอักษรพิเศษ (@ ! # %) อย่างน้อย
                        1 ตัว
                      </p>
                      <p style={{ margin: 0 }}>
                        - รหัสผ่านจำเป็นต้องมีความยาวอย่างน้อย 8 ตัวแต่ไม่เกิน
                        32 ตัว
                      </p>
                    </div>
                  ) : null}
                  <CustomInput
                    success={
                      this.state.registerConfirmPasswordState === "success"
                    }
                    error={this.state.registerConfirmPasswordState === "error"}
                    labelText={
                      switchIntl(this.props.toggleLng).register.conpassword
                    }
                    id="confirmpassword"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) =>
                        this.change(
                          event,
                          "registerConfirmPassword",
                          "equalTo",
                          "registerPassword"
                        ),
                      type: this.showpassword(this.state.togglehide),
                      autoComplete: "off",
                      endAdornment:
                        this.state.registerConfirmPasswordState === "error" ? (
                          <InputAdornment position="end">
                            <p
                              style={{
                                width: "150px",
                                margin: "0",
                                textAlign: "end",
                                color: "red",
                                fontSize: "12px",
                              }}
                            >
                              {
                                switchIntl(this.props.toggleLng).register
                                  .noconpass
                              }
                            </p>
                          </InputAdornment>
                        ) : (
                          undefined
                        ),
                    }}
                  />
                  <CustomInput
                    success={this.state.registerOrganizationState === "success"}
                    error={this.state.registerOrganizationState === "error"}
                    labelText={
                      switchIntl(this.props.toggleLng).register.company
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: this.state.orgnameValue,
                      onChange: (event) => this.getOrg(event),
                      ref: (node) => {
                        this.anchorEl = node;
                      },
                      endAdornment:
                        this.state.registerOrganizationState === "error" ? (
                          <InputAdornment position="end">
                            {this.state.orgLoading ? (
                              <div style={{ width: "50px" }}>
                                {this.loadingOrg(this.state.orgLoading)}
                              </div>
                            ) : null}
                            <p
                              style={{
                                width: "105px",
                                margin: "0",
                                textAlign: "end",
                                color: "red",
                                fontSize: "12px",
                              }}
                            >
                              {switchIntl(this.props.toggleLng).register.noorg}
                            </p>
                          </InputAdornment>
                        ) : (
                          undefined
                        ),
                      disabled: !this.state.hasCompany,
                    }}
                  />
                  <Popper
                    open={this.state.popperOpen}
                    anchorEl={this.anchorEl}
                    transition
                    disablePortal
                    placement="bottom-end"
                    className={
                      "MuiPaper-root MuiMenu-paper MuiPaper-elevation8 MuiPopover-paper MuiPaper-rounded"
                    }
                    style={{
                      maxHeight: "200px",
                      minWidth: "50%",
                      overflow: "hidden",
                      overflowY: "auto",
                      zIndex: 20,
                    }}
                  >
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <Paper className={classes.selectMenu}>
                        {optionItems}
                      </Paper>
                    </ClickAwayListener>
                  </Popper>
                  {/* <div style={{ textAlign: "end" }}>
                    {this.state.hasCompany
                      ? switchIntl(this.props.toggleLng).register.addorg
                      : null}
                    <span
                      style={{ cursor: "pointer", fontWeight: 500 }}
                      onClick={() => {
                        !this.state.hasCompany
                          ? this.setDefault()
                          : this.setState({
                              hasCompany: false,
                              registerOrganization: "",
                              registerOrganizationState: "",
                              orgnameValue: ""
                            });
                      }}
                    >
                      {!this.state.hasCompany
                        ? switchIntl(this.props.toggleLng).register.canceladd
                        : switchIntl(this.props.toggleLng).register.addorglink}
                    </span>
                  </div> */}
                  {/* Start of add company collapsed */}
                  {this.state.hasCompany ? null : (
                    <div>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        style={{
                          margin: "0 0 17px 0",
                          position: "relative",
                          paddingTop: "27px",
                          verticalAlign: "unset",
                        }}
                      >
                        <InputLabel
                          className={classes.selectLabel}
                          style={{
                            top: "10px",
                            color:
                              this.state.addOrgGuildState === ""
                                ? "#AAAAAA"
                                : style_prov,
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "1.42857",
                          }}
                        >
                          {
                            switchIntl(this.props.toggleLng).register
                              .association
                          }
                        </InputLabel>
                        <Select
                          style={{
                            marginTop: "0px",
                            textAlign: "start",
                            fontSize: "16px",
                          }}
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.addOrgGuild}
                          onChange={this.handleSelectedGuild}
                          inputProps={{
                            name: "addOrgGuild",
                            id: "addOrgGuild",
                          }}
                        >
                          {guildItems}
                        </Select>
                      </FormControl>
                      <CustomInput
                        success={this.state.addOrgNameState === "success"}
                        error={this.state.addOrgNameState === "error"}
                        labelText={
                          switchIntl(this.props.toggleLng).register.orgname
                        }
                        id="orgname"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          onChange: (event) =>
                            this.change(event, "addOrgName", "address"),
                        }}
                      />
                      <GridContainer alignItems="center">
                        <GridItem xs={12} sm={4} md={4}>
                          <CustomInput
                            success={this.state.addOrgNoState === "success"}
                            error={this.state.addOrgNoState === "error"}
                            labelText={
                              switchIntl(this.props.toggleLng).register
                                .orgaddress
                            }
                            id="orgno"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              onChange: (event) =>
                                this.change(event, "addOrgNo", "address"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <CustomInput
                            success={
                              this.state.addOrgBuildingState === "success"
                            }
                            error={this.state.addOrgBuildingState === "error"}
                            labelText={
                              switchIntl(this.props.toggleLng).register
                                .orgbuilding
                            }
                            id="orgbuilding"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              onChange: (event) =>
                                this.change(event, "addOrgBuilding", "address"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <CustomInput
                            success={this.state.addOrgFloorState === "success"}
                            error={this.state.addOrgFloorState === "error"}
                            labelText={
                              switchIntl(this.props.toggleLng).register.orgfloor
                            }
                            id="orgfloor"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              onChange: (event) =>
                                this.change(event, "addOrgFloor", "address"),
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer alignItems="center">
                        <GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            success={this.state.addOrgSoiState === "success"}
                            error={this.state.addOrgSoiState === "error"}
                            labelText={
                              switchIntl(this.props.toggleLng).register.orgsoi
                            }
                            id="orgsoi"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              onChange: (event) =>
                                this.change(event, "addOrgSoi", "address"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            success={this.state.addOrgStreetState === "success"}
                            error={this.state.addOrgStreetState === "error"}
                            labelText={
                              switchIntl(this.props.toggleLng).register.orgroad
                            }
                            id="orgstreet"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              onChange: (event) =>
                                this.change(event, "addOrgStreet", "address"),
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer alignItems="center">
                        <GridItem xs={12} sm={4} md={4}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                            style={{
                              margin: "0 0 17px 0",
                              position: "relative",
                              paddingTop: "27px",
                              verticalAlign: "unset",
                            }}
                          >
                            <InputLabel
                              className={classes.selectLabel}
                              style={{
                                top: "10px",
                                color:
                                  this.state.addOrgProvState === ""
                                    ? "#AAAAAA"
                                    : style_prov,
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "1.42857",
                              }}
                            >
                              {
                                switchIntl(this.props.toggleLng).register
                                  .orgprov
                              }
                            </InputLabel>
                            <Select
                              style={{
                                marginTop: "0px",
                                textAlign: "start",
                                fontSize: "16px",
                              }}
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.addOrgProv}
                              onChange={this.handleSelectedProv}
                              inputProps={{
                                name: "addOrgProv",
                                id: "addOrgProv",
                              }}
                            >
                              {provinceItems}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                            style={{
                              margin: "0 0 17px 0",
                              position: "relative",
                              paddingTop: "27px",
                              verticalAlign: "unset",
                            }}
                          >
                            <InputLabel
                              className={classes.selectLabel}
                              style={{
                                top: "10px",
                                color:
                                  this.state.addOrgSubdistState === ""
                                    ? "#AAAAAA"
                                    : style_amp,
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "1.42857",
                              }}
                            >
                              {
                                switchIntl(this.props.toggleLng).register
                                  .orgsubdis
                              }
                            </InputLabel>
                            <Select
                              style={{
                                marginTop: "0px",
                                textAlign: "start",
                                fontSize: "16px",
                              }}
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.addOrgSubdist}
                              onChange={this.handleSelectedSub}
                              inputProps={{
                                name: "addOrgSubdist",
                                id: "addOrgSubdist",
                              }}
                            >
                              {amphoneItems}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                            style={{
                              margin: "0 0 17px 0",
                              position: "relative",
                              paddingTop: "27px",
                              verticalAlign: "unset",
                            }}
                          >
                            <InputLabel
                              className={classes.selectLabel}
                              style={{
                                top: "10px",
                                color:
                                  this.state.addOrgDistState === ""
                                    ? "#AAAAAA"
                                    : style_tam,
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "1.42857",
                              }}
                            >
                              {switchIntl(this.props.toggleLng).register.orgdis}
                            </InputLabel>
                            <Select
                              style={{
                                marginTop: "0px",
                                textAlign: "start",
                                fontSize: "16px",
                              }}
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.addOrgDist}
                              onChange={this.handleSelectedDist}
                              inputProps={{
                                name: "addOrgDist",
                                id: "addOrgDist",
                              }}
                            >
                              {tambonItems}
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <CustomInput
                        success={this.state.addOrgPhoneState === "success"}
                        error={this.state.addOrgPhoneState === "error"}
                        labelText={
                          switchIntl(this.props.toggleLng).register.orgtel
                        }
                        id="orgphone"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "number",
                          onChange: (event) =>
                            this.change(event, "addOrgPhone", "phone", 9, 10),
                        }}
                      />
                      <CustomInput
                        success={this.state.addOrgFaxState === "success"}
                        error={this.state.addOrgFaxState === "error"}
                        labelText={
                          switchIntl(this.props.toggleLng).register.orgfax
                        }
                        id="orgfax"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "number",
                          onChange: (event) =>
                            this.change(event, "addOrgFax", "phone", 9, 10),
                        }}
                      />
                      <CustomInput
                        success={this.state.addOrgSalePhoneState === "success"}
                        error={this.state.addOrgSalePhoneState === "error"}
                        labelText={
                          switchIntl(this.props.toggleLng).register.orgset
                        }
                        id="orgsalephone"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "number",
                          onChange: (event) =>
                            this.change(
                              event,
                              "addOrgSalePhone",
                              "phone",
                              9,
                              10
                            ),
                        }}
                      />
                      <CustomInput
                        success={this.state.addOrgUrlState === "success"}
                        error={this.state.addOrgUrlState === "error"}
                        labelText={
                          switchIntl(this.props.toggleLng).register.orgweb
                        }
                        id="orgurl"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          onChange: (event) =>
                            this.change(event, "addOrgUrl", "url"),
                        }}
                      />
                    </div>
                  )}
                  {/* end of add company collapsed */}
                  <div
                    style={{
                      textAlign: "left",
                      marginTop: "15px",
                      marginBottom: "5px",
                      color:
                        this.state.registerCheckboxState !== "error"
                          ? null
                          : "red",
                    }}
                  >
                    <Checkbox
                      tabIndex={-1}
                      onClick={(event) =>
                        this.change(event, "registerCheckbox", "checkbox")
                      }
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                    {switchIntl(this.props.toggleLng).register.agreement}
                    <a
                      href="#/"
                      onClick={() => this.handleOpenModal("classicModal")}
                      style={{
                        color: "rgba(0, 0, 0, 0.87)",
                        textDecorationLine: "underline",
                        fontWeight: 500,
                        cursor: "pointer",
                      }}
                    >
                      {switchIntl(this.props.toggleLng).register.agreementLink}
                    </a>
                  </div>
                  <div className="recaptchaContainer">
                    <Recaptcha
                      // hl={this.props.toggleLng}
                      sitekey={config.recaptchaKey}
                      render="explicit"
                      onloadCallback={this.recaptchaCallback}
                      verifyCallback={this.verifyCallback}
                      expiredCallback={this.expiredCallback}
                    />
                  </div>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <NavLink
                    to={"#"}
                    style={{ width: "100%", paddingRight: "5px" }}
                    onClick={
                      this.state.disButton ? null : this.validateRegister
                    }
                  >
                    <Button
                      size="lg"
                      fullWidth={true}
                      className="fixedBTColorLogin"
                    >
                      {this.loadingButton(this.state.btLoading)}
                      {switchIntl(this.props.toggleLng).register.regBt}
                    </Button>
                  </NavLink>
                  <NavLink
                    to={"/auth/"}
                    style={{ width: "100%", paddingLeft: "5px" }}
                  >
                    <Button
                      simple
                      size="lg"
                      fullWidth={true}
                      className="fixedBTColorReg"
                    >
                      {switchIntl(this.props.toggleLng).register.backBT}
                    </Button>
                  </NavLink>
                </CardFooter>
              </Card>
              <Dialog
                classes={{
                  root: classes.center + " " + classes.modalRoot,
                  paper: classes.modal,
                }}
                open={this.state.classicModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.handleCloseModal("classicModal")}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
              >
                <DialogTitle
                  id="classic-modal-slide-title"
                  disableTypography
                  className={classes.modalHeader}
                >
                  <h4 className={classes.modalTitle}>ข้อตกลง และเงื่อนไข</h4>
                </DialogTitle>
                <DialogContent
                  id="classic-modal-slide-description"
                  className={classes.modalBody}
                >
                  <p>
                    ศูนย์ข้อมูลศูนย์ข้อมูลธนาคารอาคารสงเคราะห์
                    อาจใช้อุปกรณ์ซอฟต์แวร์
                    เพื่อการเก็บรวบรวมข้อมูลในด้านพฤติกรรมการสืบค้นข้อมูลของผู้ที่เข้ามาเยี่ยมชมเว็บไซต์
                    ได้แก่ วันและเวลาในการเข้าเยี่ยมชมเว็บไซต์
                    การดูหน้าเว็บเพจต่างๆ เวลาที่ใช้ในการเยี่ยมชม
                    ประเภทของการสืบค้น ผู้ให้บริการอินเทอร์เน็ต
                    และการเข้าเยี่ยมชมเว็บไซต์ต่างๆ
                    ทั้งก่อนและหลังการเข้าชมเว็บไซต์ของศูนย์ข้อมูลธนาคารอาคารสงเคราะห์
                    ทั้งนี้เพื่อวัตถุประสงค์ในการปรับปรุงเว็บไซต์
                    ให้เข้าถึงกลุ่มเป้าหมาย
                    ศูนย์ข้อมูลธนาคารอาคารสงเคราะห์อาจทำการตรวจสอบถึงพฤติกรรมการสืบค้นข้อมูล
                    และใช้บริการระบบเชื่อมโยงข้อมูล
                    โดยส่วนหนึ่งของกระบวนการรักษาความปลอดภัยของศูนย์ข้อมูลธนาคารอาคารสงเคราะห์นั้น
                    ศูนย์ข้อมูลธนาคารอาคารสงเคราะห์อาจใช้ระบบ “คุกกี้” (cookie)
                    ในการตรวจสอบความถูกต้องแท้จริงของคำขอของผู้ใช้บริการ ระบบ
                    “คุกกี้”
                    คือข้อมูลที่ได้ส่งจากเว็บไซต์ไปยังเครื่องคอมพิวเตอร์ของผู้เข้าเยี่ยมชมเว็บไซต์
                    ในขณะที่ผู้เข้าเยี่ยมชมเว็บไซต์นั้นกำลังเข้าเยี่ยมชมเว็บไซต์ของศูนย์ข้อมูลธนาคารอาคารสงเ
                    คราะห์ โดย “คุกกี้”
                    นั้นจะได้รับการกำหนดโดยศูนย์ข้อมูลธนาคารอาคารสงเคราะห์
                    และจะสิ้นผลลงภายหลังจากการเชื่อมโยงข้อมูลในส่วนของศูนย์ข้อมูลธนาคารอาคารสงเคราะห์
                  </p>
                  <h5>การรวบรวมและการรักษาข้อมูลส่วนบุคคล</h5>
                  <p>
                    ข้อมูลที่เกี่ยวข้องกับท่านจะถูกนำไปใช้เพื่อวัตถุประสงค์ในการดำเนินงานของศูนย์ข้อมูลธนาค
                    ารอาคารสงเคราะห์ อย่างถูกต้องตามกฎหมาย
                    การให้บริการที่ดีที่สุด
                    และการออกแบบผลิตภัณฑ์ข้อเสนอพิเศษที่ดีขึ้นกว่าเดิม
                    สำหรับการเก็บรวบรวมข้อมูลส่วนบุคคล
                    ศูนย์ข้อมูลธนาคารอาคารสงเคราะห์จะขอข้อมูลจากท่านโดยตรงด้วยกระบวนการสมัคร
                    หรือทางบริการเสริมซึ่งต้องใช้บุคคลติดต่อกับท่าน
                    หากท่านเลือกที่จะให้ข้อมูลส่วนบุคคล เช่น ที่อยู่
                    จดหมายอิเล็กทรอนิกส์ หมายเลขโทรศัพท์หรือหมายเลขโทรสาร
                    ลักษณะทางภูมิภาคที่อยู่อาศัย
                    และเลขประจำตัวของท่านแก่ศูนย์ข้อมูลธนาคารอาคารสงเคราะห์
                    ตลอดจนกิจกรรมทางธุรกิจหรือการดำเนินธุรกรรมใดๆ
                    ของท่านแก่ศูนย์ข้อมูลธนาคารอาคารสงเคราะห์แล้ว
                    ศูนย์ข้อมูลธนาคารอาคารสงเคราะห์จะรักษาข้อมูลเหล่านั้นไว้เป็นความลับ
                    ตามเกณฑ์มาตรฐานสำหรับการรักษาข้อมูลเป็นความลับของศูนย์ข้อมูลธนาคารอาคารสงเคราะ
                    ห์
                    ข้อมูลเกี่ยวกับท่านจะถูกเก็บรวบรวมและรักษาไว้ในระหว่างระยะเวลาที่ศูนย์ข้อมูลธนาคารอาค
                    ารสงเคราะห์และท่านยังคงมีความสัมพันธ์ทางธุรกิจต่อกันและตามที่กฎหมายกำหนด
                    นอกจากนี้เมื่อท่านได้ส่งจดหมายอิเล็กทรอนิกส์มายังศูนย์ข้อมูลธนาคารอาคารสงเคราะห์
                    ศูนย์ข้อมูลธนาคารอาคารสงเคราะห์จะเก็บเนื้อหาในจดหมายอิเล็กทรอนิกส์
                    ที่อยู่ของจดหมายอิเล็กทรอนิกส์
                    และการโต้ตอบจดหมายอิเล็กทรอนิกส์ดังกล่าวไว้
                    เพื่อตอบข้อสงสัยให้แก่ท่าน หรือเพื่อความจำเป็นในการติดตามผล
                    และเพื่อตรวจสอบถึงการโต้ตอบระหว่างท่านกับศูนย์ข้อมูลธนาคารอาคารสงเคราะห์
                  </p>
                  <h5>การเปิดเผยข้อมูลส่วนบุคคล</h5>
                  <p>
                    ศูนย์ข้อมูลธนาคารอาคารสงเคราะห์จะไม่เปิดเผยข้อมูลส่วนบุคคลของท่านแก่บุคคลภายนอก
                    เพื่อวัตถุประสงค์ในการนำไปใช้โดยอิสระ เว้นแต่
                    ศูนย์ข้อมูลธนาคารอาคารสงเคราะห์ได้ความยินยอมจากท่าน
                    หรือเป็นไปตามที่กฎหมายให้อำนาจหรือกำหนดไว้ เช่น
                    ศูนย์ข้อมูลธนาคารอาคารสงเคราะห์อาจต้องเปิดเผยข้อมูลเกี่ยวกับท่านตามคำสั่งศาล
                    หรือกฎหมายเกี่ยวกับข้อมูลข่าวสารของราชการ
                    หรือองค์กรที่มีอำนาจตามกฎหมาย
                    ศูนย์ข้อมูลธนาคารอาคารสงเคราะห์อาจทำความตกลงกับบุคคลภายนอกที่เป็นตัวแทน
                    หรือเป็นคู่สัญญาในฐานะผู้ขาย
                    หรือผู้รับจ้างของศูนย์ข้อมูลธนาคารอาคารสงเคราะห์
                    เพื่อเป็นการเพิ่มช่องทางในการให้บริการ
                    จัดหาระบบหรือเครื่องมืออุปกรณ์ต่างๆ
                    เพื่อเพิ่มประสิทธิภาพในการดำเนินงานหรือดำเนินธุรกิจภายใต้กรอบวัตถุประสงค์ของศูนย์ข้อมู
                    ลธนาคารอาคารสงเคราะห์ให้มากยิ่งขึ้น
                    ซึ่งบุคคลภายนอกที่เข้ามาดำเนินงานให้กับศูนย์ข้อมูลธนาคารอาคารสงเคราะห์ดังกล่าวจะได้รั
                    บทราบข้อมูลส่วนบุคคลของท่านเท่าที่จำเป็นอย่างจำกัด
                    อีกทั้งจะต้องสงวนรักษาข้อมูลส่วนบุคคลของท่านไว้เป็นความลับตามมาตรฐานการรักษาข้อมูล
                    ส่วนบุคคลของศูนย์ข้อมูลธนาคารอาคารสงเคราะห์
                    อีกทั้งยังต้องผูกผันรับผิดชอบตามกฎหมายที่มีผลใช้บังคับด้วยเช่นกัน
                    ศูนย์ข้อมูลธนาคารอาคารสงเคราะห์อาจทำความตกลงไว้กับบุคคลภายนอกในการพัฒนารักษา
                    ระบบการธุรกรรมของศูนย์ข้อมูลธนาคารอาคารสงเคราะห์
                    และการเปิดเผยปัจจัยสำคัญหรือบริการในนามของศูนย์ข้อมูลธนาคารอาคารสงเคราะห์
                    ซึ่งบุคคลภายนอกที่เข้ามาดำเนินงานให้กับศูนย์ข้อมูลธนาคารอาคารสงเคราะห์
                    หรือเข้ามาดำเนินการในนามของศูนย์ข้อมูลธนาคารอาคารสงเคราะห์นั้นจะต้องตกลงที่จะรักษา
                    ข้อมูลลูกค้าของศูนย์ข้อมูลธนาคารอาคารสงเคราะห์ไว้เป็นความลับด้วยเช่นกัน
                    ทั้งยังต้องผูกผันต่อกฎหมายที่มีผลใช้บังคับด้วย
                    เมื่อใดก็ตามที่ศูนย์ข้อมูลธนาคารอาคารสงเคราะห์ได้ว่าจ้างองค์กรอื่นใด
                    เข้ามาให้การสนับสนุนการบริการของศูนย์ข้อมูลธนาคารอาคารสงเคราะห์
                    องค์กรเหล่านั้นจะต้องปฏิบัติตามมาตรฐานการรักษาข้อมูลเป็นความลับของศูนย์ข้อมูลธนาคาร
                    อาคารสงเคราะห์ด้วยเช่นกัน
                  </p>
                  <h5>กระบวนการรักษาความปลอดภัยเพื่อการปกป้องข้อมูล</h5>
                  <p>
                    ศูนย์ข้อมูลธนาคารอาคารสงเคราะห์จะรักษามาตรฐานในการรักษาความปลอดภัยโดยเคร่งครัด
                    เพื่อวัตถุประสงค์ในการปกป้องความเสียหาย
                    หรือการเข้าถึงข้อมูลส่วนบุคคลของท่านโดยไม่มีอำนาจ
                    อย่างไรก็ดีเป็นที่ทราบโดยทั่วไปว่า
                    ปัจจุบันนี้ยังไม่มีระบบรักษาความปลอดภัยใดๆ ที่สามารถปกป้อง
                    ข้อมูลของท่านจากการถูกทำลายหรือถูกเข้าถึงโดยบุคคลที่ปราศจากอำนาจได้อย่างเด็ดขาด
                  </p>
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                  <Button
                    onClick={() => this.handleCloseModal("classicModal")}
                    color="danger"
                    simple
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(
  withStyles(registerPageStyle)(RegisterPage)
);
