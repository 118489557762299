import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getBase64 from "../../actions/getProfile.js";

// switch language function
import switchIntl from "configure/switchIntl.js";

import defaultImage from "assets/img/default-avatar.png";

class PictureUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: defaultImage,
      reject: true
    };
  }

  static propTypes = {
    toggleLng: PropTypes.string.isRequired
  };

  handleImageChange = e => {
    var imageType = /image.*/;
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    if (file.type.match(imageType)) {
      // it's an image, process it
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result
        });

        // eslint-disable-next-line react/prop-types
        this.props.getBase64(reader.result);
        this.setState({ reject: false });
      };
      reader.readAsDataURL(file);
    } else {
      // it's not an image, reject it
      this.setState({ reject: true });
    }
  };
  handleSubmit = e => {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  };
  render() {
    return (
      <div className="picture-container">
        <div className="picture">
          <img
            src={this.state.imagePreviewUrl}
            className="picture-src"
            alt="..."
          />
          <input
            type="file"
            onChange={e => this.handleImageChange(e)}
            accept="image/x-png,image/jpeg"
          />
        </div>
        {this.state.file === null ? (
          <h6 className="description">
            {switchIntl(this.props.toggleLng).register.profileimg}
          </h6>
        ) : (
          <h6
            className="description"
            style={{ color: this.state.reject ? "red" : "green" }}
          >
            {this.state.reject
              ? switchIntl(this.props.toggleLng).register.profileerror
              : switchIntl(this.props.toggleLng).register.profileok}
          </h6>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  getBase64: base64 => dispatch(getBase64(base64))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PictureUpload);
