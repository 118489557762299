/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { connect } from "react-redux";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// switch language function
import switchIntl from "configure/switchIntl.js";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <footer className={classes.footer}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <p style={{ color: "black", textAlign: "center" }}>
                {switchIntl(this.props.toggleLng).footer.copyright_phrase1}
                &copy; {1900 + new Date().getYear()}{" "}
                {switchIntl(this.props.toggleLng).footer.copyright_phrase2}
                <br />
                {switchIntl(this.props.toggleLng).footer.copyright_phrase3}
                <br />
                {switchIntl(this.props.toggleLng).footer.copyright_phrase4}
              </p>
            </GridItem>
          </GridContainer>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (bool) => dispatch(setLoading(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(footerStyle)(Footer));
