import React from "react";

import PsurveyManual from "assets/psurvey_manual.pdf";

function Iframe(props) {
  return (<div onContextMenu={(e) => e.preventDefault()} dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }} />);
}

class Manual extends React.Component {
  render() {
    return (
      <div>
        <Iframe iframe={`<iframe id="center-iframe" height="800" style="width: 100%;" src=${PsurveyManual}></iframe>`}></Iframe>
      </div>
    );
  }
}

export default Manual;