import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// core components
import Wizard from "components/Wizard/Wizard.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";

import * as config from "configure/configServer.js";
import HouseStep1 from "views/PSurvey/House/House_Section1.js";
import HouseStep2 from "views/PSurvey/House/House_Section2.js";
import HouseStep3 from "views/PSurvey/House/House_Section3.js";
import surveyModal from "actions/survey/surveyModal.js";
import toggleSurvey from "actions/survey/toggleSurvey.js";
import CryptoJS from "crypto-js";
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

class WizardHouse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
    };
  }

  componentDidMount() {
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("sessionP");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);
          this.setState({
            token: jsonDec.token,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }
  static propTypes = {
    surveytype: PropTypes.string.isRequired,
  };

  isEquivalent(a, b) {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }

    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];

      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
  }

  alertModal(validity) {
    this.props.surveyModal(
      <SweetAlert
        type={validity === "success" ? "success" : "warning"}
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={
          validity === "success"
            ? "ส่งแบบสอบถามสำเร็จ"
            : "ไม่สามารถบันทึกข้อมูลได้"
        }
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      >
        {validity === "success"
          ? "ระบบกำลังนำท่านกลับสู่หน้าแบบสอบถามหลัก..."
          : "กรุณาตรวจสอบเลขที่แบบสอบถาม และ กรอกข้อมูลเอกสารที่จำเป็นให้ครบถ้วน"}
      </SweetAlert>
    );

    if (validity === "success") {
      setTimeout(() => {
        this.props.surveyModal(null);
        this.props.toggleSurvey(false);
        window.location.replace("/survey");
      }, 1500);
    } else {
      setTimeout(() => {
        this.props.surveyModal(null);
      }, 1500);
    }
  }

  async call_marge_all() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.merge_data());
      }, 1000);
    });
  }

  merge_data() {
    const target = this.props.house_initial_data;
    const house_data_sec1 = this.props.householdSec1Object;
    const house_data_sec2 = this.props.householdSec2Object;
    const house_data_sec3 = this.props.housesec3object;
    // console.log(house_data_sec1);
    // console.log(house_data_sec2);
    // console.log(house_data_sec3);
    const body = Object.assign(
      target,
      house_data_sec1,
      house_data_sec2,
      house_data_sec3
    );
    // console.log(body)

    return body;
  }

  async submitSurvey() {
    const body = await this.call_marge_all();
    // console.log(body);
    const token = this.state.token;
    // console.log(this.props.classes.button)
    // console.log(this.props.classes.success)
    // console.log(body);
    this.props.surveyModal(
      <SweetAlert
        type={"warning"}
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => this.confirm_submit(body, token)}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={"ยืนยันการส่งข้อมูล"}
        showCancel={true}
        showConfirm={true}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.default
        }
        closeOnClickOutside={true}
        confirmBtnText={"ยืนยัน"}
        cancelBtnText={"ตรวจสอบใหม่"}
      >
        ท่านยืนยันที่จะทำการส่งแบบสอบถามใช่หรือไม่?
      </SweetAlert>
    );
  }

  confirm_submit = (body, token) => {
    // console.log(token)
    // console.log(body)
    // console.log(JSON.stringify(body))
    let log_house_send = { event: "Send house project to admin" };
    body = Object.assign(body, log_house_send);
    // console.log(body);

    var request = new Request(config.API_submit_household, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: token,
      }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
        if (responseJson.code === 200) {
          this.alertModal("success");
        } else {
          this.alertModal("warning");
        }
      })
      .catch((err) => {
        // console.log(err);
        this.setState({
          btLoading: false,
        });
      });
  };

  successAlert(title, message, status) {
    this.setState({
      alert: (
        <SweetAlert
          {...status}
          className="SweetAlert"
          title={<div>{title}</div>}
          onConfirm={() => this.setState({ alert: null })}
          confirmBtnCssClass="modalBt"
          cancelBtnCssClass="modalBt modalBtCancel"
          confirmBtnText={"ยืนยัน"}
          cancelBtnText={"ยกเลิก"}
        >
          {message}
        </SweetAlert>
      ),
    });
  }

  render() {
    return (
      <GridContainer justify="center">
        {this.state.alert}
        <GridItem xs={12} sm={12}>
          <Wizard
            color="ghbank"
            validate
            steps={[
              {
                stepName: "ส่วนที่ 1",
                stepComponent: HouseStep1,
                stepId: "houseSec1",
              },
              {
                stepName: "ส่วนที่ 2",
                stepComponent: HouseStep2,
                stepId: "houseSec2",
              },
              {
                stepName: "ส่วนที่ 3",
                stepComponent: HouseStep3,
                stepId: "houseSec3",
              },
            ]}
            title="โครงการบ้านจัดสรร"
            subtitle=""
            previousButtonText="ย้อนกลับ"
            nextButtonText="ต่อไป"
            finishButtonText={"ส่งข้อมูลโครงการ"}
            showFinishButton={false}
            finishButtonClick={() => this.submitSurvey()}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  surveyModal: (payload) => dispatch(surveyModal(payload)),
  toggleSurvey: (payload) => dispatch(toggleSurvey(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(sweetAlertStyle)(WizardHouse));
