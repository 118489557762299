/* eslint-disable no-useless-escape */
import React from "react";
import { ClipLoader } from "react-spinners";

import CustomInput from "components/CustomInput/CustomInputTooltips.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "components/CustomButtons/Button.js";
import * as config from "configure/configServer.js";
import surveyModal from "actions/survey/surveyModal.js";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "components/Snackbar/Snackbar.js";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import "react-bootstrap-table/dist/react-bootstrap-table.min.css";
import "./CondoTable.css";
import SweetAlert from "react-bootstrap-sweetalert";
import withStyles from "@material-ui/core/styles/withStyles";
import CryptoJS from "crypto-js";
import MaterialTable from "material-table";
import Add from "@material-ui/icons/Add";

import _ from "lodash";

import numeral from "numeral";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { connect } from "react-redux";
import condo_sec3Object from "actions/survey/condo/condo_sec3Data.js";
import condoSec3_Object from "actions/survey/condo/condo_sec3Obj.js";

import condo_sec4Object from "actions/survey/condo/condo_sec4Data.js";

import PropTypes from "prop-types";
import surveyInfo from "actions/survey/surveyInfo.js";
const section3keys = [
  "st_buildingname",
  "st_roomtype",
  "st_pricelenid",
  "st_allunit",
  "st_allsigned",
  "st_remaining",
  "st_newsigned",
  "st_transfered",
  "st_newtransfered",
];

class CondoStep4 extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      memberStatus: this.props.memberStatus ? this.props.memberStatus : 1,
      price_allRange: [],
      st_maxUnit: [],
      sum: {
        sellprice: 0,
        allunit: 0,
        allsigned: 0,
        remaining: 0,
        newsigned: 0,
        transfered: 0,
        newtransfered: 0,
      },
      // new state start here
      part2_cattype: [],
      part2_catfloor: [],
      part2_catprice: [],
      part2_allunit: [],
      part4_cattype: [],
      price_selected: [],
      // noti core
      noti: false,
      open: false,
      color: "info",
      message: "",
      place: "tc",

      // oldpropjid: "",

      token: "",
      btLoading: false,
      // value: 0,
      // createModal: false,
      // showingInfoWindow: false,
      // activeMarker: {},
      // selectedPlace: {},
      // markerPosition: { lat: null, lng: null },
      // welfareCheck: [],
      // openNotification: false,
      // language: "th",
      // st_unit_placeholder: "เลือกอาคารและประเภทห้องก่อน",

      // st_detail:
      // main st_detail
      st_detail: [],
      st_pricerange: [],
      disabled_stcUnit: true,
      // istype_land: false,
      // stc detail state change
      st_type_State: "error",
      st_floor_Data: "",
      st_floor_State: "",
      st_pricelenid_State: "",
      st_unit_State: "",
      st_allsigned_State: "",
      st_remaining_State: "",
      st_newsigned_State: "",
      st_transfered_State: "",
      st_newtransfered_State: "",
      // st_notconstruct_State: "",
      // st_underconstruct_State: "",
      // st_finished_State: "",
      // st_newconstruct_State: "",
      // st_newfinished_State: "",
      // st_rmnotconstruct_State: "",
      // st_rmunderconstruct_State: "",
      // st_rmfinished_State: "",
      // tooltip state value
      tooltip_st_allsigned: "",
      tooltip_st_remaining: "",
      tooltip_st_newsigned: "",
      tooltip_st_transfered: "",
      tooltip_st_newtransfered: "",
      // tooltip_st_notconstruct: "",
      // tooltip_st_underconstruct: "",
      // tooltip_st_finished: "",
      // tooltip_st_newconstruct: "",
      // tooltip_st_newfinished: "",
      // tooltip_st_rmnotconstruct: "",
      // tooltip_st_rmunderconstruct: "",
      // tooltip_st_rmfinished: "",
      price_selectedBlank: [],
      // start of column render
      column: [
        {
          title: "ชื่ออาคาร",
          field: "st_buildingname", // ส่งเป็น อาคาร A - สตูดิโอ
          type: "string",
          editable: "onAdd",
          render: (rowData) => {
            // console.log(rowData.st_buildingname);
            // console.log(rowData);
            return rowData
              ? this.render_buildingname_showName(rowData.st_buildingname)
              : null;
          },
          editComponent: (props) => (
            <FormControl
              fullWidth
              className={this.props.classes.selectFormControl}
              style={{
                margin: "0 0 17px 0",
                position: "relative",
                paddingTop: "27px",
                verticalAlign: "unset",
              }}
            >
              <InputLabel
                className={this.props.classes.selectLabel}
                style={{
                  top: "10px",
                  color:
                    this.state.st_type_State === ""
                      ? "#AAAAAA"
                      : this.state.st_type_State === "success"
                      ? "green"
                      : "red",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "1.42857",
                }}
              >
                โปรดเลือก
              </InputLabel>
              <Select
                style={{
                  marginTop: "0px",
                  textAlign: "start",
                  fontSize: "16px",
                }}
                MenuProps={{
                  className: this.props.classes.selectMenu,
                }}
                classes={{
                  select: this.props.classes.select,
                }}
                value={props.value ? props.value : ""}
                onChange={(event) => this.change(event, props, "st_type")}
              >
                {this.state.part4_cattype.map((cat) => (
                  <MenuItem
                    classes={{ root: this.props.classes.selectMenuItem }}
                    key={cat.value}
                    value={cat.value}
                    name={cat.label}
                    min={cat.price_min}
                    max={cat.price_max}
                    buildStatus={cat.buildStatus}
                    style={{ backgroundColor: "#fff" }}
                  >
                    {cat.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ),
        },
        {
          title: "ราคาขาย",
          field: "st_pricelenid",
          editable: "onAdd",
          render: (rowData) => (rowData ? this.render_roomtype(rowData) : null),
          editComponent: (props) => (
            <FormControl
              fullWidth
              className={this.props.classes.selectFormControl}
              style={{
                margin: "0 0 17px 0",
                position: "relative",
                paddingTop: "27px",
                verticalAlign: "unset",
              }}
            >
              <InputLabel
                className={this.props.classes.selectLabel}
                style={{
                  top: "10px",
                  color:
                    this.state.st_pricelenid_State === ""
                      ? "#AAAAAA"
                      : this.state.st_pricelenid_State === "success"
                      ? "green"
                      : "red",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "1.42857",
                }}
              >
                {this.state.st_pricelenid_State !== "success"
                  ? "เลือกประเภทก่อน"
                  : "โปรดเลือก"}
              </InputLabel>
              <Select
                style={{
                  marginTop: "0px",
                  textAlign: "start",
                  fontSize: "16px",
                }}
                MenuProps={{
                  className: this.props.classes.selectMenu,
                }}
                classes={{
                  select: this.props.classes.select,
                }}
                value={props.value ? props.value : ""}
                onChange={(event) => this.change(event, props, "st_pricelenid")}
                disabled={this.state.st_type_State !== "success"}
              >
                {this.state.st_pricerange.length === 0
                  ? null
                  : this.checkDisabledRow(
                      this.state.st_pricerange,
                      props.rowData
                    )}
              </Select>
            </FormControl>
          ),
        },
        {
          title: "หน่วยในผังโครงการ",
          field: "st_allunit",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.st_allunit_State === "error" ? "**" : undefined
              }
              success={this.state.st_allunit_State === "success"}
              error={this.state.st_allunit_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, props, "st_allunit"),
                onFocus: (event) =>
                  this.setState({
                    tooltip_st_allunit: "",
                  }),
                value: props.value ? props.value : "",
              }}
              // tooltipText={this.state.tooltip_st_allunit}
              helperText={this.state.tooltip_st_allunit}
            />
          ),
        },
        {
          title: "ทำสัญญาจะซื้อขายแล้ว",
          emptyValue: 0,
          field: "st_allsigned",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.st_allsigned_State === "error" ? "**" : undefined
              }
              success={this.state.st_allsigned_State === "success"}
              error={this.state.st_allsigned_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, props, "st_allsigned"),

                value: props.value ? props.value : "",
              }}
              tooltipText={this.state.tooltip_st_allsigned}
            />
          ),
        },
        {
          title: "เหลือขาย",
          emptyValue: 0,
          field: "st_remaining",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.st_remaining_State === "error" ? "**" : undefined
              }
              success={this.state.st_remaining_State === "success"}
              error={this.state.st_remaining_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, props, "st_remaining"),

                value: props.value ? props.value : "",
              }}
              tooltipText={this.state.tooltip_st_remaining}
            />
          ),
        },
        {
          title: "ทำสัญญาจะซื้อขายใหม่",
          emptyValue: 0,
          field: "st_newsigned",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.st_newsigned_State === "error" ? "**" : undefined
              }
              success={this.state.st_newsigned_State === "success"}
              error={this.state.st_newsigned_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, props, "st_newsigned"),

                value: props.value ? props.value : "",
              }}
              tooltipText={this.state.tooltip_st_newsigned}
            />
          ),
        },
        {
          title: "โอนกรรมสิทธิ์ทั้งหมด",
          field: "st_transfered",

          //initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.st_transfered_State === "error" ? "**" : undefined
              }
              success={this.state.st_transfered_State === "success"}
              error={this.state.st_transfered_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, props, "st_transfered"),

                value: props.value ? props.value : "",
                // disabled: true,
                disabled: this.state.st_transferedBlock,
              }}
              tooltipText={this.state.tooltip_st_transfered}
            />
          ),
        },
        {
          title: "โอนกรรมสิทธิ์ใหม่",
          field: "st_newtransfered",

          //initialEditValue: "0",
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.st_newtransfered_State === "error" ? "**" : undefined
              }
              success={this.state.st_newtransfered_State === "success"}
              error={this.state.st_newtransfered_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "st_newtransfered"),

                value: props.value ? props.value : "",
                // disabled: true,
                disabled: this.state.st_newtransferedBlock,
              }}
              tooltipText={this.state.tooltip_st_newtransfered}
            />
          ),
        },
      ],
      data: [],
      index: -1,
    };
  }

  static propTypes = {
    house_sec3Object: PropTypes.object.isRequired,
  };
  componentDidMount() {
    // console.log("props", this.props);
    this.getSecretKey();
    this.st_setWarning("");
    if (
      this.props.proj_dataTable === undefined ||
      this.props.proj_dataTable.data.length <= 0
    ) {
      // ignore
    } else {
      // console.log("sec4")
      if (
        !(
          this.props.proj_dataTable.st.length === 1 &&
          "st_buildingname" in this.props.proj_dataTable.st[0] &&
          (this.props.proj_dataTable.st[0].st_buildingname === "" ||
            this.props.proj_dataTable.st[0].st_buildingname === "N_A")
        )
      ) {
        // console.log("merge start");
        this.get_projectData(this.props.proj_dataTable);
        // console.log(this.props.proj_dataTable);
      }
    }
  }

  getProjectStatus() {
    // console.log("getProjectStatus");
    if (this.state.part1_projectStatus === 1) {
      return "โครงการปิดการขาย";
    } else if (this.state.part1_projectStatus === 2) {
      return "อยู่ระหว่างขาย";
    } else if (this.state.part1_projectStatus === 3) {
      return "เปิดขายใหม่ในช่วงสำรวจ";
    } else if (this.state.part1_projectStatus === 4) {
      return "โครงการอนาคต";
    } else if (this.state.part1_projectStatus === 5) {
      return "โครงการชะลอการขาย";
    } else if (this.state.part1_projectStatus === 6) {
      return "โครงการเปิดและปิดการขายในช่วงสำรวจ";
    }
  }

  get_projectData = (value) => {
    // console.log("get_projectData");
    this.setState({
      projectStatus: value.status,
      view_psurvey: value.view_psurvey,
    });
    this.setState(
      {
        st_detail: value.st,
      },
      () => this.merge_data()
    );
    this.calculateSum(value.st);
  };
  disabled_function = (price_id, filter_val) => {
    // console.log("disabled_function");
    switch (filter_val.length) {
      case 0:
        return false;
      default:
        if (filter_val[0].price_id.indexOf(price_id) > -1) {
          return true;
        } else {
          return false;
        }
    }
  };
  checkDisabledRow = (data, props) => {
    // console.log("checkDisabledRow");
    let takenPrice = this.state.price_selected;
    // console.log("takenPrice")
    // console.log(takenPrice)
    // console.log(props)
    // console.log(data)

    let filter_val = takenPrice.filter(function(obj) {
      if (typeof props.st_buildingname === "string") {
        return obj.label === props.st_buildingname;
      } else {
        return obj.id_val === props.st_buildingname;
      }
    });
    // console.log(filter_val)

    return data.map((price) => (
      <MenuItem
        classes={{ root: this.props.classes.selectMenuItem }}
        key={price.srpprice_id}
        value={price.srpprice_id}
        style={{ backgroundColor: "#fff" }}
        disabled={this.disabled_function(price.srpprice_id, filter_val)}
      >
        {price.srpprice_info}
        {/* {this.chk_if10m(price)} */}
      </MenuItem>
    ));
  };

  // chk_if10m(val) {
  //   if (val.srpprice_min === "20000001") {
  //     return "≥ 20,000,001";
  //   } else if (val.srpprice_max === "1000000") {
  //     return "≤ " + numeral(val.srpprice_max).format("0,0.[0]");
  //   } else {
  //     return (
  //       numeral(val.srpprice_min).format("0,0.[0]") +
  //       " - " +
  //       numeral(val.srpprice_max).format("0,0.[0]")
  //     );
  //   }
  // }
  st_setWarning(status) {
    // console.log("st_setWarning");
    this.setState({
      part1_projectStatus: status,
    });
    switch (status) {
      // โครงการปิดการขาย
      case 1:
        this.setState({
          tooltip_st_allsigned:
            "หน่วย ทำสัญญาจะซื้อจะขาย + เหลือขาย ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_st_remaining: "หน่วย เหลือขายต้องน้อยกว่า 6",
          tooltip_st_newsigned:
            "หน่วย ทำสัญญาจะซื้อจะขายใหม่ ต้องน้อยกว่า(<)หรือเท่ากับ(=) ทำสัญญาจะซื้อจะขาย",
          tooltip_st_transfered:
            "หน่วย โอนกรรมสิทธิ์แล้ว ต้องน้อยกว่า(<)หรือเท่ากับ(=) ทำสัญญาจะซื้อจะขาย",
          tooltip_st_newtransfered:
            "หน่วย โอนกรรมสิทธิ์ใหม่ ต้องน้อยกว่า(<)หรือเท่ากับ(=) โอนกรรมสิทธิ์แล้ว",
        });
        break;
      // โครงการเปิดขายใหม่ในช่วงสำรวจ
      case 3:
        this.setState({
          tooltip_st_allsigned:
            "หน่วย ทำสัญญาจะซื้อจะขาย + เหลือขาย ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_st_remaining:
            "หน่วย เหลือขาย + ทำสัญญาจะซื้อจะขาย ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_st_newsigned:
            "หน่วย ทำสัญญาจะซื้อจะขายใหม่ ต้องเท่ากับ(=) ทำสัญญาจะซื้อจะขาย",
          tooltip_st_transfered:
            "หน่วย โอนกรรมสิทธิ์แล้ว ต้องน้อยกว่า(<)หรือเท่ากับ(=) ทำสัญญาจะซื้อจะขาย",
          tooltip_st_newtransfered:
            "หน่วย โอนกรรมสิทธิ์ใหม่ ต้องเท่ากับ(=) โอนกรรมสิทธิ์แล้ว",
        });
        break;
      // โครงการในอนาคต
      case 4:
        this.setState({
          tooltip_st_allsigned: "หน่วย ทำสัญญาจะซื้อจะขาย ต้องเท่ากับ(=) 0",
          tooltip_st_remaining:
            "หน่วย เหลือขาย + ทำสัญญาจะซื้อจะขาย ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_st_newsigned: "หน่วย ทำสัญญาจะซื้อจะขายใหม่ ต้องเท่ากับ(=) 0",
          tooltip_st_transfered: "หน่วย โอนกรรมสิทธิ์แล้ว ต้องเท่ากับ(=) 0",
          tooltip_st_newtransfered: "หน่วย โอนกรรมสิทธิ์ใหม่ ต้องเท่ากับ(=) 0",
        });
        break;

      // โครงการเปิดและปิดการขายในช่วงสำรวจ
      case 6:
        this.setState({
          tooltip_st_allsigned:
            "หน่วย ทำสัญญาจะซื้อจะขาย + เหลือขาย ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_st_remaining: "หน่วย เหลือขายต้องน้อยกว่า 6",
          tooltip_st_newsigned:
            "หน่วย ทำสัญญาจะซื้อจะขายใหม่ ต้องเท่ากับ(=) ทำสัญญาจะซื้อจะขาย",
          tooltip_st_transfered:
            "หน่วย โอนกรรมสิทธิ์แล้ว ต้องน้อยกว่า(<)หรือเท่ากับ(=) ทำสัญญาจะซื้อจะขาย",
          tooltip_st_newtransfered:
            "หน่วย โอนกรรมสิทธิ์ใหม่ ต้องเท่ากับ(=) โอนกรรมสิทธิ์แล้ว",
        });
        break;
      default:
        this.setState({
          tooltip_st_allsigned:
            "หน่วย ทำสัญญาจะซื้อจะขาย + เหลือขาย ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_st_remaining:
            "หน่วย เหลือขาย + ทำสัญญาจะซื้อจะขาย ต้องเท่ากับ(=) หน่วยในผังทั้งหมด",
          tooltip_st_newsigned:
            "หน่วย ทำสัญญาจะซื้อจะขายใหม่ ต้องน้อยกว่า(<)หรือเท่ากับ(=) ทำสัญญาจะซื้อจะขาย",
          tooltip_st_transfered:
            "หน่วย โอนกรรมสิทธิ์แล้ว ต้องน้อยกว่า(<)หรือเท่ากับ(=) ทำสัญญาจะซื้อจะขาย",
          tooltip_st_newtransfered:
            "หน่วย โอนกรรมสิทธิ์ใหม่ ต้องน้อยกว่า(<)หรือเท่ากับ(=) โอนกรรมสิทธิ์แล้ว",
        });
        break;
    }
  }

  loadingButton(isLoading) {
    // console.log("loadingButton");
    if (isLoading) {
      return (
        <ClipLoader
          css={"margin-right: 10px;"}
          sizeUnit={"px"}
          size={20}
          color={"#ffffff"}
          loading={this.state.loading}
        />
      );
    } else {
      return null;
    }
  }

  get_allPrice = () => {
    // console.log("get_allPrice");
    let body = {
      srpprice_cat: 1,
      srpprice_max: 1000000000,
      srpprice_min: 0,
    };

    let request = new Request(config.API_getprice, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        let response_array = responseJson.data;
        switch (responseJson.code) {
          case 200:
            if (response_array.length === 0) {
              //
            } else {
              this.setState({
                price_allRange: response_array,
              });
            }
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  alertModal(validity) {
    // console.log("alertModal");
    this.props.surveyModal(
      <SweetAlert
        type={validity === "success" ? "success" : "warning"}
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={
          validity === "success" ? "บันทึกสำเร็จ" : "ไม่สามารถบันทึกข้อมูลได้"
        }
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      />
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 1500);
  }

  surveyModal(validity) {
    // console.log("surveyModal");
    this.props.surveyModal(
      <SweetAlert
        type="error"
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={
          validity === "save"
            ? "ไม่สามารถบันทึกเอกสารได้"
            : "ไม่สามารถส่งเอกสารได้"
        }
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      >
        {validity === "save"
          ? "กรุณาตรวจสอบและกรอกข้อมูลเอกสารที่จำเป็นให้ครบถ้วนก่อนทำการบันทึก"
          : "กรุณาตรวจสอบและกรอกข้อมูลเอกสารที่จำเป็นให้ครบถ้วนก่อนทำการส่ง"}
      </SweetAlert>
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 2500);
  }

  setOnEdit = () => {
    // console.log("setOnEdit");
    // console.log("setOnEdit")
    let arrayprice_selected = Object.assign(this.state.price_selectedBlank);
    // console.log(this.state.price_selectedBlank)
    let inputdata = this.state.st_detail;
    // console.log(inputdata)
    let new_arr = arrayprice_selected;
    new_arr.map((a) => (a.price_id = []));
    // console.log(new_arr)
    setTimeout(() => {
      for (let i = 0; i < inputdata.length; i++) {
        for (let j = 0; j < new_arr.length; j++) {
          if (inputdata[i].st_buildingname + "" === new_arr[j].label) {
            new_arr[j].price_id.push(inputdata[i].st_pricelenid);
          }
        }
      }
    }, 1000);
    // console.log("new_arr")
    // console.log(new_arr)
    this.setState({
      price_selected: new_arr,
    });
  };
  getSecretKey() {
    // console.log("getSecretKey");
    let request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          let key = responseJson.data;
          let keyutf = CryptoJS.enc.Utf8.parse(key);
          let iv = CryptoJS.enc.Base64.parse(key);

          let aesStr = localStorage.getItem("sessionP");
          let dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          let decStr = CryptoJS.enc.Utf8.stringify(dec);
          let jsonDec = JSON.parse(decStr);
          this.setState({
            token: jsonDec.token,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  componentDidUpdate(previousProps) {
    // console.log(this.state.part4_cattype);
    // console.log();
    if (
      JSON.stringify(previousProps.condosec1part2object) !==
        JSON.stringify(this.props.condosec1part2object) &&
      JSON.stringify(this.props.condo_initial_data) &&
      "condo_projectstatus" in this.props.condosec1part2object
    ) {
      this.st_setWarning(this.props.condosec1part2object.condo_projectstatus);
    }
    if (
      JSON.stringify(previousProps.condoSec1Object) !==
      JSON.stringify(this.props.condoSec1Object)
    ) {
      // console.log(this.props.condoSec1Object)

      let part1Data = this.props.condoSec1Object;
      // console.log(part1Data)
      // console.log(part1Data.condo_projectstatus)
      // console.log("st_setWarning");
      // console.log(part1Data);
      if ("condo_projectstatus" in part1Data) {
        this.st_setWarning(part1Data.condo_projectstatus);
      }
    }
    if (
      JSON.stringify(previousProps.condosec4object) !==
      JSON.stringify(this.props.condosec4object)
    ) {
      // console.log(this.props.condosec4object)
      if (this.props.condosec4object.length === 0) {
        // console.log('wipe out')
        this.setState({ st_detail: [] });
      }
    }
    if (
      JSON.stringify(previousProps.condosec3object) !==
      JSON.stringify(this.props.condosec3object)
    ) {
      let part2Data = this.props.condoSec2Object;
      let part3Data = this.props.condosec3object;
      this.generateCat_type(part3Data, part2Data);
      //this.generateCat_floor(part2Data);
      setTimeout(async () => {
        await this.getloweruper_price();
        await this.setOnEdit();
      }, 1000);
      this.generateCat_price(part3Data);
      this.generateCat_unit(part3Data);
      this.get_allPrice();
    }

    if (
      JSON.stringify(previousProps.BuildingAndUnit_sec3) !==
      JSON.stringify(this.props.BuildingAndUnit_sec3)
    ) {
    }
  }

  removeSelectedPrice = (olddata) => {
    // console.log("removeSelectedPrice");
    let arrayprice_selected = this.state.price_selected;

    let new_arr = arrayprice_selected;

    for (let i = 0; i < arrayprice_selected.length; i++) {
      let all_priceid = arrayprice_selected[i].price_id;

      let removed_index = all_priceid.indexOf(olddata.stc_pricelenid);

      if (removed_index !== -1) {
        all_priceid.splice(removed_index, 1);
      }
    }

    this.setState({
      price_selected: new_arr,
    });
  };

  editSelectedPrice = (newdata, olddata) => {
    // console.log("editSelectedPrice");
    let arrayprice_selected = this.state.price_selected;

    let new_arr = arrayprice_selected;

    for (let i = 0; i < arrayprice_selected.length; i++) {
      let all_priceid = arrayprice_selected[i].price_id;

      let removed_index = all_priceid.indexOf(olddata.stc_pricelenid);
      let exist_index = all_priceid.indexOf(newdata.stc_pricelenid);

      if (olddata.stc_pricelenid !== newdata.stc_pricelenid) {
        if (removed_index !== -1) {
          all_priceid.splice(removed_index, 1);
        }

        if (exist_index === -1) {
          all_priceid.push(newdata.stc_pricelenid);
          break;
        }
      } else {
        //
      }
    }

    this.setState({
      price_selected: new_arr,
    });
  };

  isResubmiting = () => {
    // console.log("isResubmiting");
    return this.state.projectStatus === 2;
  };
  isEditable = () => {
    // console.log("isEditable");
    return !(
      this.state.view_psurvey === true ||
      this.state.projectStatus === 1 ||
      this.state.projectStatus === 3 ||
      this.state.memberStatus === 2
    );
  };

  generateCat_type(part3Data, part2Data) {
    // console.log("generateCat_type");
    // console.log(part2Data);
    // console.log(part3Data);
    let st_detail_obj = [];
    let array_building = part3Data.sellroom_buildingname;
    let array_floortype = part3Data.sellroom_floortype;
    let array_roomtype = part3Data.sellroom_type;
    if (!array_building) {
      return;
    }
    if (!array_floortype) {
      return;
    }
    if (!array_roomtype) {
      return;
    }

    for (let i = 0; i < array_building.length; i++) {
      let merge_name = "อาคาร ";
      if (array_roomtype[i] === 1) {
        if (array_floortype[i] === 1) {
          merge_name += array_building[i] + " - ห้องชั้นเดียว - สตูดิโอ";
        } else if (array_floortype[i] === 2) {
          merge_name += array_building[i] + " - ห้องมีชั้นลอย (Loft) - สตูดิโอ";
        } else if (array_floortype[i] === 3) {
          merge_name +=
            array_building[i] + " - ห้องชุด 2 ชั้น (Duplex) - สตูดิโอ";
        } else {
          merge_name += array_building[i] + " - สตูดิโอ";
        }
      } else if (array_roomtype[i] === 2) {
        if (array_floortype[i] === 1) {
          merge_name += array_building[i] + " - ห้องชั้นเดียว - 1 ห้องนอน";
        } else if (array_floortype[i] === 2) {
          merge_name +=
            array_building[i] + " - ห้องมีชั้นลอย (Loft) - 1 ห้องนอน";
        } else if (array_floortype[i] === 3) {
          merge_name +=
            array_building[i] + " - ห้องชุด 2 ชั้น (Duplex) - 1 ห้องนอน";
        } else {
          merge_name += array_building[i] + " - 1 ห้องนอน";
        }
      } else if (array_roomtype[i] === 3) {
        if (array_floortype[i] === 1) {
          merge_name += array_building[i] + " - ห้องชั้นเดียว - 2 ห้องนอน";
        } else if (array_floortype[i] === 2) {
          merge_name +=
            array_building[i] + " - ห้องมีชั้นลอย (Loft) - 2 ห้องนอน";
        } else if (array_floortype[i] === 3) {
          merge_name +=
            array_building[i] + " - ห้องชุด 2 ชั้น (Duplex) - 2 ห้องนอน";
        } else {
          merge_name += array_building[i] + " - 2 ห้องนอน";
        }
      } else if (array_roomtype[i] === 4) {
        if (array_floortype[i] === 1) {
          merge_name +=
            array_building[i] + " - ห้องชั้นเดียว - 3 ห้องนอนขึ้นไป";
        } else if (array_floortype[i] === 2) {
          merge_name +=
            array_building[i] + " - ห้องมีชั้นลอย (Loft) - 3 ห้องนอนขึ้นไป";
        } else if (array_floortype[i] === 3) {
          merge_name +=
            array_building[i] + " - ห้องชุด 2 ชั้น (Duplex) - 3 ห้องนอนขึ้นไป";
        } else {
          merge_name += array_building[i] + " - 3 ห้องนอนขึ้นไป";
        }
      }
      let indexInPart2 = 0;
      let buildingStatus = "1";
      try {
        indexInPart2 = part2Data.bs_name.indexOf(array_building[i]);
        buildingStatus = part2Data.bs_notbuild[indexInPart2];
      } catch {}

      st_detail_obj.push({
        id: i + 1,
        name: array_building[i],
        value: i + 1,
        label: merge_name,
        cat_type: array_roomtype[i],
        buildingStatus,
        maximum_unit: part3Data.sellroom_allunitinproject[i],
      });
      this.setState({
        part4_cattype: st_detail_obj,
      });
      // console.log(st_detail_obj);
    }
  }

  generateCat_floor(part2Data) {
    // console.log("generateCat_floor");
    let st_detail_obj = [];
    let arraycat_type = part2Data.cat_type;
    let arraycat_floor = part2Data.cat_floor;
    if (!arraycat_type) {
      return;
    }
    if (!arraycat_floor) {
      return;
    }

    for (let i = 0; i < arraycat_type.length; i++) {
      let merge_floor = arraycat_floor[i];

      st_detail_obj.push({ value: i + 1, label: merge_floor });
      this.setState({
        part2_catfloor: st_detail_obj,
      });
    }
  }

  generateCat_price(part3Data) {
    // console.log("generateCat_price");
    let st_detail_obj = [];
    let arraycat_type = part3Data.sellroom_type;
    let arraycat_minprice = part3Data.sellroom_minprice;
    let arraycat_maxprice = part3Data.sellroom_maxprice;
    if (!arraycat_type) {
      return;
    }
    if (!arraycat_maxprice) {
      return;
    }
    if (!arraycat_maxprice) {
      return;
    }
    for (let i = 0; i < arraycat_type.length; i++) {
      st_detail_obj.push({
        value: i + 1,
        price_min: arraycat_minprice[i],
        price_max: arraycat_maxprice[i],
        price_type: arraycat_type[i],
      });
      this.setState({
        part4_catprice: st_detail_obj,
      });
    }
  }

  generateCat_unit(part3Data) {
    // console.log("generateCat_unit");
    let st_detail_obj = [];
    let arraycat_type = part3Data.sellroom_type;
    let arraycat_allunit = part3Data.sellroom_allunitinproject;
    if (!arraycat_type) {
      return;
    }
    if (!arraycat_allunit) {
      return;
    }
    for (let i = 0; i < arraycat_type.length; i++) {
      st_detail_obj.push({
        value: i + 1,
        unit_type: arraycat_allunit[i],
      });
      // console.log(st_detail_obj);
      this.setState({
        part3_allunit: st_detail_obj,
      });
    }
  }

  check_cattype(type) {
    // console.log("check_cattype");
    let arraycat_type = this.state.part2_cattype;

    let match_val = arraycat_type.filter(function(obj) {
      return obj.value === type;
    });
    if (match_val[0].cat_type === 5) {
      this.setState({
        istype_land: true,
      });
    } else {
      this.setState({
        istype_land: false,
      });
    }

    return match_val[0].label;
  }

  render_buildingname(type) {
    // console.log("render_buildingname");
    let arraycat = this.state.part4_cattype;

    let label = arraycat.filter(function(obj) {
      if (obj.value === type) {
        return obj.name;
      } else {
        return null;
      }
    });

    if (!label[0]) {
      return 0;
    } else {
      let val = label[0].label;
      if (val.includes("สตูดิโอ")) {
        return 1;
      } else if (val.includes("1 ห้องนอน")) {
        return 2;
      } else if (val.includes("2 ห้องนอน")) {
        return 3;
      } else if (val.includes("3 ห้องนอนขึ้นไป")) {
        return 4;
      }
    }
  }

  render_buildingname_showName(type) {
    // console.log("render_buildingname_showName");
    let arraycat = this.state.part4_cattype;

    let label = arraycat.filter(function(obj) {
      if (obj.value === parseInt(type)) {
        return obj.name;
      } else {
        return null;
      }
    });

    if (!label[0]) {
      return 0;
    } else {
      return label[0].label;
    }
  }

  get_maximum_unit(type) {
    // console.log("get_maximum_unit");
    let arraycat = this.state.part4_cattype;

    let maximum_unit = arraycat.filter(function(obj) {
      if (obj.value === parseInt(type)) {
        return obj.name;
      } else {
        return null;
      }
    });

    if (!maximum_unit[0]) {
      return 0;
    } else {
      return maximum_unit[0].maximum_unit;
    }
  }

  render_roomtype(type) {
    // console.log("render_roomtype");
    // let arraycat_type = this.state.st_pricerange;
    // console.log(type)
    //return
    let arraycat_type = this.state.price_allRange;
    // console.log(arraycat_type)
    let match_val = arraycat_type.filter(function(obj) {
      return obj.srpprice_id === parseInt(type.st_pricelenid);
    });
    // console.log(match_val)
    if (match_val.length !== 0) {
      return match_val[0].srpprice_info;
    } else {
      return type.srpprice_info;
    }
    // console.log(type.srpprice_info);
  }

  change(event, props, stateName) {
    // console.log("change");
    this.setState({
      tooltip_st_allunit: "",
    });
    switch (stateName) {
      case "st_type":
        if (this.st_selectType(event.target.value, props)) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        this.setState({ st_pricelenid_State: "error" });
        break;
      case "st_pricelenid":
        if (this.st_selectPrice(event.target.value, props)) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        this.setState({
          st_allsigned_State: "error",
          st_allunit_State: "error",
          st_remaining_State: "error",
          st_newsigned_State: "error",
          st_transfered_State: !this.state.st_transferedBlock
            ? "error"
            : "success",
          st_newtransfered_State: !this.state.st_newtransferedBlock
            ? "error"
            : "success",
        });

        break;
      // ผังโครงการ
      case "st_allunit":
        this.st_typeUnit(event.target.value, props);
        // if (this.st_typeUnit(event.target.value, props)) {
        //   this.setState({ [stateName + "_State"]: "success" });
        // } else {
        //   this.setState({ [stateName + "_State"]: "error" });
        // }
        break;
      // ขายได้สะสม
      case "st_allsigned":
        this.st_typeAllsigned(event.target.value, props);
        // if (this.st_typeAllsigned(event.target.value, props)) {
        //   this.setState({ [stateName + "_State"]: "success" });
        // } else {
        //   this.setState({ [stateName + "_State"]: "error" });
        // }
        break;
      // เหลือขาย
      case "st_remaining":
        this.st_typeRemaining(event.target.value, props);
        // if (this.st_typeRemaining(event.target.value, props)) {
        //   this.setState({ [stateName + "_State"]: "success" });
        // } else {
        //   this.setState({ [stateName + "_State"]: "error" });
        // }
        break;
      // ขายได้ใหม่ในช่วงสำรวจ
      case "st_newsigned":
        this.st_typeNewsigned(event.target.value, props);
        // if (this.st_typeNewsigned(event.target.value, props)) {
        //   this.setState({ [stateName + "_State"]: "success" });
        // } else {
        //   this.setState({ [stateName + "_State"]: "error" });
        // }
        break;
      // โอนแล้ว
      case "st_transfered":
        this.st_typeTransfered(event.target.value, props);
        // if (this.st_typeTransfered(event.target.value, props)) {
        //   this.setState({ [stateName + "_State"]: "success" });
        // } else {
        //   this.setState({ [stateName + "_State"]: "error" });
        // }

        break;
      // โอนใหม่
      case "st_newtransfered":
        this.st_typeNewtransfer(event.target.value, props);
        // if (this.st_typeNewtransfer(event.target.value, props)) {
        //   this.setState({ [stateName + "_State"]: "success" });
        // } else {
        //   this.setState({ [stateName + "_State"]: "error" });
        // }
        break;
      default:
        break;
    }
  }

  st_selectType = (event, props) => {
    // console.log("st_selectType");
    // console.log(event);
    // console.log(props);
    this.st_priceRange(event);
    this.st_setUnit(event);
    // this.check_cattype(event);
    props.onChange(event);
    try {
      // console.log(this.state.part4_cattype[event - 1]);
      if (
        !(
          this.state.part4_cattype[event - 1].buildingStatus === "1" ||
          this.state.part4_cattype[event - 1].buildingStatus === 1
        )
      ) {
        // console.log("not finish");
        this.setState({
          st_transferedBlock: true,
          st_newtransferedBlock: true,
          st_transfered: 0,
          st_newtransfered: 0,
        });
      } else {
        throw new Error();
      }
    } catch {
      // console.log(" finish");
      this.setState({
        st_transferedBlock: false,
        st_newtransferedBlock: false,
      });
    }

    return true;
  };

  st_selectPrice = (event, props) => {
    // console.log("st_selectPrice");
    props.onChange(event);
    return true;
  };
  // หน่วยในผังโครงการทั้งหมด
  st_typeUnit = (event, props) => {
    // console.log("st_typeUnit");
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let remaining = parseInt(props.rowData.st_remaining);
    let allsigned = parseInt(props.rowData.st_allsigned);
    let newsigned = parseInt(props.rowData.st_newsigned);
    let transfered = parseInt(props.rowData.st_transfered);
    let newtransfer = parseInt(props.rowData.st_newtransfered);
    // console.log(props.rowData);
    // console.log(this.state.st_detail);
    if (!this.state.st_maxUnit[0]) {
      this.setState({
        st_allunit_State: "error",
      });
    }
    let maxunit = this.get_maximum_unit(props.rowData.st_buildingname);
    // หา index ที่ไม่ใช่ บรรทัดนี้
    // edit
    let otherTotal = 0;
    if (props.rowData.tableData) {
      let other = _.filter(
        this.state.st_detail,
        (item) =>
          item.st_buildingname === props.rowData.st_buildingname &&
          item.tableData.id !== props.rowData.tableData.id
      );
      otherTotal = _.sumBy(other, (item) => parseInt(item.st_allunit));
      // add
    } else {
      let other = _.filter(
        this.state.st_detail,
        (item) => item.st_buildingname === props.rowData.st_buildingname
      );
      otherTotal = _.sumBy(other, (item) => parseInt(item.st_allunit));
    }
    // console.log(other);
    // console.log(this.get_maximum_unit(props.rowData.))

    // let maxunit = this.state.st_maxUnit[0].unit_type;

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      this.setState({
        tooltip_st_allunit:
          "หน่วยในผังโครงการทั้งหมด รวมกันห้ามเกิน " + (maxunit - otherTotal),
      });
      if (val + otherTotal > maxunit) {
        this.showNotification("warning", "ห้ามเกินหน่วยในผังโครงการทั้งหมด");
        return;
      }
      if (val <= maxunit) {
        props.onChange(numeral(event).format("0"));
        switch (proStatus) {
          case 3:
            if (!isNaN(allsigned) && !isNaN(remaining)) {
              if (val === allsigned + remaining) {
                this.setState({
                  st_allunit_State: "success",
                  st_allsigned_State: "success",
                  st_remaining_State: "success",
                });
              } else {
                this.setState({
                  st_allunit_State: "error",
                  st_allsigned_State: "error",
                  st_remaining_State: "error",
                });
                return;
              }
            }
            if (!isNaN(allsigned) && !isNaN(newsigned)) {
              if (allsigned === newsigned) {
                this.setState({
                  st_allsigned_State: "success",
                  st_newsigned_State: "success",
                });
              } else {
                this.setState({
                  st_allsigned_State: "error",
                  st_newsigned_State: "error",
                });
                return;
              }
            }
            if (!isNaN(allsigned) && !isNaN(transfered)) {
              if (allsigned >= transfered) {
                this.setState({
                  st_allsigned_State: "success",
                  st_transfered_State: "success",
                });
              } else {
                this.setState({
                  st_allsigned_State: "error",
                  st_transfered_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(newtransfer)) {
              if (transfered === newtransfer) {
                this.setState({
                  st_transfered_State: "success",
                  st_newtransfered_State: "success",
                });
              } else {
                this.setState({
                  st_transfered_State: "error",
                  st_newtransfered_State: "error",
                });
                return;
              }
            }
            return;
          case 4:
            if (!isNaN(allsigned)) {
              if (allsigned === 0) {
                this.setState({
                  st_allsigned_State: "success",
                });
                if (!isNaN(remaining)) {
                  if (val === remaining) {
                    this.setState({
                      st_allunit_State: "success",
                      st_remaining_State: "success",
                    });
                  } else {
                    this.setState({
                      st_allunit_State: "error",
                      st_remaining_State: "error",
                    });
                    return;
                  }
                }
              } else {
                this.setState({
                  st_allsigned_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered)) {
              if (transfered === 0) {
                this.setState({
                  st_transfered_State: "success",
                });
              } else {
                this.setState({
                  st_transfered_State: "error",
                });
                return;
              }
            }
            return;
          case 6:
            if (!isNaN(allsigned) && !isNaN(remaining)) {
              if (val === allsigned + remaining) {
                this.setState({
                  st_allunit_State: "success",
                  st_allsigned_State: "success",
                  st_remaining_State: "success",
                });
              } else {
                this.setState({
                  st_allunit_State: "error",
                  st_allsigned_State: "error",
                  st_remaining_State: "error",
                });
                return;
              }
            }
            if (!isNaN(allsigned) && !isNaN(newsigned)) {
              if (allsigned === newsigned) {
                this.setState({
                  st_allsigned_State: "success",
                  st_newsigned_State: "success",
                });
              } else {
                this.setState({
                  st_allsigned_State: "error",
                  st_newsigned_State: "error",
                });
                return;
              }
            }
            if (!isNaN(allsigned) && !isNaN(transfered)) {
              if (allsigned >= transfered) {
                this.setState({
                  st_allsigned_State: "success",
                  st_transfered_State: "success",
                });
              } else {
                this.setState({
                  st_allsigned_State: "error",
                  st_transfered_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(newtransfer)) {
              if (transfered === newtransfer) {
                this.setState({
                  st_transfered_State: "success",
                  st_newtransfered_State: "success",
                });
              } else {
                this.setState({
                  st_transfered_State: "error",
                  st_newtransfered_State: "error",
                });
                return;
              }
            }
            return;
          default:
            if (!isNaN(allsigned) && !isNaN(remaining)) {
              if (val === allsigned + remaining) {
                this.setState({
                  st_allunit_State: "success",
                  st_allsigned_State: "success",
                  st_remaining_State: "success",
                });
              } else {
                this.setState({
                  st_allunit_State: "error",
                  st_allsigned_State: "error",
                  st_remaining_State: "error",
                });
                return;
              }
            }
            if (!isNaN(allsigned) && !isNaN(newsigned)) {
              if (allsigned >= newsigned) {
                this.setState({
                  st_allsigned_State: "success",
                  st_newsigned_State: "success",
                });
              } else {
                this.setState({
                  st_allsigned_State: "error",
                  st_newsigned_State: "error",
                });
                return;
              }
            }
            if (!isNaN(allsigned) && !isNaN(transfered)) {
              if (allsigned >= transfered) {
                this.setState({
                  st_allsigned_State: "success",
                  st_transfered_State: "success",
                });
              } else {
                this.setState({
                  st_allsigned_State: "error",
                  st_transfered_State: "error",
                });
                return;
              }
            }
            if (!isNaN(transfered) && !isNaN(newtransfer)) {
              if (transfered >= newtransfer) {
                this.setState({
                  st_transfered_State: "success",
                  st_newtransfered_State: "success",
                });
              } else {
                this.setState({
                  st_transfered_State: "error",
                  st_newtransfered_State: "error",
                });
                return;
              }
            }
            return;
        }
      } else {
        this.setState({
          st_allunit_State: "error",
        });
        this.showNotification("warning", "ห้ามเกินหน่วยในผังโครงการทั้งหมด");
        return;
      }
    }
    props.onChange("");
    this.setState({
      st_allunit_State: "error",
    });
    return;
  };

  // ทำสัญญาจะซื้อจะขาย
  st_typeAllsigned = (event, props) => {
    // console.log("st_typeAllsigned");
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let unit = parseInt(props.rowData.st_allunit);
    let remaining = parseInt(props.rowData.st_remaining);
    let newsigned = parseInt(props.rowData.st_newsigned);
    let transfered = parseInt(props.rowData.st_transfered);
    let newtransfer = parseInt(props.rowData.st_newtransfered);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      // console.log(val);
      // console.log(unit);
      // console.log(remaining);
      props.onChange(numeral(event).format("0"));
      switch (proStatus) {
        case 3:
          if (!isNaN(unit) && !isNaN(remaining)) {
            if (unit === val + remaining) {
              this.setState({
                st_allunit_State: "success",
                st_allsigned_State: "success",
                st_remaining_State: "success",
              });
            } else {
              this.setState({
                st_allunit_State: "error",
                st_allsigned_State: "error",
                st_remaining_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newsigned)) {
            if (val === newsigned) {
              this.setState({
                st_allsigned_State: "success",
                st_newsigned_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (val >= transfered) {
              this.setState({
                st_allsigned_State: "success",
                st_transfered_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered === newtransfer) {
              this.setState({
                st_transfered_State: "success",
                st_newtransfered_State: "success",
              });
            } else {
              this.setState({
                st_transfered_State: "error",
                st_newtransfered_State: "error",
              });
              return;
            }
          }
          return;
        case 4:
          if (val === 0) {
            this.setState({
              st_allsigned_State: "success",
            });
            if (!isNaN(unit) && !isNaN(remaining)) {
              if (unit === remaining) {
                this.setState({
                  st_allunit_State: "success",
                  st_remaining_State: "success",
                });
              } else {
                this.setState({
                  st_allunit_State: "error",
                  st_remaining_State: "error",
                });
                return;
              }
            }
          } else {
            this.setState({
              st_allsigned_State: "error",
            });
            return;
          }
          if (!isNaN(transfered)) {
            if (transfered === 0) {
              this.setState({
                st_transfered_State: "success",
              });
            } else {
              this.setState({
                st_transfered_State: "error",
              });
              return;
            }
          }
          return;
        case 6:
          if (!isNaN(unit) && !isNaN(remaining)) {
            if (unit === val + remaining) {
              this.setState({
                st_allunit_State: "success",
                st_allsigned_State: "success",
                st_remaining_State: "success",
              });
            } else {
              this.setState({
                st_allunit_State: "error",
                st_allsigned_State: "error",
                st_remaining_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newsigned)) {
            if (val === newsigned) {
              this.setState({
                st_allsigned_State: "success",
                st_newsigned_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (val >= transfered) {
              this.setState({
                st_allsigned_State: "success",
                st_transfered_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered === newtransfer) {
              this.setState({
                st_transfered_State: "success",
                st_newtransfered_State: "success",
              });
            } else {
              this.setState({
                st_transfered_State: "error",
                st_newtransfered_State: "error",
              });
              return;
            }
          }
          return;
        default:
          if (!isNaN(unit) && !isNaN(remaining)) {
            if (unit === val + remaining) {
              this.setState({
                st_allunit_State: "success",
                st_allsigned_State: "success",
                st_remaining_State: "success",
              });
            } else {
              this.setState({
                st_allunit_State: "error",
                st_allsigned_State: "error",
                st_remaining_State: "error",
              });
              return;
            }
          }
          if (!isNaN(newsigned)) {
            if (val >= newsigned) {
              this.setState({
                st_allsigned_State: "success",
                st_newsigned_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered)) {
            if (val >= transfered) {
              this.setState({
                st_allsigned_State: "success",
                st_transfered_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered >= newtransfer) {
              this.setState({
                st_transfered_State: "success",
                st_newtransfered_State: "success",
              });
            } else {
              this.setState({
                st_transfered_State: "error",
                st_newtransfered_State: "error",
              });
              return;
            }
          }
          return;
      }
    }
    props.onChange("");
    // console.log("v");
    this.setState({
      st_allsigned_State: "error",
    });
    return;
  };

  // เหลือขาย
  st_typeRemaining = (event, props) => {
    // console.log("st_typeRemaining");
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let unit = parseInt(props.rowData.st_allunit);
    let allsigned = parseInt(props.rowData.st_allsigned);
    let newsigned = parseInt(props.rowData.st_newsigned);
    let transfered = parseInt(props.rowData.st_transfered);
    let newtransfer = parseInt(props.rowData.st_newtransfered);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(event).format("0"));
      switch (proStatus) {
        case 1:
          if (!isNaN(unit) && !isNaN(allsigned)) {
            if (unit === val + allsigned) {
              this.setState({
                st_allunit_State: "success",
                st_allsigned_State: "success",
                st_remaining_State: "success",
              });
            } else {
              this.setState({
                st_allunit_State: "error",
                st_allsigned_State: "error",
                st_remaining_State: "error",
              });
              return;
            }
          }
          if (val < 6) {
            this.setState({
              st_remaining_State: "success",
            });
          } else {
            this.setState({
              st_remaining_State: "error",
            });
            return;
          }
          if (!isNaN(allsigned) && !isNaN(newsigned)) {
            if (allsigned === newsigned) {
              this.setState({
                st_allsigned_State: "success",
                st_newsigned_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(allsigned) && !isNaN(transfered)) {
            if (allsigned >= transfered) {
              this.setState({
                st_allsigned_State: "success",
                st_transfered_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered >= newtransfer) {
              this.setState({
                st_transfered_State: "success",
                st_newtransfered_State: "success",
              });
            } else {
              this.setState({
                st_transfered_State: "error",
                st_newtransfered_State: "error",
              });
              return;
            }
          }
          return;
        case 3:
          if (!isNaN(unit) && !isNaN(allsigned)) {
            if (unit === val + allsigned) {
              this.setState({
                st_allunit_State: "success",
                st_allsigned_State: "success",
                st_remaining_State: "success",
              });
            } else {
              this.setState({
                st_allunit_State: "error",
                st_allsigned_State: "error",
                st_remaining_State: "error",
              });
              return;
            }
          }
          if (!isNaN(allsigned) && !isNaN(newsigned)) {
            if (allsigned === newsigned) {
              this.setState({
                st_allsigned_State: "success",
                st_newsigned_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(allsigned) && !isNaN(transfered)) {
            if (allsigned >= transfered) {
              this.setState({
                st_allsigned_State: "success",
                st_transfered_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered === newtransfer) {
              this.setState({
                st_transfered_State: "success",
                st_newtransfered_State: "success",
              });
            } else {
              this.setState({
                st_transfered_State: "error",
                st_newtransfered_State: "error",
              });
              return;
            }
          }
          return;
        case 4:
          if (!isNaN(allsigned)) {
            if (allsigned === 0) {
              this.setState({
                st_allsigned_State: "success",
              });
              if (!isNaN(unit)) {
                if (unit === val) {
                  this.setState({
                    st_allunit_State: "success",
                    st_remaining_State: "success",
                  });
                } else {
                  this.setState({
                    st_allunit_State: "error",
                    st_remaining_State: "error",
                  });
                  return;
                }
              } else {
                this.setState({
                  st_allsigned_State: "error",
                });
                return;
              }
            }
          }
          if (!isNaN(transfered)) {
            if (transfered === 0) {
              this.setState({
                st_transfered_State: "success",
              });
            } else {
              this.setState({
                st_transfered_State: "error",
              });
              return;
            }
          }
          return;
        case 6:
          if (!isNaN(unit) && !isNaN(allsigned)) {
            if (unit === val + allsigned) {
              this.setState({
                st_allunit_State: "success",
                st_allsigned_State: "success",
                st_remaining_State: "success",
              });
            } else {
              this.setState({
                st_allunit_State: "error",
                st_allsigned_State: "error",
                st_remaining_State: "error",
              });
              return;
            }
          }
          if (val < 6) {
            this.setState({
              st_remaining_State: "success",
            });
          } else {
            this.setState({
              st_remaining_State: "error",
            });
            return;
          }
          if (!isNaN(allsigned) && !isNaN(newsigned)) {
            if (allsigned === newsigned) {
              this.setState({
                st_allsigned_State: "success",
                st_newsigned_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(allsigned) && !isNaN(transfered)) {
            if (allsigned >= transfered) {
              this.setState({
                st_allsigned_State: "success",
                st_transfered_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered === newtransfer) {
              this.setState({
                st_transfered_State: "success",
                st_newtransfered_State: "success",
              });
            } else {
              this.setState({
                st_transfered_State: "error",
                st_newtransfered_State: "error",
              });
              return;
            }
          }
          return;
        default:
          if (!isNaN(unit) && !isNaN(allsigned)) {
            if (unit === val + allsigned) {
              this.setState({
                st_allunit_State: "success",
                st_allsigned_State: "success",
                st_remaining_State: "success",
              });
            } else {
              this.setState({
                st_allunit_State: "error",
                st_allsigned_State: "error",
                st_remaining_State: "error",
              });
              return;
            }
          }
          if (!isNaN(allsigned) && !isNaN(newsigned)) {
            if (allsigned >= newsigned) {
              this.setState({
                st_allsigned_State: "success",
                st_newsigned_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(allsigned) && !isNaN(transfered)) {
            if (allsigned >= transfered) {
              this.setState({
                st_allsigned_State: "success",
                st_transfered_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered >= newtransfer) {
              this.setState({
                st_transfered_State: "success",
                st_newtransfered_State: "success",
              });
            } else {
              this.setState({
                st_transfered_State: "error",
                st_newtransfered_State: "error",
              });
              return;
            }
          }
          return;
      }
    }
    props.onChange("");
    this.setState({
      st_remaining_State: "error",
    });
    return;
  };

  // ทำสัญญาจะซื้อจะขายใหม่
  st_typeNewsigned = (event, props) => {
    // console.log("st_typeNewsigned");
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let allsigned = parseInt(props.rowData.st_allsigned);
    let transfered = parseInt(props.rowData.st_transfered);
    let newtransfer = parseInt(props.rowData.st_newtransfered);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(event).format("0"));
      switch (proStatus) {
        case 3:
          if (!isNaN(allsigned)) {
            if (val === allsigned) {
              this.setState({
                st_allsigned_State: "success",
                st_newsigned_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(allsigned) && !isNaN(transfered)) {
            if (allsigned >= transfered) {
              this.setState({
                st_allsigned_State: "success",
                st_transfered_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered === newtransfer) {
              this.setState({
                st_transfered_State: "success",
                st_newtransfered_State: "success",
              });
            } else {
              this.setState({
                st_transfered_State: "error",
                st_newtransfered_State: "error",
              });
              return;
            }
          }
          return;
        case 4:
          if (val === 0) {
            this.setState({
              st_newsigned_State: "success",
            });
          } else {
            this.setState({
              st_newsigned_State: "error",
            });
            return;
          }
          if (!isNaN(transfered)) {
            if (transfered === 0) {
              this.setState({
                st_transfered_State: "success",
              });
            } else {
              this.setState({
                st_transfered_State: "error",
              });
              return;
            }
          }
          return;
        case 6:
          if (!isNaN(allsigned)) {
            if (val === allsigned) {
              this.setState({
                st_allsigned_State: "success",
                st_newsigned_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(allsigned) && !isNaN(transfered)) {
            if (allsigned >= transfered) {
              this.setState({
                st_allsigned_State: "success",
                st_transfered_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered === newtransfer) {
              this.setState({
                st_transfered_State: "success",
                st_newtransfered_State: "success",
              });
            } else {
              this.setState({
                st_transfered_State: "error",
                st_newtransfered_State: "error",
              });
              return;
            }
          }
          return;
        default:
          if (!isNaN(allsigned)) {
            if (val <= allsigned) {
              this.setState({
                st_allsigned_State: "success",
                st_newsigned_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_newsigned_State: "error",
              });
              return;
            }
          }
          if (!isNaN(allsigned) && !isNaN(transfered)) {
            if (allsigned >= transfered) {
              this.setState({
                st_allsigned_State: "success",
                st_transfered_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_transfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(transfered) && !isNaN(newtransfer)) {
            if (transfered >= newtransfer) {
              this.setState({
                st_transfered_State: "success",
                st_newtransfered_State: "success",
              });
            } else {
              this.setState({
                st_transfered_State: "error",
                st_newtransfered_State: "error",
              });
              return;
            }
          }
          return;
      }
    }
    props.onChange("");
    this.setState({
      st_newsigned_State: "error",
    });
    return;
  };

  // โอนกรรมสิทธิ์แล้ว
  st_typeTransfered = (event, props) => {
    // console.log("st_typeTransfered");
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let allsigned = parseInt(props.rowData.st_allsigned);
    let newtransfer = parseInt(props.rowData.st_newtransfered);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      props.onChange(numeral(event).format("0"));
      switch (proStatus) {
        case 3:
          if (!isNaN(newtransfer)) {
            if (val === newtransfer) {
              this.setState({
                st_transfered_State: "success",
                st_newtransfered_State: "success",
              });
            } else {
              this.setState({
                st_transfered_State: "error",
                st_newtransfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(allsigned)) {
            if (val <= allsigned) {
              this.setState({
                st_allsigned_State: "success",
                st_transfered_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_transfered_State: "error",
              });
              return;
            }
          }
          return;
        case 4:
          if (val === 0) {
            this.setState({
              st_transfered_State: "success",
            });
          } else {
            this.setState({
              st_transfered_State: "error",
            });
            return;
          }
          return;
        case 6:
          if (!isNaN(newtransfer)) {
            if (val === newtransfer) {
              this.setState({
                st_transfered_State: "success",
                st_newtransfered_State: "success",
              });
            } else {
              this.setState({
                st_transfered_State: "error",
                st_newtransfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(allsigned)) {
            if (val <= allsigned) {
              this.setState({
                st_allsigned_State: "success",
                st_transfered_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_transfered_State: "error",
              });
              return;
            }
          }
          return;
        default:
          if (!isNaN(newtransfer)) {
            if (val >= newtransfer) {
              this.setState({
                st_transfered_State: "success",
                st_newtransfered_State: "success",
              });
            } else {
              this.setState({
                st_transfered_State: "error",
                st_newtransfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(allsigned)) {
            if (val <= allsigned) {
              this.setState({
                st_allsigned_State: "success",
                st_transfered_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_transfered_State: "error",
              });
              return;
            }
          }
          return;
      }
    }
    props.onChange("");
    this.setState({
      st_transfered_State: "error",
    });
    return;
  };

  // Recheck
  // โอนกรรมสิทธิ์ใหม่
  st_typeNewtransfer = (event, props) => {
    // console.log("st_typeNewtransfer");
    let proStatus = this.state.part1_projectStatus;
    let val = parseInt(event);
    let allsigned = parseInt(props.rowData.st_allsigned);
    let transfered = parseInt(props.rowData.st_transfered);

    let phoneRex = /^[0-9]+$/;
    if (phoneRex.test(val)) {
      // console.log(val);
      // console.log(transfered);
      props.onChange(numeral(event).format("0"));
      switch (proStatus) {
        case 3:
          if (!isNaN(transfered)) {
            if (val === transfered) {
              this.setState({
                st_transfered_State: "success",
                st_newtransfered_State: "success",
              });
            } else {
              this.setState({
                st_transfered_State: "error",
                st_newtransfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(allsigned) && !isNaN(transfered)) {
            if (allsigned >= transfered) {
              this.setState({
                st_allsigned_State: "success",
                st_transfered_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_transfered_State: "error",
              });
              return;
            }
          }
          return;
        case 4:
          if (val === 0) {
            this.setState({
              st_transfered_State: "success",
              st_newtransfered_State: "success",
            });
          } else {
            this.setState({
              st_transfered_State: "error",
              st_newtransfered_State: "error",
            });
            return;
          }
          if (!isNaN(transfered)) {
            if (val === 0) {
              this.setState({
                st_transfered_State: "success",
              });
            } else {
              this.setState({
                st_transfered_State: "error",
              });
              return;
            }
          }
          return;
        case 6:
          if (!isNaN(transfered)) {
            if (val === transfered) {
              this.setState({
                st_transfered_State: "success",
                st_newtransfered_State: "success",
              });
            } else {
              this.setState({
                st_transfered_State: "error",
                st_newtransfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(allsigned) && !isNaN(transfered)) {
            if (allsigned >= transfered) {
              this.setState({
                st_allsigned_State: "success",
                st_transfered_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_transfered_State: "error",
              });
              return;
            }
          }
          return;
        default:
          if (!isNaN(transfered)) {
            if (val <= transfered) {
              this.setState({
                st_transfered_State: "success",
                st_newtransfered_State: "success",
              });
            } else {
              this.setState({
                st_transfered_State: "error",
                st_newtransfered_State: "error",
              });
              return;
            }
          }
          if (!isNaN(allsigned) && !isNaN(transfered)) {
            if (allsigned >= transfered) {
              this.setState({
                st_allsigned_State: "success",
                st_transfered_State: "success",
              });
            } else {
              this.setState({
                st_allsigned_State: "error",
                st_transfered_State: "error",
              });
              return;
            }
          }
          return;
      }
    }
    props.onChange("");
    // console.log("fix");
    this.setState({
      st_newtransfered_State: "error",
    });
    return;
  };

  // trigger function
  st_priceRange = (event) => {
    // console.log("st_priceRange");
    let arraycat_price = this.state.part4_catprice;
    let match_val = arraycat_price.filter(function(obj) {
      return obj.value === event;
    });

    let body = {
      srpprice_cat: 1,
      srpprice_max: match_val[0].price_max,
      srpprice_min: match_val[0].price_min,
    };

    let request = new Request(config.API_getprice, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        let response_array = responseJson.data;

        switch (responseJson.code) {
          case 200:
            if (response_array.length === 0) {
            } else {
              this.setState({
                st_pricerange: response_array,
              });
            }
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  st_setUnit = (event) => {
    // console.log("st_setUnit");
    let arraycat_unit = this.state.part3_allunit;
    let all_type = this.state.part4_cattype;
    let addedData = this.state.st_detail;
    let unit_value = arraycat_unit.filter(function(obj) {
      return obj.value === event;
    });

    let match_val = all_type.filter(function(obj) {
      return obj.value === unit_value[0].value;
    });

    let match_result = addedData.filter(function(obj) {
      return obj.st_buildingname === match_val[0].value;
    });

    let rowmaxUnit = unit_value[0].unit_type;
    for (let i = 0; i < match_result.length; i++) {
      rowmaxUnit = parseInt(rowmaxUnit) - parseInt(match_result[i].st_allunit);
    }
    // console.log(unit_value)
    switch (match_result.length) {
      case 0:
        this.setState({
          st_maxUnit: unit_value,
          tooltip_st_allunit:
            "หน่วยในผังโครงการทั้งหมด รวมกันห้ามเกิน " +
            unit_value[0].unit_type,
          // st_unit_placeholder: "หน่วยสูงสุด = " + unit_value[0].unit_type,
          // st_allsigned_State: "error",
          // st_newsigned_State: "error"
        });
        break;
      default:
        this.setState({
          st_maxUnit: [{ value: unit_value[0].value, unit_type: rowmaxUnit }],
          tooltip_st_allunit:
            "หน่วยในผังโครงการทั้งหมด รวมกันห้ามเกิน " + rowmaxUnit,
          // st_unit_placeholder: "หน่วยสูงสุด = " + rowmaxUnit,
          // st_allsigned_State: "error",
          // st_newsigned_State: "error"
        });
        break;
    }
  };

  filter_typeval = (data) => {
    // console.log("filter_typeval");
    let arraycat_type = this.state.part4_cattype;
    let match_val = arraycat_type.filter(function(obj) {
      return obj.value === data;
    });
    return match_val[0].label;
  };

  filter_typeval_reverse = (label) => {
    // console.log("filter_typeval_reverse");
    let arraycat_type = this.state.part4_cattype;
    let match_val = arraycat_type.filter(function(obj) {
      return obj.label === label;
    });
    if (match_val.length > 0) {
      return match_val[0].value;
    } else {
      return null;
    }
  };

  checkDataInRowBeforeAdd = (data) => {
    // console.log("checkDataInRowBeforeAdd");
    let {
      st_buildingname_State,
      st_type_State,
      st_pricelenid_State,
      st_allunit_State,
      st_allsigned_State,
      st_remaining_State,
      st_newsigned_State,
      st_transfered_State,
      st_newtransfered_State,
    } = this.state;
    // console.log(data);
    if (
      st_buildingname_State === "error" ||
      st_type_State === "error" ||
      st_pricelenid_State === "error" ||
      st_allunit_State === "error" ||
      st_allsigned_State === "error" ||
      st_remaining_State === "error" ||
      st_newsigned_State === "error" ||
      st_transfered_State === "error" ||
      st_newtransfered_State === "error"
    ) {
      return false;
    } else {
      this.seperateLimitData(data);
      return true;
    }
  };

  checkDataInRowBeforeEdit = (data) => {
    // console.log("checkDataInRowBeforeEdit");
    let {
      // st_buildingname_State,
      // st_type_State,
      // st_pricelenid_State,
      st_allunit_State,
      st_allsigned_State,
      st_remaining_State,
      st_newsigned_State,
      st_transfered_State,
      st_newtransfered_State,
    } = this.state;
    // console.log(data);
    if (
      // st_buildingname_State === "error" ||
      // st_type_State === "error" ||
      // st_pricelenid_State === "error" ||
      st_allunit_State === "error" ||
      st_allsigned_State === "error" ||
      st_remaining_State === "error" ||
      st_newsigned_State === "error" ||
      st_transfered_State === "error" ||
      st_newtransfered_State === "error"
    ) {
      return false;
    } else {
      this.seperateLimitData(data);
      return true;
    }
  };

  seperateLimitData = (inputdata) => {
    // console.log("seperateLimitData");
    let arrayprice_selected = this.state.price_selected;

    let new_arr = arrayprice_selected;

    for (let i = 0; i < arrayprice_selected.length; i++) {
      if (inputdata.stc_type === arrayprice_selected[i].label) {
        new_arr[i].price_id.push(inputdata.stc_pricelenid);
      } else {
        //
      }
    }

    this.setState({
      price_selected: new_arr,
    });
  };
  async call_merge_sec4() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.merge_data());
      }, 1000);
    });
  }

  merge_data() {
    // console.log("merge_data");
    let st_detail_obj = {};
    // let part4_cattype = this.state.part4_cattype;
    // console.log(this.state.st_detail);
    if (this.state.st_detail.length > 0) {
      this.state.st_detail.map(function(value) {
        return section3keys.forEach(function(i) {
          if (!st_detail_obj[i]) {
            st_detail_obj[i] = [];
          }
          if (!(i in value)) {
            value[i] = "0";
          } else {
            if (i !== "st_roomtype") {
              value[i] = Number(value[i]);
            } else if (i === "st_roomtype") {
              value[i] = String(value[i]);
            }
            // value[i] = value[i];
          }
          // สตูดิโอ 1 ห้องนอน 2 ห้องนอน 3 ห้องนอนขึ้นไป
          st_detail_obj[i].push(value[i]);
        });
      });
      // console.log(st_detail_obj);
    }
    let N = this.state.st_detail.length;
    let condodata_section4 = {
      st_buildingname:
        st_detail_obj && "st_buildingname" in st_detail_obj
          ? st_detail_obj["st_buildingname"]
          : Array(N).fill(""),
      st_roomtype:
        st_detail_obj && "st_roomtype" in st_detail_obj
          ? st_detail_obj["st_roomtype"]
          : Array(N).fill(""),
      st_pricelenid:
        st_detail_obj && "st_pricelenid" in st_detail_obj
          ? st_detail_obj["st_pricelenid"]
          : Array(N).fill(1),
      st_allunit:
        st_detail_obj && "st_allunit" in st_detail_obj
          ? st_detail_obj["st_allunit"]
          : Array(N).fill(0),
      st_allsigned:
        st_detail_obj && "st_allsigned" in st_detail_obj
          ? st_detail_obj["st_allsigned"]
          : Array(N).fill(0),
      st_remaining:
        st_detail_obj && "st_remaining" in st_detail_obj
          ? st_detail_obj["st_remaining"]
          : Array(N).fill(0),
      st_transfered:
        st_detail_obj && "st_transfered" in st_detail_obj
          ? st_detail_obj["st_transfered"]
          : Array(N).fill(0),
      st_newsigned:
        st_detail_obj && "st_newsigned" in st_detail_obj
          ? st_detail_obj["st_newsigned"]
          : Array(N).fill(0),
      st_newtransfered:
        st_detail_obj && "st_newtransfered" in st_detail_obj
          ? st_detail_obj["st_newtransfered"]
          : Array(N).fill(0),
    };
    this.props.condo_sec4Object(condodata_section4);
    return condodata_section4;
  }

  checkUnitAndBuilding = () => {
    // console.log("checkUnitAndBuilding");
    return true;
  };
  async getloweruper_price() {
    // console.log("getloweruper_price");
    // console.log("getloweruper_price")
    // console.log(this.props.condosec3object)
    let price_merge = [];
    let price_selectedBlank = [];
    if (
      this.props.condosec3object.sellroom_type &&
      this.props.condosec3object.sellroom_maxprice &&
      this.props.condosec3object.sellroom_minprice
    ) {
      let array_catype = this.props.condosec3object.sellroom_type;
      let array_catprice = this.props.condosec3object;

      // let data_initial = [];

      for (let i = 0; i < array_catype.length; i++) {
        let body = {
          srpprice_cat: 1,
          srpprice_max: array_catprice.sellroom_maxprice[i],
          srpprice_min: array_catprice.sellroom_minprice[i],
        };

        let request = new Request(config.API_getprice, {
          method: "POST",
          headers: new Headers({ "Content-Type": "application/json" }),
          body: JSON.stringify(body),
        });

        await fetch(request)
          .then((response) => response.json())
          .then((responseJson) => {
            // console.log(responseJson)
            let response_array = responseJson.data;
            switch (responseJson.code) {
              case 200:
                if (response_array.length === 0) {
                  //
                } else if (response_array.length === 1) {
                  price_selectedBlank.push({
                    id_val: i + 1,
                    price_id: [],
                    label: i + 1 + "",
                  });
                  price_merge.push({
                    id_val: i + 1,
                    price_id: [response_array[0].srpprice_id],
                    // label: array_catype[i].label
                  });
                } else {
                  // console.log("empty len")
                  price_merge.push({
                    id_val: i + 1,
                    price_id: [
                      response_array[0].srpprice_id,
                      response_array[response_array.length - 1].srpprice_id,
                    ],
                    // label: array_catype[i].label
                  });

                  price_selectedBlank.push({
                    id_val: i + 1,
                    price_id: [],
                    label: i + 1 + "",
                  });
                }
                break;
              default:
                break;
            }
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    }
    // console.log("price_selectedBlank")
    // console.log(price_selectedBlank)
    this.setState({
      price_limit: price_merge,
      // price_selected: price_selectedBlank,
      price_selectedBlank: price_selectedBlank,
    });
  }
  async isValidated() {
    // console.log("isValidated");
    if (await this.checkUnitAndBuilding()) {
      let body = await this.call_merge_sec4();
      let limit_value = this.state.price_limit;
      let selectd_value = this.state.price_selected;
      let proj_status = this.state.part1_projectStatus;
      let arraycat = this.state.part4_cattype;

      let result_ja = true;
      let sumunit = 0;
      let sumunit_remain = 0;
      let sumunit_allsigned = 0;
      let sumunit_newsigned = 0;
      let sumunit_transfered = 0;
      let sumunit_newtransfered = 0;
      let conditionCase = "";

      let arraycat_unit = this.state.part3_allunit;
      let st_maxUnit = 0;
      // console.log(arraycat_unit);
      // console.log(st_maxUnit);

      // console.log(body)
      // console.log(limit_value)
      // console.log(selectd_value)
      for (let i = 0; i < limit_value.length; i++) {
        if (limit_value[i].length !== 0) {
          let min = limit_value[i].price_id[0];
          let max = limit_value[i].price_id[1];
          // console.log(min)
          // console.log(max)
          // console.log(selectd_value[i].price_id)

          if (min && selectd_value[i].price_id.indexOf(min) === -1) {
            // console.log("no_low "+min)
            conditionCase = "no_uplow";
            result_ja = false;
          }

          if (max && selectd_value[i].price_id.indexOf(max) === -1) {
            // console.log("no_up "+max)
            conditionCase = "no_uplow";
            result_ja = false;
          }
        }
      }
      if (result_ja) {
        for (let i = 0; i < arraycat_unit.length; i++) {
          if (arraycat_unit[i] && arraycat_unit[i].unit_type) {
            st_maxUnit = st_maxUnit + parseInt(arraycat_unit[i].unit_type);
          }
        }
        for (let j = 0; j < body.st_allunit.length; j++) {
          arraycat.filter(function(obj) {
            if (obj.value === body.st_buildingname[j]) {
              if (
                parseInt(obj.buildingStatus) !== 1 &&
                (parseInt(body.st_transfered[j]) !== 0 ||
                  parseInt(body.st_newtransfered[j] !== 0))
              ) {
                conditionCase = "unmatched_build";
                result_ja = false;
              }
            }
          });

          sumunit = sumunit + parseInt(body.st_allunit[j]);
          sumunit_remain = sumunit_remain + parseInt(body.st_remaining[j]);
          sumunit_allsigned =
            sumunit_allsigned + parseInt(body.st_allsigned[j]);
          sumunit_newsigned =
            sumunit_newsigned + parseInt(body.st_newsigned[j]);
          sumunit_transfered =
            sumunit_transfered + parseInt(body.st_transfered[j]);
          sumunit_newtransfered =
            sumunit_newtransfered + parseInt(body.st_newtransfered[j]);

          if (proj_status === 1) {
            if (
              parseInt(body.st_newsigned[j]) > parseInt(body.st_allsigned[j])
            ) {
              conditionCase = "not_zero";
              result_ja = false;
              // console.log("1-1");
            } else if (
              parseInt(body.st_transfered[j]) > parseInt(body.st_allsigned[j])
            ) {
              conditionCase = "not_zero";
              result_ja = false;
              // console.log("1-2");
            } else if (
              parseInt(body.st_newtransfered[j]) >
              parseInt(body.st_transfered[j])
            ) {
              conditionCase = "not_zero";
              result_ja = false;
              // console.log("1-3");
            }
          } else if (proj_status === 2) {
            if (
              parseInt(body.st_newsigned[j]) > parseInt(body.st_allsigned[j])
            ) {
              conditionCase = "not_zero";
              result_ja = false;
              // console.log("2-1");
            } else if (
              parseInt(body.st_transfered[j]) > parseInt(body.st_allsigned[j])
            ) {
              conditionCase = "not_zero";
              result_ja = false;
              // console.log("2-2");
            } else if (
              parseInt(body.st_newtransfered[j]) >
              parseInt(body.st_transfered[j])
            ) {
              conditionCase = "not_zero";
              result_ja = false;
              // console.log("2-3");
            }
          } else if (proj_status === 3) {
            if (
              parseInt(body.st_newsigned[j]) !== parseInt(body.st_allsigned[j])
            ) {
              conditionCase = "not_zero";
              result_ja = false;
              // console.log("3-1");
            } else if (
              parseInt(body.st_transfered[j]) > parseInt(body.st_allsigned[j])
            ) {
              conditionCase = "not_zero";
              result_ja = false;
              // console.log("3-2");
            } else if (
              parseInt(body.st_newtransfered[j]) !==
              parseInt(body.st_transfered[j])
            ) {
              conditionCase = "not_zero";
              result_ja = false;
              // console.log("3-3");
            }
          } else if (proj_status === 5) {
            if (parseInt(body.st_newsigned[j]) !== 0) {
              conditionCase = "not_zero";
              result_ja = false;
              // console.log("5-1");
            } else if (
              parseInt(body.st_transfered[j]) > parseInt(body.st_allsigned[j])
            ) {
              conditionCase = "not_zero";
              result_ja = false;
              // console.log("5-2");
            } else if (
              parseInt(body.st_newtransfered[j]) >
              parseInt(body.st_transfered[j])
            ) {
              conditionCase = "not_zero";
              result_ja = false;
              // console.log("5-3");
            }
          } else if (proj_status === 6) {
            if (
              parseInt(body.st_newsigned[j]) !== parseInt(body.st_allsigned[j])
            ) {
              conditionCase = "not_zero";
              result_ja = false;
              // console.log("6-1");
            } else if (
              parseInt(body.st_transfered[j]) > parseInt(body.st_allsigned[j])
            ) {
              conditionCase = "not_zero";
              result_ja = false;
              // console.log("6-2");
            } else if (
              parseInt(body.st_newtransfered[j]) !==
              parseInt(body.st_transfered[j])
            ) {
              conditionCase = "not_zero";
              result_ja = false;
              // console.log("6-3");
            }
          }
        }

        if (result_ja) {
          if (sumunit === st_maxUnit) {
            conditionCase = "";
            result_ja = true;
          } else {
            conditionCase = "no_maxunit";
            result_ja = false;
          }
          if ((proj_status === 2 || proj_status === 3) && sumunit_remain < 6) {
            conditionCase = "remain_more";
            result_ja = false;
          }
          if ((proj_status === 1 || proj_status === 6) && sumunit_remain >= 6) {
            conditionCase = "remain_less";
            result_ja = false;
          }
          if (
            proj_status === 4 &&
            (sumunit_allsigned !== 0 ||
              sumunit_newsigned !== 0 ||
              sumunit_transfered !== 0 ||
              sumunit_newtransfered !== 0)
          ) {
            conditionCase = "not_zero";
            result_ja = false;
          }
        }
      }
      switch (result_ja) {
        case true:
          if (
            body.st_buildingname.length === 0 ||
            body.st_pricelenid.length === 0 ||
            body.st_allunit.length === 0 ||
            body.st_allsigned.length === 0 ||
            body.st_remaining.length === 0 ||
            body.st_transfered.length === 0 ||
            body.st_newsigned.length === 0 ||
            body.st_roomtype.length === 0
          ) {
            this.surveyModal("error");
            return false;
          } else {
            return true;
          }

        default:
          this.surveyModal_lock(conditionCase);
          return false;
      }
    } else {
      alert(
        "หน่วยรวมไม่ตรงกับส่วนที่ 2 และ 3 หรือไม่ได้ใส่รายละเอียดในบางอาคาร กรุณาตรวจสอบหน่วย และอาคารให้ตรงกับส่วนก่อนหน้า"
      );
    }
  }
  surveyModal_lock(conditionCase) {
    let error_text = "";
    switch (conditionCase) {
      case "no_maxunit":
        error_text =
          "หน่วยในแต่ละประเภทโครงการทั้งหมด รวมกันต้องเท่ากับหน่วยในผังโครงการทั้งหมด";
        break;
      case "no_uplow":
        error_text =
          "กรุณาใส่ช่วงราคาให้ครบถ้วน (ราคาต่ำสุด - สูงสุดในแต่ละประเภท)";
        break;
      case "remain_less":
        error_text = "หน่วยเหลือขายต้องน้อยกว่า 6";
        break;
      case "remain_more":
        error_text = "หน่วยเหลือขายต้องมากกว่า 6";
        break;
      case "unmatched_build":
        error_text = "หน่วยไม่สอดคล้องกับสถานะการก่อสร้าง";
        break;
      case "not_zero":
        error_text = "หน่วยไม่สอดคล้องกับสถานะโครงการ";
        break;
      default:
        error_text = "Something went wrong";
    }
    this.props.surveyModal(
      <SweetAlert
        type="error"
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={"ข้อมูลส่วนที่ 4 ไม่ถูกต้อง"}
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      >
        {error_text}
      </SweetAlert>
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 2500);
  }
  async submit_save() {
    // console.log("submit_save");
    this.setState({
      btLoading: true,
    });
    let token = this.state.token;
    let part1 = this.props.condoSec1Object;
    let part2 = this.props.condoSec2Object;
    let part3 = this.props.condosec3object;
    // console.log(part1)
    // console.log(part2)
    // console.log(part3)
    let body = await this.call_merge_sec4();

    if (
      body.st_buildingname.length === 0 ||
      body.st_pricelenid.length === 0 ||
      body.st_allunit.length === 0 ||
      body.st_allsigned.length === 0 ||
      body.st_remaining.length === 0 ||
      body.st_newsigned.length === 0 ||
      body.st_transfered.length === 0 ||
      body.st_newtransfered.length === 0
    ) {
      this.surveyModal("save");
      this.setState({
        btLoading: false,
      });
    } else {
      let source = {
        ...part1,
        ...part2,
        ...part3,
        ...body,
      };

      let target = this.props.condo_initial_data;
      let log_condo_sec_4 = { event: "Insert or update condo section 4" };
      let mergebody = Object.assign(target, source, log_condo_sec_4);
      // console.log(mergebody);
      let request = new Request(config.API_save_condo, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          token: token,
        }),
        body: JSON.stringify(mergebody),
      });
      // return
      fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            btLoading: false,
          });
          if (responseJson.code === 200) {
            this.alertModal("success");
          } else {
            this.errorModal("400");
          }
        })
        .catch(() => {
          this.setState({
            btLoading: false,
          });
        });
    }
  }
  errorModal(validity) {
    // console.log("errorModal");
    this.props.surveyModal(
      <SweetAlert
        type={validity === "400" ? "warning" : "danger"}
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={validity === "400" ? "บันทึกไม่สำเร็จ" : "การเชื่อมต่อมีปัญหา"}
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      />
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 1500);
  }
  successAlert(title, message, status) {
    // console.log("successAlert");
    this.setState({
      alert: (
        <SweetAlert
          {...status}
          className="SweetAlert"
          title={<div>{title}</div>}
          onConfirm={() => this.setState({ alert: null })}
          confirmBtnCssClass="modalBt"
          cancelBtnCssClass="modalBt modalBtCancel"
          confirmBtnText={"ยืนยัน"}
          cancelBtnText={"ยกเลิก"}
        >
          {message}
        </SweetAlert>
      ),
    });
  }

  showNotification(type, message) {
    // console.log("showNotification");
    this.setState({ open: true, color: type, message: message });
    setTimeout(
      function() {
        this.setState({ open: false });
      }.bind(this),
      12000
    );
  }

  resetAll_State() {
    // console.log("resetAll_State");
    this.setState({
      st_buildingname_State: "",
      st_type_State: "error",
      st_pricelenid_State: "",
      st_allunit_State: "",
      st_allsigned_State: "",
      st_remaining_State: "",
      st_newsigned_State: "",
      st_transfered_State: "",
      st_newtransfered_State: "",
      st_maxUnit: [],
    });
  }

  sendState() {
    // console.log("sendState");
    return this.state;
  }

  calculateSum = (st_detail) => {
    // console.log("calculateSum");
    // sum
    st_detail = st_detail || [];
    let allunit = 0;
    let allsigned = 0;
    let remaining = 0;
    let newsigned = 0;
    let transfered = 0;
    let newtransfered = 0;

    for (let i = 0; i < st_detail.length; i++) {
      let allunit_t = st_detail[i].st_allunit;
      let allsigned_t = st_detail[i].st_allsigned;
      let remaining_t = st_detail[i].st_remaining;
      let newsigned_t = st_detail[i].st_newsigned;
      let transfered_t = st_detail[i].st_transfered;
      let newtransfered_t = st_detail[i].st_newtransfered;
      if (allunit_t) {
        allunit += parseFloat(allunit_t);
      }
      if (allsigned_t) {
        allsigned += parseFloat(allsigned_t);
      }
      if (remaining_t) {
        remaining += parseFloat(remaining_t);
      }
      if (newsigned_t) {
        newsigned += parseFloat(newsigned_t);
      }
      if (transfered_t) {
        transfered += parseFloat(transfered_t);
      }
      if (newtransfered_t) {
        newtransfered += parseFloat(newtransfered_t);
      }
    }

    let sum = {
      allunit: allunit,
      allsigned: allsigned,
      remaining: remaining,
      newsigned: newsigned,
      transfered: transfered,
      newtransfered: newtransfered,
    };

    this.setState({
      sum: sum,
    });
  };
  blockEdit(isEdit) {
    // console.log("blockEdit");
    if (isEdit) {
      return (
        <div
          style={{
            position: "absolute",
            backgroundColor: "rgba(0,0,0,0)",
            zIndex: "10000",
            width: "100%",
            height: "100%",
            left: 0,
            right: 0,
            cursor: "not-allowed",
          }}
        ></div>
      );
    } else {
      //
    }
  }

  render() {
    // console.log("render");
    const bt_style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "120px",
      height: "40px",
      fontSize: "16px",
      borderRadius: "0.2rem",
      backgroundColor: "#384D97",
      color: "white",
    };
    const StyleHeader = {
      border: "1px solid #dddddd",
      textAlign: "center",
      borderLeft: "none",
      maxWidth: "fit-content",
    };
    const StyleTHeader = {
      fontSize: "14px",
      width: "100%",
      borderCollapse: "collapse",
      whiteSpace: "nowrap",
      textAlign: "center",
    };
    return (
      <GridContainer style={{ padding: "15px" }}>
        <ListItem xs={6}>
          <ListItemText
            primary={<Typography variant="caption">สถานะโครงการ</Typography>}
            secondary={this.getProjectStatus()}
          ></ListItemText>
        </ListItem>
        <Snackbar
          place={this.state.place}
          color={this.state.color}
          message={this.state.message}
          open={this.state.open}
          closeNotification={() => this.setState({ open: false })}
          close
        />
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <h4>
            <b>ข้อมูลการขาย การโอนกรรมสิทธิ์ และการก่อสร้าง</b>
          </h4>
          {this.state.alert}
          <label>เปิดขายทั้งหมดจำแนกตามประเภทห้องชุด ดังนี้</label>
          <MaterialTable
            style={{
              width: "100%",
              backgroundColor: "#fff",
              textAlign: "center",
            }}
            icons={{
              Add: () => (
                <div style={bt_style}>
                  <Add style={{ marginRight: "10px" }} />
                  เพิ่มข้อมูล
                </div>
              ),
            }}
            components={{
              Header: (props) => (
                <thead style={StyleTHeader}>
                  <tr>
                    <td style={StyleHeader}>อาคารและประเภทห้อง</td>
                    <td style={StyleHeader}>ราคาขาย</td>
                    <td style={StyleHeader}>หน่วยในผังโครงการทั้งหมด</td>
                    <td style={StyleHeader}>ทำสัญญาจะซื้อจะขายแล้วทั้งหมด</td>
                    <td style={StyleHeader}>เหลือขาย</td>
                    <td style={StyleHeader}>ทำสัญญาจะซื้อจะขายใหม่</td>
                    <td style={StyleHeader}>โอนกรรมสิทธิ์แล้วทั้งหมด</td>
                    <td style={StyleHeader}>โอนกรรมสิทธิ์ใหม่</td>
                    <td rowSpan="2" style={StyleHeader}>
                      จัดการข้อมูล
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" style={StyleHeader}>
                      รวม
                    </td>
                    <td style={StyleHeader}>
                      {numeral(this.state.sum.allunit).format("0,0")} หน่วย
                    </td>
                    <td style={StyleHeader}>
                      {numeral(this.state.sum.allsigned).format("0,0")} หน่วย
                    </td>
                    <td style={StyleHeader}>
                      {numeral(this.state.sum.remaining).format("0,0")} หน่วย
                    </td>
                    <td style={StyleHeader}>
                      {numeral(this.state.sum.newsigned).format("0,0")} หน่วย
                    </td>
                    <td style={StyleHeader}>
                      {numeral(this.state.sum.transfered).format("0,0")} หน่วย
                    </td>
                    <td style={StyleHeader}>
                      {numeral(this.state.sum.newtransfered).format("0,0")}{" "}
                      หน่วย
                    </td>
                  </tr>
                </thead>
              ),
            }}
            localization={{
              header: {
                actions: "การจัดการ",
              },
              body: {
                addTooltip: "เพิ่มข้อมูล",
                editTooltip: "แก้ไขข้อมูล",
                deleteTooltip: "ลบข้อมูล",
                emptyDataSourceMessage: "ไม่มีข้อมูล",
                editRow: {
                  deleteText: "ยืนยันการลบข้อมูล",
                  saveTooltip: "บันทึก",
                  cancelTooltip: "ยกเลิก",
                },
              },
              toolbar: {
                searchTooltip: "ค้นหา",
                searchPlaceholder: "ค้นหา",
              },
              pagination: {
                labelRowsSelect: "แถว",
                labelDisplayedRows: " {from}-{to} จาก {count}",
                firstTooltip: "หน้าแรก",
                previousTooltip: "ก่อนหน้า",
                nextTooltip: "ถัดไป",
                lastTooltip: "สุดท้าย",
              },
            }}
            title=""
            columns={this.state.column}
            data={this.state.st_detail}
            options={{
              pageSize: this.state.st_detail ? this.state.st_detail.length : 10,
              pageSizeOptions: [
                10,
                20,
                30,
                this.state.st_detail ? this.state.st_detail.length : 50,
              ],
              actionsColumnIndex: -1,
              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.tableData.id % 2 === 0
                    ? "rgba(0, 0, 0, 0.03)"
                    : "#FFF",
              }),
              headerStyle: {
                fontSize: "0.9rem",
                fontWeight: "600",
              },
            }}
            // onRowDataChange={() => console.log(1)}
            editable={
              !(
                this.state.view_psurvey === true ||
                this.state.projectStatus === 1 ||
                this.state.projectStatus === 3 ||
                this.state.memberStatus === 2
              )
                ? {
                    onRowAdd: (newData) =>
                      new Promise((resolve, reject) => {
                        // console.log("onRowAdd");
                        section3keys.forEach((row) => {
                          if (!(row in newData)) {
                            newData[row] = "0";
                          }
                        });
                        if (this.checkDataInRowBeforeAdd(newData)) {
                          setTimeout(() => {
                            // if (this.checkList(newData) === true) {
                            let st_detail = this.state.st_detail;
                            newData.id = st_detail.length;

                            let roomtype = this.render_buildingname(
                              newData.st_buildingname
                            );

                            newData["st_roomtype"] = roomtype;
                            st_detail.push(newData);
                            this.resetAll_State();
                            this.setState(
                              { st_detail, st_type_State: "error" },
                              () => {
                                this.calculateSum(st_detail);
                                this.setOnEdit();
                                resolve();
                              }
                            );
                            // }
                            resolve();
                          }, 1000);
                        } else {
                          this.showNotification(
                            "warning",
                            "กรุณาตรวจสอบข้อมูลก่อนทำการบันทึก"
                          );
                          reject();
                        }
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        // console.log("onRowUpdate");
                        if (this.checkDataInRowBeforeEdit(newData)) {
                          setTimeout(() => {
                            // console.log(newData)
                            section3keys.forEach((row) => {
                              if (!(row in newData)) {
                                newData[row] = "0";
                              }
                            });
                            // if (this.checkList(newData) === true) {
                            let cat_detail = this.state.st_detail;
                            let index = cat_detail.indexOf(oldData);
                            cat_detail[index] = newData;
                            // this.resetAll_State();
                            this.setState({ tooltip_st_allunit: "" });
                            this.setState({ cat_detail }, () => {
                              this.calculateSum(cat_detail);
                              this.setOnEdit();
                              resolve();
                            });
                            // }
                            resolve();
                          }, 500);
                        } else {
                          this.showNotification(
                            "warning",
                            "กรุณาตรวจสอบข้อมูลก่อนทำการบันทึก"
                          );
                          reject();
                        }
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        // console.log("onRowDelete");
                        setTimeout(() => {
                          {
                            let cat_detail = this.state.st_detail;
                            let index = cat_detail.indexOf(oldData);
                            cat_detail.splice(index, 1);
                            this.setState({ st_detail: cat_detail }, () => {
                              this.calculateSum(cat_detail);
                              this.setOnEdit();
                              resolve();
                            });
                          }
                          resolve();
                        }, 500);
                      }),
                    onRowDataChange: (newData, oldData) =>
                      new Promise((resolve) => {
                        // console.log("onRowDataChange");
                        setTimeout(() => {
                          {
                            let cat_detail = this.state.cat_detail;
                            let index = cat_detail.indexOf(oldData);
                            cat_detail[index] = newData;
                            this.setState({ st_detail: cat_detail }, () => {
                              this.setOnEdit();
                              resolve();
                            });
                          }
                          resolve();
                        }, 500);
                      }),
                  }
                : {}
            }
          />
        </GridItem>

        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
            padding: "0 15px",
            marginTop: "10px",
          }}
        >
          {(this.state.projectStatus !== 3 ||
            this.state.view_psurvey === false) &&
          this.isEditable() &&
          !this.isResubmiting() ? (
            <Button
              color="info"
              size="sm"
              style={{ fontSize: "16px" }}
              onClick={() => this.submit_save()}
              disabled={this.state.btLoading}
            >
              {this.loadingButton(this.state.btLoading)}
              บันทึก
            </Button>
          ) : null}
        </div>
      </GridContainer>
    );
  }
}

CondoStep4.propTypes = {
  classes: PropTypes.object,
  condoSec1Object: PropTypes.any,
  condoSec2Object: PropTypes.any,
  condoSec3Object: PropTypes.any,
  BuildingAndUnit_sec3: PropTypes.any,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  surveyInfo: (payload) => dispatch(surveyInfo(payload)),
  condo_sec3Object: (payload) => dispatch(condo_sec3Object(payload)),
  condoSec3_Object: (payload) => dispatch(condoSec3_Object(payload)),
  condo_sec4Object: (payload) => dispatch(condo_sec4Object(payload)),
  surveyModal: (payload) => dispatch(surveyModal(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(regularFormsStyle)(CondoStep4));
