/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import CryptoJS from "crypto-js";
import * as config from "configure/configServer.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInputTooltips.js";

import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormLabel from "@material-ui/core/FormLabel";

import Button from "components/CustomButtons/Button.js";

import defaultImage from "assets/img/image_placeholder.png";
import defaultAvatar from "assets/img/placeholder.jpg";

import { connect } from "react-redux";

import pressSave from "actions/survey/condo/toggleSave.js";

import condo_sec1_part1Object from "actions/survey/condo/condo_sec1_part1Data.js";
import notiToggle from "actions/survey/notificationToggle.js";
import project_datatable from "actions/survey/psurvey_editproj.js";
import imageuploading from "actions/uploading_image.js";

import { API_add_image } from "configure/configServer.js";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

class BasicInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noti: false,
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      // toggle feature
      selltype_trigger: "",
      // form
      survey_nameth: "",
      survey_namethState: "",
      survey_nameen: "",
      survey_nameenState: "",
      survey_ownertype: "",
      survey_ownertypeState: "",
      survey_forelder: "",
      survey_forelderState: "",
      survey_mixeduse: "",
      survey_mixeduseState: "",
      survey_selltype: "",
      survey_selltypeState: "",
      survey_selltypearea: "",
      survey_selltypeareaState: "success",
      survey_rai: "",
      survey_raiState: "",
      survey_ngan: "",
      survey_nganState: "",
      survey_sqwa: "",
      survey_sqwaState: "",
      survey_frontimg: "",
      survey_frontimgState: "",
      keyToken: "",
    };

    this.handle_ownertype = this.handle_ownertype.bind(this);
    this.handle_forelder = this.handle_forelder.bind(this);
    this.handle_mixeduse = this.handle_mixeduse.bind(this);
    this.handle_typesales = this.handle_typesales.bind(this);
  }

  componentDidUpdate(previousProps) {
    // eslint-disable-next-line react/prop-types
    if (
      JSON.stringify(previousProps.oldproj_data) !==
      JSON.stringify(this.props.oldproj_data)
    ) {
      // console.log(this.props.oldproj_data)
      this.get_projectData(this.props.oldproj_data, true);
    }
  }

  getToken() {
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          this.setState({
            keyToken: responseJson.data,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  showNotification(status, message) {
    this.setState({
      noti: true,
    });
    this.props.notiToggle(
      <Snackbar
        place="tc"
        color={status}
        icon={AddAlert}
        message={message}
        open={this.state.noti}
      />
    );

    setTimeout(() => {
      this.setState({
        noti: false,
      });
      this.props.notiToggle(
        <Snackbar
          place="tc"
          color={status}
          icon={AddAlert}
          message={message}
          open={this.state.noti}
        />
      );
    }, 1000);
  }

  fileInput = React.createRef();
  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    // eslint-disable-next-line no-constant-condition
    if ((this.fileInput.current.value = null)) {
      reader.onloadend = () => {
        this.setState({
          file: null,
          imagePreviewUrl: defaultImage,
          survey_frontimg: "",
        });
      };
      reader.readAsDataURL(file);
    } else {
      reader.onloadend = () => {
        let base64 = reader.result;
        if (this.props.project_id !== undefined) {
          this.setState({
            file: file,
            imagePreviewUrl: reader.result,
          });
          this.props.imageuploading(true);
          this.getImagePath(base64.split(",")[1]);
        } else {
          //
          this.showNotification("danger", "กรุณาทำการปักหมุดก่อนเพิ่มรูปภาพ");
        }
      };
      reader.readAsDataURL(file);
    }
  };

  getImagePath(base64) {
    const key = this.state.keyToken;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("sessionP");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    var tokenData = jsonDec.token;

    let body = {
      projectid: this.props.project_id,
      img: [base64],
      cat: "condo_frontprojectplaceimg",
      form: "condo",
    };

    var request = new Request(API_add_image, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify(body),
    });
    fetch(request)
      .then((response) => {
        return response.json();
      })
      .then((responseJson) => {
        if (responseJson.code === 200) {
          this.props.imageuploading(false);
          this.setState({
            survey_frontimg: responseJson.data[0],
            survey_frontimgState: "success",
          });
        } else {
          this.setState({
            survey_frontimg: "",
            survey_frontimgState: "error",
          });
        }
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  };
  handleClick = () => {
    this.fileInput.current.click();
  };
  handleRemove = () => {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      survey_frontimg: "",
      survey_frontimgState: "error",
    });
    this.fileInput.current.value = null;
  };

  componentDidMount() {
    this.props.onRef(this);

    this.getToken();

    if (
      this.props.proj_dataTable === undefined ||
      this.props.proj_dataTable.data.length <= 0
    ) {
      // ignore
      this.props.project_datatable(undefined);
    } else {
      this.get_projectData(this.props.proj_dataTable, false);
    }
  }

  get_projectData = (value, isOld) => {
    const edit_data = value.data[0];

    var condo_frontprojectplaceimg =
      edit_data.condo_frontprojectplaceimg.length === 0 ||
      edit_data.condo_frontprojectplaceimg[0] === ""
        ? null
        : edit_data.condo_frontprojectplaceimg[0];
    if (
      condo_frontprojectplaceimg !== null &&
      condo_frontprojectplaceimg.length > 0 &&
      condo_frontprojectplaceimg[0] !== ""
    ) {
      this.setState({
        imagePreviewUrl:
          config.requestUrl + edit_data.condo_frontprojectplaceimg[0],
        survey_frontimg: edit_data.condo_frontprojectplaceimg,
      });
    }
    this.setState({
      survey_nameth: edit_data.condo_nameth,
      survey_nameen: edit_data.condo_nameeng,
      survey_ownertype: edit_data.condo_typeowner,
      survey_forelder: edit_data.condo_forelder,
      survey_mixeduse: edit_data.condo_mixeduse,
      survey_selltype: edit_data.condo_presaleproject,
      survey_selltypearea: edit_data.condo_presalephase,
      survey_rai: edit_data.condo_rai || 0,
      survey_ngan: edit_data.condo_ngan || 0,
      survey_sqwa: edit_data.condo_sqwa || 0,
      survey_frontimg: !isOld ? edit_data.condo_frontprojectplaceimg[0] : "",
      imagePreviewUrl: !isOld
        ? config.requestUrl + condo_frontprojectplaceimg
        : defaultImage,

      // setstate of all exist variable
      survey_namethState: this.chk_empty(edit_data.condo_nameth),
      survey_nameenState: this.chk_empty(edit_data.condo_nameeng),
      survey_ownertypeState: this.chk_empty(edit_data.condo_typeowner),
      survey_forelderState: this.chk_empty(edit_data.condo_forelder),
      survey_mixeduseState: this.chk_empty(edit_data.condo_mixeduse),
      survey_selltypeState: this.chk_empty(edit_data.condo_presaleproject),
      survey_selltypeareaState: this.chk_empty(edit_data.condo_presalephase),
      survey_raiState: this.chk_empty(edit_data.condo_rai),
      survey_nganState: this.chk_empty(edit_data.condo_ngan),
      survey_sqwaState: this.chk_empty(edit_data.condo_sqwa),
    });
    if (!this.state.survey_frontimg) {
      this.setState({
        survey_frontimgState: this.chk_empty(
          edit_data.condo_frontprojectplaceimg[0]
        ),
      });
    }
  };

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  // function that returns true if value is meet profile details, false otherwise
  verifyName(value) {
    var nameRex = /^[A-Za-zก-๙0-9]+$/;
    if (nameRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that returns true if value is meet profile details, false otherwise
  callArea_rai(rai) {
    var isNumber = /^[0-9]+$/;
    if (isNumber.test(rai)) {
      return true;
    }
    return false;
  }
  callArea_ngan(ngan) {
    var isNumber = /^[0-9]+$/;
    if (isNumber.test(ngan) && ngan < 4) {
      return true;
    }
    return false;
  }
  callArea_wa(wa) {
    var isNumber = /^[0-9.]+$/;
    if (isNumber.test(wa) && wa < 100) {
      return true;
    }
    return false;
  }

  // function call radio survey ownertype
  handle_ownertype(event) {
    this.setState({
      survey_ownertype: event.target.value,
      survey_ownertypeState: "success",
    });
  }

  // function call radio survey ownertype
  handle_forelder(event) {
    this.setState({
      survey_forelder: event.target.value,
      survey_forelderState: "success",
    });
  }

  // function call radio survey ownertype
  handle_mixeduse(event) {
    this.setState({
      survey_mixeduse: event.target.value,
      survey_mixeduseState: "success",
    });
  }

  // function call radio survey ownertype
  handle_typesales(event) {
    // console.log(this.state.survey_selltypeareaState);
    // console.log(event.target.value);

    if (event.target.value === "1") {
      this.setState({
        survey_selltypeareaState: "",
        survey_selltypeState: "success",
      });
    }
    this.setState({
      survey_selltype: event.target.value,
      survey_selltypeState: "success",
    });
  }

  verifyAddress(value) {
    var addressRex = /^[a-zA-Zก-๙0-9._-\s/]+$/;
    if (addressRex.test(value)) {
      return true;
    }
    return false;
  }
  verifyAddressTH(value) {
    var addressRex = /^[ก-๙0-9._-\s/()@&]+$/;
    if (addressRex.test(value)) {
      return true;
    }
    return false;
  }
  verifyAddressEN(value) {
    var addressRex = /^[a-zA-Z0-9._-\s/()@&]+$/;
    if (addressRex.test(value)) {
      return true;
    }
    return false;
  }
  verifyPostcode(value) {
    var postcodeRex = new RegExp("^(?=.*[0-9])(?=.{4})");
    if (postcodeRex.test(value)) {
      return true;
    }
    return false;
  }

  // validate forms
  change(event, stateName, type) {
    switch (type) {
      case "all":
        this.setState({ [stateName + "State"]: "success" });
        break;
      case "name":
        if (this.verifyName(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "address":
        if (this.verifyAddress(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "addressTH":
        if (this.verifyAddressTH(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "addressEN":
        if (this.verifyAddressEN(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "postcode":
        if (this.verifyPostcode(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "rai":
        if (this.callArea_rai(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "ngan":
        if (this.callArea_ngan(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "sqwa":
        if (this.callArea_wa(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }

  // handle trigger function
  // check add org trigger?
  validateRegister() {
    const {
      survey_nameth,
      survey_nameen,
      survey_ownertype,
      survey_forelder,
      survey_mixeduse,
      survey_selltype,
      survey_selltypearea,
      survey_rai,
      survey_ngan,
      survey_sqwa,
      survey_frontimg,
    } = this.state;

    var body = {
      condo_nameth: survey_nameth === "" ? "N_A" : survey_nameth,
      condo_nameeng: survey_nameen === "" ? "" : survey_nameen,
      condo_typeowner: survey_ownertype === "" ? "N_A" : survey_ownertype,
      condo_forelder: survey_forelder === "" ? "0" : survey_forelder,
      condo_mixeduse: survey_mixeduse === "" ? "0" : survey_mixeduse,
      condo_presaleproject: survey_selltype === "" ? "N_A" : survey_selltype,
      condo_presalephase: survey_selltype === "0" ? "0" : survey_selltypearea,
      condo_rai: parseInt(survey_rai),
      condo_ngan: parseInt(survey_ngan),
      condo_sqwa: parseFloat(survey_sqwa),
      condo_frontprojectplaceimg: [survey_frontimg],
    };

    this.props.condo_sec1_part1Object(body);
  }

  // check all state
  validateState(state) {
    const {
      survey_namethState,
      survey_nameenState,
      survey_selltypeareaState,
      survey_raiState,
      survey_nganState,
      survey_sqwaState,
      survey_frontimgState,
      survey_ownertypeState,
      survey_forelderState,
      survey_mixeduseState,
      survey_selltypeState,
      survey_selltype,
    } = this.state;
    this.setAllEmpty();
    // console.log({
    //   survey_namethState,
    //   survey_nameenState,
    //   survey_selltypeareaState,
    //   survey_raiState,
    //   survey_nganState,
    //   survey_sqwaState,
    //   survey_frontimgState,
    //   survey_ownertypeState,
    //   survey_forelderState,
    //   survey_mixeduseState,
    //   survey_selltypeState,
    //   survey_selltype,
    // });
    switch (state) {
      case "save":
        if (
          survey_namethState === "error" ||
          survey_nameenState === "error" ||
          (survey_selltypeareaState === "error" && survey_selltype === "0") ||
          survey_raiState === "error" ||
          survey_nganState === "error" ||
          survey_sqwaState === "error" ||
          survey_ownertypeState === "error" ||
          survey_forelderState === "error" ||
          survey_mixeduseState === "error" ||
          survey_selltypeState === "error"
        ) {
          this.setAllEmpty();
          return false;
        } else {
          return true;
        }
      case "submit":
        if (
          // survey_namethState !== "error" &&
          // //survey_nameenState !== "error" &&
          // (survey_selltypeareaState !== "error" || survey_selltype!=="1")&&
          // survey_raiState !== "error" &&
          // survey_nganState !== "error" &&
          // survey_sqwaState !== "error" &&
          // survey_frontimgState !== "error" &&
          // survey_ownertypeState !== "error" &&
          // survey_forelderState !== "error" &&
          // survey_mixeduseState !== "error" &&
          // survey_selltypeState !== "error"
          survey_namethState === "success" &&
          survey_nameenState === "success" &&
          (survey_selltypeareaState === "success" || survey_selltype === "0") &&
          survey_raiState === "success" &&
          survey_nganState === "success" &&
          survey_sqwaState === "success" &&
          survey_frontimgState === "success" &&
          survey_ownertypeState === "success" &&
          survey_forelderState === "success" &&
          survey_mixeduseState === "success" &&
          survey_selltypeState === "success"
        ) {
          return true;
        } else {
          this.setAllEmpty();
          return false;
        }
      default:
        break;
    }
  }

  setAllEmpty() {
    if (this.state.survey_nameth === "") {
      this.setState({ survey_namethState: "error" });
    }
    if (this.state.survey_nameen === "") {
      this.setState({ survey_nameenState: "error" });
    }
    if (
      this.state.survey_selltype === "1" &&
      (this.state.survey_selltypearea === "" ||
        this.state.survey_selltypearea === "0")
    ) {
      this.setState({ survey_selltypeareaState: "error" });
    }
    if (this.state.survey_rai === "") {
      this.setState({ survey_raiState: "error" });
    }
    if (this.state.survey_ngan === "") {
      this.setState({ survey_nganState: "error" });
    }
    if (this.state.survey_sqwa === "") {
      this.setState({ survey_sqwaState: "error" });
    }
    if (
      this.state.survey_frontimg === "" ||
      this.state.survey_frontimg[0] === ""
    ) {
      this.setState({ survey_frontimgState: "error" });
    }
    if (this.state.survey_ownertype === "") {
      this.setState({ survey_ownertypeState: "error" });
    }
    if (this.state.survey_forelder === "") {
      this.setState({ survey_forelderState: "error" });
    }
    if (this.state.survey_mixeduse === "") {
      this.setState({ survey_mixeduseState: "error" });
    }
    if (this.state.survey_selltype === "") {
      this.setState({ survey_selltypeState: "error" });
    }
  }

  chk_selltype(selltype) {
    if (this.state.survey_selltype === "0") {
      return "success";
    } else {
      return selltype;
    }
  }

  chk_empty(val) {
    if (val === "" || val === null || val === undefined) {
      return "";
    } else {
      return "success";
    }
  }

  // reset all state in this components
  resetAllState() {
    this.setState({
      survey_namethState: "",
      survey_nameenState: "",
      survey_selltypeareaState: "",
      survey_raiState: "",
      survey_nganState: "",
      survey_sqwaState: "",
      survey_frontimgState: "",
      survey_ownertypeState: "",
      survey_forelderState: "",
      survey_mixeduseState: "",
      survey_selltypeState: "",
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <CustomInput
              success={this.state.survey_namethState === "success"}
              error={this.state.survey_namethState === "error"}
              labelText="ชื่อโครงการ(ไทย)"
              id="survey_nameth"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: this.state.survey_nameth,
                onChange: (event) =>
                  this.change(event, "survey_nameth", "addressTH"),
                type: "text",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <CustomInput
              success={this.state.survey_nameenState === "success"}
              error={this.state.survey_nameenState === "error"}
              labelText="ชื่อโครงการ(อังกฤษ) (ถ้ามี)"
              id="survey_nameen"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: this.state.survey_nameen,
                onChange: (event) =>
                  this.change(event, "survey_nameen", "addressEN"),
                type: "text",
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4} lg={4}>
            <FormLabel
              style={{
                float: "none",
                marginBottom: "15px",
                color:
                  this.state.survey_ownertypeState === "error"
                    ? "red"
                    : "black",
              }}
              className={
                classes.labelHorizontal +
                " " +
                classes.labelHorizontalRadioCheckbox
              }
            >
              ประเภทการถือครอง
            </FormLabel>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.survey_ownertype === "free"}
                    onChange={this.handle_ownertype}
                    value="free"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="กรรมสิทธิ์ (Freehold)"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.survey_ownertype === "lease"}
                    onChange={this.handle_ownertype}
                    value="lease"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="สิทธิการเช่าระยะยาว (Leasehold)"
              />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={4} lg={4}>
            <FormLabel
              style={{
                float: "none",
                marginBottom: "15px",
                color:
                  this.state.survey_forelderState === "error" ? "red" : "black",
              }}
              className={
                classes.labelHorizontal +
                " " +
                classes.labelHorizontalRadioCheckbox
              }
            >
              ประเภทโครงการเพื่อผู้สูงอายุ
            </FormLabel>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.survey_forelder === "1"}
                    onChange={this.handle_forelder}
                    value="1"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="ใช่"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.survey_forelder === "0"}
                    onChange={this.handle_forelder}
                    value="0"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="ไม่ใช่"
              />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={4} lg={4}>
            <FormLabel
              style={{
                float: "none",
                marginBottom: "15px",
                color:
                  this.state.survey_mixeduseState === "error" ? "red" : "black",
              }}
              className={
                classes.labelHorizontal +
                " " +
                classes.labelHorizontalRadioCheckbox
              }
            >
              ประเภทโครงการ Mixed-use
            </FormLabel>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.survey_mixeduse === "1"}
                    onChange={this.handle_mixeduse}
                    value="1"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="ใช่"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.survey_mixeduse === "0"}
                    onChange={this.handle_mixeduse}
                    value="0"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="ไม่ใช่"
              />
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <FormLabel
              style={{
                float: "none",
                marginBottom: "15px",
                color:
                  this.state.survey_selltypeState === "error" ? "red" : "black",
              }}
              className={
                classes.labelHorizontal +
                " " +
                classes.labelHorizontalRadioCheckbox
              }
            >
              ลักษณะการเปิดขาย
            </FormLabel>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.survey_selltype === "0"}
                    onChange={this.handle_typesales}
                    value="0"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="เปิดขายทั้งโครงการ"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.survey_selltype === "1"}
                    onChange={this.handle_typesales}
                    value="1"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="เปิดขายเฟส"
              />
            </div>
            {this.state.survey_selltype === "1" ? (
              <CustomInput
                success={this.state.survey_selltypeareaState === "success"}
                error={this.state.survey_selltypeareaState === "error"}
                labelText="กรุณาระบุชื่อเฟส"
                id="survey_selltypearea"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: this.state.survey_selltypearea,
                  onChange: (event) =>
                    this.change(event, "survey_selltypearea", "all"),
                  type: "text",
                }}
              />
            ) : null}
            <GridContainer>
              <GridItem xs={12} sm={4} md={4} lg={4}>
                <CustomInput
                  success={this.state.survey_raiState === "success"}
                  error={this.state.survey_raiState === "error"}
                  labelText="ไร่"
                  id="survey_rai"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.survey_rai,
                    onChange: (event) =>
                      this.change(event, "survey_rai", "rai"),
                    type: "number",
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4} lg={4}>
                <CustomInput
                  success={this.state.survey_nganState === "success"}
                  error={this.state.survey_nganState === "error"}
                  labelText="งาน"
                  id="survey_ngan"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.survey_ngan,
                    onChange: (event) =>
                      this.change(event, "survey_ngan", "ngan"),
                    type: "number",
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4} lg={4}>
                <CustomInput
                  success={this.state.survey_sqwaState === "success"}
                  error={this.state.survey_sqwaState === "error"}
                  labelText="ตารางวา"
                  id="survey_sqwa"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.survey_sqwa,
                    onChange: (event) =>
                      this.change(event, "survey_sqwa", "sqwa"),
                    type: "number",
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{
                    marginBottom: "15px",
                    float: "none",
                    paddingTop: "22px",
                    color:
                      this.state.survey_frontimgState === "error"
                        ? "red"
                        : "black",
                  }}
                >
                  รูปด้านหน้าโครงการพร้อมป้ายชื่อ
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div className="fileinput text-center">
                  <input
                    type="file"
                    accept="image/x-png,image/jpeg"
                    onChange={this.handleImageChange}
                    ref={this.fileInput}
                  />
                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "rgba(0,0,0,0)",
                      zIndex: "1000",
                      width: "100%",
                      height: "100%",
                      left: 0,
                      right: 0,
                      cursor: "pointer",
                    }}
                    className={"resize_imageupload thumbnail"}
                  >
                    {this.state.survey_frontimg !== null &&
                    this.state.survey_frontimg !== "" &&
                    this.state.survey_frontimg.length > 0 &&
                    this.state.survey_frontimg[0] !== "" ? (
                      <a
                        className={"resize_imageupload thumbnail"}
                        href={config.requestUrl + this.state.survey_frontimg}
                        // download={
                        //   config.requestUrl + this.state.survey_frontimg
                        // }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          key={"file"}
                          src={this.state.imagePreviewUrl}
                          alt="..."
                        />
                      </a>
                    ) : (
                      <a
                        className={"resize_imageupload thumbnail"}
                        href={config.requestUrl + this.state.survey_frontimg}
                        // download={
                        //   config.requestUrl + this.state.survey_frontimg
                        // }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          key={"file"}
                          src={this.state.imagePreviewUrl}
                          alt="..."
                        />
                      </a>
                    )}
                  </div>
                  <div>
                    {this.state.survey_frontimg === null ||
                    this.state.survey_frontimg === "" ||
                    this.state.survey_frontimg[0] === "" ? (
                      <Button
                        size="sm"
                        color="rose"
                        onClick={() => this.handleClick()}
                      >
                        Select image
                      </Button>
                    ) : (
                      <span>
                        <Button
                          size="sm"
                          color="rose"
                          onClick={() => this.handleClick()}
                        >
                          Change
                        </Button>
                        {null}
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => this.handleRemove()}
                        >
                          <i className="fas fa-times" /> Remove
                        </Button>
                      </span>
                    )}
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

BasicInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  condo_sec1_part1Object: (payload) =>
    dispatch(condo_sec1_part1Object(payload)),
  pressSave: (payload) => dispatch(pressSave(payload)),
  notiToggle: (payload) => dispatch(notiToggle(payload)),
  project_datatable: (payload) => dispatch(project_datatable(payload)),
  imageuploading: (bool) => dispatch(imageuploading(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(regularFormsStyle)(BasicInfo));
