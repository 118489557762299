import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BeatLoader } from "react-spinners";

// @material-ui/icons
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Badge from "components/Badge/Badge.js";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import SweetAlert from "react-bootstrap-sweetalert";

// GH bank function
import switchIntl from "../../configure/switchIntl.js";
// import ReactTable from "views/Tables/ReactTable.js";
import ReactTable from "views/Tables/ReactTables.js";
import AddIcon from "@material-ui/icons/Add";
import Button from "components/CustomButtons/Button.js";
import WizardHouse from "views/PSurvey/wizard_house.js";
import WizardCondo from "views/PSurvey/wizard_condo.js";
import WizardVilla from "views/PSurvey/wizard_villa.js";

import toggleSurvey from "actions/survey/toggleSurvey.js";
import surveyType from "actions/survey/setSurveyType.js";
import project_datatable from "actions/survey/psurvey_editproj.js";
import setProjectid from "actions/survey/setProjectId.js";
import setLatlon from "actions/survey/setLatlon.js";
import setCatType1 from "actions/survey/setCatType1.js";
import setCatType2 from "actions/survey/setCatType2.js";
import setCatType3 from "actions/survey/setCatType3.js";
import setCatType4 from "actions/survey/setCatType4.js";
import setCatType5 from "actions/survey/setCatType5.js";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import priceImage1 from "assets/img/card_house.jpg";
import priceImage2 from "assets/img/card_condo.jpg";
import priceImage3 from "assets/img/card_villa.jpg";
import GHBankLoading from "assets/img/reicLoading.gif";

import * as config from "configure/configServer.js";
import CryptoJS from "crypto-js";

import numeral from "numeral";

class Survey extends React.Component {
  state = {
    loadingScreen: true,
    value: 0,
    exampleVal: "2,324",
    openSurveyMenu: false,
    img_house: require("assets/img/CodeH.png"),
    img_villa: require("assets/img/CodeV.png"),
    img_condo: require("assets/img/CodeC.png"),
    cardloaded: false,
    surveyloaded: "",
    tc: false,
    proj_all: "-",
    proj_save: "-",
    proj_eject: "-",
    proj_pass: "-",
    proj_sent: "-",
    defaultProps: {
      lat: 0,
      lng: 0,
    },
    alert: null,
    member_type: 2,
    member_status: 1,
  };
  static propTypes = {
    toggleLng: PropTypes.string.isRequired,
    isToggle: PropTypes.bool.isRequired,
    toggleSurvey: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.getSecretKey();
  }

  componentWillUnmount() {
    var id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }

  handleClickNotification = () => {
    this.setState({ openSurveyMenu: !this.state.openSurveyMenu });
  };
  handleCloseNotification = () => {
    this.setState({ openSurveyMenu: false });
  };
  openSurvey_direct = (type) => {
    this.props.setCatType1(false);
    this.props.setCatType2(false);
    this.props.setCatType3(false);
    this.props.setCatType4(false);
    this.props.setCatType5(false);
    this.props.surveyType(type);
    this.props.toggleSurvey(true);
    this.props.project_datatable(undefined);
    this.props.setProjectid(undefined);
    this.setState({
      cardloaded: false,
      surveyloaded: "",
    });
  };
  passSurvey_data = (section) => {
    this.setState({
      cardloaded: true,
      surveyloaded: section,
    });
    setTimeout(() => {
      this.openSurvey_direct(section);
    }, 500);
  };
  checkSurveyType = (type) => {
    if (type === "household") {
      return <WizardHouse />;
    } else if (type === "condo") {
      return <WizardCondo />;
    } else if (type === "villa") {
      return <WizardVilla />;
    } else if (type === "e_household") {
      return <WizardHouse />;
    } else if (type === "e_condo") {
      return <WizardCondo />;
    } else if (type === "e_villa") {
      return <WizardVilla />;
    } else {
      return null;
    }
  };
  confirm_closeModal() {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ fontSize: "16px" }}
          showCancel
          confirmBtnText="ยืนยัน"
          cancelBtnText="ยกเลิก"
          title="ปิดแบบสอบถาม?"
          onConfirm={() => this.resetSurvey()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.warning
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.default
          }
          focusCancelBtn
        >
          หากท่านไม่ทำการกดบันทึกข้อมูลก่อนปิดแบบสอบถาม
          ข้อมูลทั้งหมดจะไม่ถูกบันทึกเข้าสู่ระบบ
        </SweetAlert>
      ),
    });
  }
  hideAlert() {
    this.setState({
      alert: null,
    });
  }
  resetSurvey() {
    this.setState({
      alert: null,
    });
    this.props.toggleSurvey(false);
    this.props.surveyType("");
    this.props.project_datatable(undefined);
    this.props.setProjectid(undefined);
    this.props.setLatlon(this.state.defaultProps);
    window.location.replace("/survey");
  }

  getSecretKey = () => {
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("sessionP");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);
          this.getall_Psurveyporject(jsonDec.token);
          this.setState({
            member_type: "member_type" in jsonDec ? jsonDec.member_type : 0,
            member_status:
              "member_status" in jsonDec ? jsonDec.member_status : 0,
          });
        }
      })
      .catch(() => {
        // console.log("error");
      });
  };

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  getall_Psurveyporject(token) {
    // console.log('token')
    // console.log(token)
    var request = new Request(config.API_listall_psurvey, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: token,
      }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson)
        if (responseJson.code === 200) {
          const data = responseJson.data.filter((d) => d.condo_approved !== 5);

          const grouped = this.groupBy(data, (data) => data.condo_approved);

          this.setState({
            proj_all: data.length,
            proj_pass: grouped.get(1) === undefined ? 0 : grouped.get(1).length,
            proj_eject:
              grouped.get(2) === undefined ? 0 : grouped.get(2).length,
            proj_wait: grouped.get(3) === undefined ? 0 : grouped.get(3).length,
            proj_save: grouped.get(4) === undefined ? 0 : grouped.get(4).length,
            loadingScreen: false,
          });
        }
      })
      .catch(() => {
        // console.log("error");
      });
  }

  loadingScreen(isLoading) {
    if (isLoading) {
      return (
        <div
          style={{
            display: "flex",
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.3)",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99999,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 250,
              height: 250,
              backgroundColor: "#fff",
              borderRadius: 10,
            }}
          >
            <img src={GHBankLoading} height="100%" alt="ghlogo" />
          </div>
        </div>
      );
    } else {
      //
    }
  }

  imgUploading(isLoading) {
    if (isLoading) {
      return (
        <div
          style={{
            display: "flex",
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.2)",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99999,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BeatLoader
            sizeUnit={"px"}
            size={15}
            color={"#ffffff"}
            loading={true}
          />
        </div>
      );
    } else {
      //
    }
  }

  render() {
    const { classes, toggleLng } = this.props;
    const { openSurveyMenu } = this.state;

    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="down" ref={ref} {...props} />;
    });

    const badgeStyle = {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    };

    const loadingCard = (
      <div className="card_loading">
        <BeatLoader
          sizeUnit={"px"}
          size={15}
          color={"#ffffff"}
          loading={true}
        />
      </div>
    );

    let proj_all = this.state.proj_all;
    let proj_pass = this.state.proj_pass;
    let proj_save = this.state.proj_save;
    let proj_sent = this.state.proj_wait + this.state.proj_eject;
    let proj_eject = this.state.proj_eject;

    return (
      <div style={{ marginTop: "-2%" }}>
        {this.state.alert}
        {this.props.survey_modal}
        {this.props.noti_toggle}
        {this.loadingScreen(this.state.loadingScreen)}
        {this.loadingScreen(this.props.loading_tabledata)}
        {this.imgUploading(this.props.imageUploading)}
        {switchIntl(toggleLng).pSurvey.table_header}
        <Button
          color="info"
          size="sm"
          buttonRef={(node) => {
            this.anchorEl = node;
          }}
          onClick={this.handleClickNotification}
          style={{ marginLeft: "20px", fontSize: "16px" }}
          disabled={
            this.state.member_type !== 2 || this.state.member_status !== 1
          }
        >
          <AddIcon />
          เพิ่มโครงการ
        </Button>
        <Popper
          open={openSurveyMenu}
          anchorEl={this.anchorEl}
          transition
          placement="bottom-start"
          className="popper"
          style={{ zIndex: 999 }}
          modifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: false,
              boundariesElement: "viewport",
              escapeWithReference: false,
            },
            hide: {
              enabled: false,
            },
            arrow: {
              enabled: false,
              element: "arrowRef",
            },
          }}
        >
          <ClickAwayListener onClickAway={this.handleClickNotification}>
            <Paper className={classes.selectMenu}>
              <Card>
                <CardHeader>
                  <GridContainer
                    alignItems="center"
                    style={{ paddingLeft: "10px" }}
                  >
                    <h3 className={classes.cardTitle}>เพิ่มโครงการ</h3>
                    <Button onClick={this.handleCloseNotification} simple>
                      <Close
                        className={classes.icons}
                        style={{ color: "gray" }}
                      />
                    </Button>
                  </GridContainer>
                </CardHeader>
                <CardBody>
                  <h5 className={classes.cardTitle}>ประเภทโครงการ</h5>
                  <GridContainer alignItems="center">
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <Card style={{ maxWidth: "200px" }}>
                        {this.state.cardloaded &&
                        this.state.surveyloaded === "household"
                          ? loadingCard
                          : null}
                        <a
                          href="#/"
                          onClick={() =>
                            this.props.isToggle
                              ? null
                              : this.passSurvey_data("household")
                          }
                        >
                          <CardBody className="card_imageBody">
                            <img
                              src={priceImage1}
                              alt="..."
                              className="card_imagePic"
                            />
                          </CardBody>
                          <CardFooter stats className="card_imageFooter">
                            <div
                              className={classes.stats}
                              style={{ fontSize: "12px" }}
                            >
                              Code: H
                            </div>
                            <div
                              className={classes.stats}
                              style={{ fontSize: "14px" }}
                            >
                              บ้านจัดสรร
                            </div>
                          </CardFooter>
                        </a>
                      </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <Card style={{ maxWidth: "200px" }}>
                        {this.state.cardloaded &&
                        this.state.surveyloaded === "condo"
                          ? loadingCard
                          : null}
                        <a
                          href="#/"
                          onClick={() =>
                            this.props.isToggle
                              ? null
                              : this.passSurvey_data("condo")
                          }
                        >
                          <CardBody className="card_imageBody">
                            <img
                              src={priceImage2}
                              alt="..."
                              className="card_imagePic"
                            />
                          </CardBody>
                          <CardFooter stats className="card_imageFooter">
                            <div
                              className={classes.stats}
                              style={{ fontSize: "12px" }}
                            >
                              Code: C
                            </div>
                            <div
                              className={classes.stats}
                              style={{ fontSize: "14px" }}
                            >
                              อาคารชุดพักอาศัย
                            </div>
                          </CardFooter>
                        </a>
                      </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <Card style={{ maxWidth: "200px" }}>
                        {this.state.cardloaded &&
                        this.state.surveyloaded === "villa"
                          ? loadingCard
                          : null}
                        <a
                          href="#/"
                          onClick={() =>
                            this.props.isToggle
                              ? null
                              : this.passSurvey_data("villa")
                          }
                        >
                          <CardBody className="card_imageBody">
                            <img
                              src={priceImage3}
                              alt="..."
                              className="card_imagePic"
                            />
                          </CardBody>
                          <CardFooter stats className="card_imageFooter">
                            <div
                              className={classes.stats}
                              style={{ fontSize: "12px" }}
                            >
                              Code: V
                            </div>
                            <div
                              className={classes.stats}
                              style={{ fontSize: "14px" }}
                            >
                              บ้านพักตากอากาศ
                            </div>
                          </CardFooter>
                        </a>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </Paper>
          </ClickAwayListener>
        </Popper>
        {this.props.toggleSurvey ? (
          <Dialog
            fullScreen
            open={this.props.isToggle}
            transition={Transition}
            style={{ width: "100%" }}
            keepMounted
            onClose={() => this.props.toggleSurvey(false)}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogContent
              style={{ backgroundColor: "#eeeeee" }}
              id="modal-slide-description"
              className={classes.modalBody}
            >
              <Button
                onClick={() => this.confirm_closeModal()}
                justIcon
                round
                color="white"
                style={{
                  position: "absolute",
                  right: "50px",
                  zIndex: "100000",
                }}
              >
                <Close className={classes.icons} />
              </Button>
              {this.checkSurveyType(this.props.surveytype)}
            </DialogContent>
          </Dialog>
        ) : null}
        <CustomTabs
          classesname="change_tabColor"
          headerColor="info"
          tabs={[
            {
              tabName: (function() {
                return (
                  <div style={badgeStyle}>
                    <h4 style={{ marginRight: "10px" }}>
                      {switchIntl(toggleLng).project.table_nav1}
                    </h4>
                    <Badge color="success">
                      {numeral(proj_all).format("0,0")}
                    </Badge>
                  </div>
                );
              })(),
              tabContent: <ReactTable type="all" />,
            },
            {
              tabName: (function() {
                return (
                  <div style={badgeStyle}>
                    <h4 style={{ marginRight: "10px" }}>
                      {switchIntl(toggleLng).project.table_nav2}
                    </h4>
                    <Badge color="success">
                      {numeral(proj_save).format("0,0")}
                    </Badge>
                  </div>
                );
              })(),
              tabContent: <ReactTable type="saved" />,
            },
            {
              tabName: (function() {
                return (
                  <div style={badgeStyle}>
                    <h4 style={{ marginRight: "10px" }}>
                      {switchIntl(toggleLng).project.table_nav3}
                    </h4>
                    <Badge color="success">
                      {numeral(proj_pass).format("0,0")}
                    </Badge>
                  </div>
                );
              })(),
              tabContent: <ReactTable type="approved" />,
            },
            {
              tabName: (function() {
                return (
                  <div style={badgeStyle}>
                    <h4 style={{ marginRight: "10px" }}>
                      {switchIntl(toggleLng).project.table_nav4}
                    </h4>
                    <Badge color="success">
                      {numeral(proj_sent).format("0,0")}
                    </Badge>
                  </div>
                );
              })(),
              tabContent: <ReactTable type="sent" />,
            },
            {
              tabName: (function() {
                return (
                  <div style={badgeStyle}>
                    <h4 style={{ marginRight: "10px" }}>
                      {switchIntl(toggleLng).project.table_nav5}
                    </h4>
                    <Badge color="success">
                      {numeral(proj_eject).format("0,0")}
                    </Badge>
                  </div>
                );
              })(),
              tabContent: <ReactTable type="eject" />,
            },
          ]}
        />
      </div>
    );
  }
}

Survey.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setCatType1: (payload) => dispatch(setCatType1(payload)),
  setCatType2: (payload) => dispatch(setCatType2(payload)),
  setCatType3: (payload) => dispatch(setCatType3(payload)),
  setCatType4: (payload) => dispatch(setCatType4(payload)),
  setCatType5: (payload) => dispatch(setCatType5(payload)),
  toggleSurvey: (payload) => dispatch(toggleSurvey(payload)),
  surveyType: (payload) => dispatch(surveyType(payload)),
  project_datatable: (payload) => dispatch(project_datatable(payload)),
  setProjectid: (payload) => dispatch(setProjectid(payload)),
  setLatlon: (payload) => dispatch(setLatlon(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle)(Survey));
