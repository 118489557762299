import React from "react";
import PropTypes from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import * as config from "configure/configServer.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import CryptoJS from "crypto-js";
import setProjectid from "actions/survey/setProjectId.js";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
//redux
import { connect } from "react-redux";
import setLanguage from "actions/setLanguage";
import toggleSurvey from "actions/survey/toggleSurvey.js";
import surveyType from "actions/survey/setSurveyType.js";
import project_datatable from "actions/survey/psurvey_editproj.js";
import loading_datatable from "actions/survey/loading_datatable.js";
import notiToggle from "actions/survey/notificationToggle.js";
import setCatType1 from "actions/survey/setCatType1.js";
import setCatType2 from "actions/survey/setCatType2.js";
import setCatType3 from "actions/survey/setCatType3.js";
import setCatType4 from "actions/survey/setCatType4.js";
import setCatType5 from "actions/survey/setCatType5.js";

// switch language function
import switchIntl from "configure/switchIntl.js";
//import setLatlon from "actions/survey/setLatlon.js";

import numeral from "numeral";

import { API_listall_psurvey } from "configure/configServer.js";

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      data: [],
      alert: null,
      token: "",
      memberStatus: this.props.memberStatus ? this.props.memberStatus : 1,
      member_type: 2,
      view_psurvey: false,
    };
    this.formatData = this.formatData.bind(this);
    this.formatApproval = this.formatApproval.bind(this);
    // console.log(this.props.memberStatus)
    // console.log(this.state.memberStatus)
  }

  componentDidUpdate(previousProps) {
    if (previousProps !== this.props.memberStatus) {
      // console.log(this.props.memberStatus);
    }
  }
  componentDidMount() {
    this.addFilterPlaceholder();
    this.getSecretKey();
  }
  getSecretKey = () => {
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);
          var aesStr = localStorage.getItem("sessionP");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);
          this.setState({
            user_id: jsonDec.member_id,
            token: jsonDec.token,
            member_type: jsonDec.member_type,
            key,
          });
          if ("member_status" in responseJson) {
            this.setState({
              memberStatus: responseJson.member_status,
            });
          }
          this.loadProject();
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  successAlert(title, message, status) {
    this.setState({
      alert: (
        <SweetAlert
          {...status}
          className="SweetAlert"
          title={<div>{title}</div>}
          onConfirm={() => this.setState({ alert: null })}
        >
          {message.length > 200 ? (
            <div style={{ overflowY: "scroll", height: "100px" }}>
              <br />
              {message}
            </div>
          ) : (
            message
          )}
        </SweetAlert>
      ),
    });
  }
  loadProject = () => {
    // console.log('token')
    // console.log(self.state.token)
    var param = {
      srp_name: 0,
    };
    let self = this;
    var request = new Request(API_listall_psurvey, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: self.state.token,
      }),
      body: JSON.stringify(param),
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          // console.log(data);
          switch (data.code) {
            case 200:
              // success
              // console.log(data)
              self.formatData(data.data.filter((d) => d.condo_approved !== 5));
              break;
            default:
              // failed
              break;
          }
        });
      })
      .catch(function() {});
    return false;
  };

  viewSurvey(type, id, status) {
    this.props.loading_datatable(true);
    this.props.project_datatable(undefined);
    let body = {};
    let api_call = "";
    switch (type) {
      case "household":
        body = {
          household_projectid: id,
          household_approved: status,
        };
        api_call = config.API_showdetail_projectid_household;
        break;
      case "villa":
        body = {
          villa_projectid: id,
          villa_approved: status,
        };
        api_call = config.API_showdetail_projectid_villa;
        break;
      case "condo":
        body = {
          condo_projectid: id,
          condo_approved: status,
        };
        api_call = config.API_showdetail_projectid_condo;
        break;
      default:
        break;
    }

    let dataStatus = {
      status: status,
    };
    // console.log(body)
    var request = new Request(api_call, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: this.state.token,
      }),
      body: JSON.stringify(body),
    });
    // console.log(body);
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        const body = {
          ...responseJson,
          ...dataStatus,
          view_psurvey: true,
        };

        // console.log('fetch data with this obj');
        // console.log(responseJson);
        // console.log(JSON.stringify(responseJson));
        switch (responseJson.code) {
          case 200:
            setTimeout(() => {
              // console.log(body);
              this.props.setCatType1(false);
              this.props.setCatType2(false);
              this.props.setCatType3(false);
              this.props.setCatType4(false);
              this.props.setCatType5(false);
              this.props.project_datatable(body);
              this.props.surveyType(type);
              this.props.toggleSurvey(true);
              this.props.loading_datatable(false);
              this.props.setProjectid(id);
            }, 1500);
            break;
          default:
            this.props.loading_datatable(false);
            this.showNotification(
              "danger",
              "ไม่สามารถเปิดดูข้อมูลแบบสอบถามได้ในขณะนี้ กรุณาลองใหม่ภายหลัง"
            );
            break;
        }
      })
      .catch((error) => {
        // console.log(error);
        // eslint-disable-next-line react/prop-types
        this.props.loading_datatable(false);
        this.showNotification(
          "danger",
          "ไม่สามารถเปิดดูข้อมูลแบบสอบถามได้ในขณะนี้ กรุณาลองใหม่ภายหลัง"
        );
      });
  }
  openSurvey(type, id, status) {
    this.props.loading_datatable(true);
    this.props.project_datatable(undefined);
    let body = {};
    let api_call = "";
    switch (type) {
      case "household":
        body = {
          household_projectid: id,
          household_approved: status,
        };
        api_call = config.API_showdetail_projectid_household;
        break;
      case "villa":
        body = {
          villa_projectid: id,
          villa_approved: status,
        };
        api_call = config.API_showdetail_projectid_villa;
        break;
      case "condo":
        body = {
          condo_projectid: id,
          condo_approved: status,
        };
        api_call = config.API_showdetail_projectid_condo;
        break;
      default:
        break;
    }

    let dataStatus = {
      status: status,
    };
    // console.log(body)
    var request = new Request(api_call, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: this.state.token,
      }),
      body: JSON.stringify(body),
    });
    // console.log(body);
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        const body = {
          ...responseJson,
          ...dataStatus,
          view_psurvey: false,
        };

        // console.log('fetch data with this obj');
        // console.log(responseJson);
        // console.log(JSON.stringify(responseJson));
        switch (responseJson.code) {
          case 200:
            setTimeout(() => {
              // console.log(body);
              this.props.setCatType1(false);
              this.props.setCatType2(false);
              this.props.setCatType3(false);
              this.props.setCatType4(false);
              this.props.setCatType5(false);
              this.props.project_datatable(body);
              this.props.surveyType(type);
              this.props.toggleSurvey(true);
              this.props.loading_datatable(false);
              this.props.setProjectid(id);
            }, 1500);
            break;
          default:
            this.props.loading_datatable(false);
            this.showNotification(
              "danger",
              "ไม่สามารถเปิดดูข้อมูลแบบสอบถามได้ในขณะนี้ กรุณาลองใหม่ภายหลัง"
            );
            break;
        }
      })
      .catch((error) => {
        // console.log(error);
        // eslint-disable-next-line react/prop-types
        this.props.loading_datatable(false);
        this.showNotification(
          "danger",
          "ไม่สามารถเปิดดูข้อมูลแบบสอบถามได้ในขณะนี้ กรุณาลองใหม่ภายหลัง"
        );
      });
  }
  showNotification(status, message) {
    this.setState({
      noti: true,
    });
    this.props.notiToggle(
      <Snackbar
        place="tc"
        color={status}
        icon={AddAlert}
        message={message}
        open={this.state.noti}
      />
    );
    setTimeout(() => {
      this.setState({
        noti: false,
      });
      this.props.notiToggle(
        <Snackbar
          place="tc"
          color={status}
          icon={AddAlert}
          message={message}
          open={this.state.noti}
        />
      );
    }, 3000);
  }
  formatData(rawdata) {
    let self = this;
    // eslint-disable-next-line react/prop-types
    let type = this.props.type;
    let arr = [];
    for (var i = 0; i < rawdata.length; i++) {
      let data = rawdata[i];
      let condo_approved = data.condo_approved;
      if (type === "all") {
        arr.push([
          data.projectid,
          data.condo_nameth,
          data.project_type,
          data.zone,
          data.province_t,
          data.amphoe_t,
          data.tambon_t,
          data.cstats_name,
          data.condo_approved,
          data.refcode,
        ]);
      } else if (type === "saved") {
        // บันทึกชั่วคราว
        if (condo_approved === 4) {
          arr.push([
            data.projectid,
            data.condo_nameth,
            data.project_type,
            data.zone,
            data.province_t,
            data.amphoe_t,
            data.tambon_t,
            data.cstats_name,
            data.condo_approved,
            data.refcode,
          ]);
        }
      } else if (type === "approved") {
        // ผ่าน
        if (condo_approved === 1) {
          arr.push([
            data.projectid,
            data.condo_nameth,
            data.project_type,
            data.zone,
            data.province_t,
            data.amphoe_t,
            data.tambon_t,
            data.cstats_name,
            data.condo_approved,
            data.refcode,
          ]);
        }
      } else if (type === "sent") {
        if (condo_approved === 2 || condo_approved === 3) {
          // ไม่ผ่าน + รอตรวจ
          arr.push([
            data.projectid,
            data.condo_nameth,
            data.project_type,
            data.zone,
            data.province_t,
            data.amphoe_t,
            data.tambon_t,
            data.cstats_name,
            data.condo_approved,
            data.refcode,
          ]);
        }
      } else {
        if (condo_approved === 2) {
          // ไม่ผ่าน + รอตรวจ
          arr.push([
            data.projectid,
            data.condo_nameth,
            data.project_type,
            data.zone,
            data.province_t,
            data.amphoe_t,
            data.tambon_t,
            data.cstats_name,
            data.condo_approved,
            data.refcode,
          ]);
        }
      }
    }
    const dataTable = {
      headerRow: [
        "no",
        "projectid",
        "projectname",
        "project_type",
        "zone",
        "prov",
        "amphoe",
        "tambon",
        "cstats_name",
        "approval",
        "refcode",
      ],
      dataRows: arr,
    };
    let data = dataTable.dataRows.map((prop, key) => {
      let no = numeral(key + 1).format("0,0");
      return {
        no: no,
        id: key,
        projectid: prop[0],
        projectname: prop[1],
        project_type: prop[2],
        zone: prop[3],
        prov: prop[4],
        amphoe: prop[5],
        tambon: prop[6],
        cstats_name: prop[7],
        approval: self.formatApproval(prop[8]),
        approval_id: prop[8],
        refcode: prop[9],
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a like kind of action */}
            <Button
              style={{
                display: this.state.member_type === 2 ? "none" : "",
              }}
              justIcon
              round
              simple
              onClick={() => {
                // console.log(this.state.member_type);
                let obj = this.state.data.find((o) => o.id === key);
                this.viewSurvey(
                  obj.project_type,
                  obj.projectid,
                  obj.approval_id
                );
              }}
              color="info"
              className="view"
            >
              <Assignment />
            </Button>{" "}
            <Button
              style={{
                display: this.state.member_type === 1 ? "none" : "",
              }}
              justIcon
              round
              simple
              onClick={() => {
                let obj = this.state.data.find((o) => o.id === key);
                this.openSurvey(
                  obj.project_type,
                  obj.projectid,
                  obj.approval_id
                );
              }}
              color="warning"
              className="edit"
            >
              <Edit />
            </Button>{" "}
            {/* use this button to add a edit kind of action */}
            <Button
              style={{
                display: this.state.member_type === 1 ? "none" : "",
              }}
              justIcon
              round
              simple
              onClick={() => {
                let obj = this.state.data.find((o) => o.id === key);
                if (this.props.memberStatus !== 2) {
                  this.setState({
                    alert: (
                      <SweetAlert
                        input
                        style={{ display: "block", marginTop: "-100px" }}
                        title={
                          switchIntl(this.props.toggleLng).project
                            .home_project_list.title_name
                        }
                        placeHolder={
                          switchIntl(this.props.toggleLng).project
                            .home_project_list.msg2
                        }
                        inputType="password"
                        validationMsg={
                          switchIntl(this.props.toggleLng).project
                            .home_project_list.msg2
                        }
                        onConfirm={(inputValue) =>
                          this.deleteProject(
                            obj.projectid,
                            obj.refcode,
                            obj.project_type,
                            inputValue
                          )
                        }
                        onCancel={() => this.hideAlert()}
                        confirmBtnText={
                          switchIntl(this.props.toggleLng).basic_word
                            .delete_project
                        }
                        cancelBtnText={
                          switchIntl(this.props.toggleLng).basic_word.cancel
                        }
                        confirmBtnCssClass={
                          this.props.classes.button +
                          " " +
                          this.props.classes.danger
                        }
                        cancelBtnCssClass={
                          this.props.classes.button +
                          " " +
                          this.props.classes.default
                        }
                        showCancel
                      >
                        {" "}
                        {
                          switchIntl(this.props.toggleLng).project
                            .home_project_list.msg2
                        }
                      </SweetAlert>
                    ),
                  });
                } else {
                  this.setState({
                    alert: (
                      <SweetAlert
                        style={{ display: "block", marginTop: "-100px" }}
                        title={
                          this.props.toggleLng === "TH"
                            ? "คุณมีสิทธิ์เป็น Read only"
                            : "You are only a Read Only user"
                        }
                        onConfirm={() => this.hideAlert()}
                        confirmBtnText={
                          switchIntl(this.props.toggleLng).basic_word.cancel
                        }
                        confirmBtnCssClass={
                          this.props.classes.button +
                          " " +
                          this.props.classes.default
                        }
                      ></SweetAlert>
                    ),
                  });
                }
              }}
              color={this.colorStatus(prop[8])}
              disabled={this.isDisabled(prop[8])}
              className="delete"
            >
              <Delete />
            </Button>{" "}
          </div>
        ),
      };
    });
    this.setState({
      data: data,
    });
  }
  formatApproval(data) {
    switch (data) {
      case 1:
        return <p style={{ color: "#008125", fontWeight: 600 }}>ผ่าน</p>;
      case 2:
        return <p style={{ color: "red", fontWeight: 600 }}>ไม่ผ่าน</p>;
      case 3:
        return <p style={{ color: "#FFBD00", fontWeight: 600 }}>รอตรวจ</p>;
      case 4:
        return "บันทึกชั่วคราว";
      case 5:
        return <p style={{ color: "#FFBD00", fontWeight: 600 }}>โครงการเก่า</p>;
      default:
        return data;
    }
  }
  hideAlert() {
    this.setState({
      alert: null,
    });
  }
  deleteProject(projectID, refcode, project_type, inputValue) {
    let that = this;
    var param = {
      projectid: projectID,
      refcode: refcode,
      form: project_type,
      member_passwd: inputValue,
      event: "Delete project",
    };
    var request = new Request(config.API_delpsurvey, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: that.state.token,
      }),
      body: JSON.stringify(param),
    });
    fetch(request)
      .then(function(response) {
        response.json().then(function(data) {
          if (data.code === 200) {
            that.loadProject();
            that.setState({
              alert: (
                <SweetAlert
                  success="true"
                  style={{ display: "block", marginTop: "-100px" }}
                  title="ลบโครงการสำเร็จ"
                  onConfirm={() => that.hideAlert()}
                  confirmBtnCssClass={
                    that.props.classes.button + " " + that.props.classes.success
                  }
                  cancelBtnCssClass={
                    that.props.classes.button + " " + that.props.classes.danger
                  }
                  confirmBtnText={
                    switchIntl(that.props.toggleLng).basic_word.ok
                  }
                ></SweetAlert>
              ),
            });
          } else if (data.code === 402) {
            that.setState({
              alert: (
                <SweetAlert
                  warning
                  style={{ display: "block", marginTop: "-100px" }}
                  title="รหัสไม่ถูกต้อง"
                  onConfirm={() => that.hideAlert()}
                  confirmBtnCssClass={
                    that.props.classes.button + " " + that.props.classes.success
                  }
                  cancelBtnCssClass={
                    that.props.classes.button + " " + that.props.classes.danger
                  }
                  confirmBtnText={
                    switchIntl(that.props.toggleLng).basic_word.ok
                  }
                ></SweetAlert>
              ),
            });
          }
        });
      })
      .catch(function(err) {
        // console.log(err);
      });
  }
  colorStatus(data) {
    let status = parseInt(data);
    if (status === 4) {
      return "danger";
    } else {
      return "white";
    }
  }
  colorStatusEdit(data) {
    let status = parseInt(data);
    if (status === 1 || status === 3) {
      return "white";
    } else {
      return "info";
    }
  }
  isDisabled(data) {
    let status = parseInt(data);
    if (status === 4) {
      return false;
    } else {
      return true;
    }
  }
  addFilterPlaceholder = () => {
    const filters = document.querySelectorAll("div.rt-th > input");
    for (let filter of filters) {
      filter.placeholder = "ค้นหาที่นี่..";
    }
  };
  render() {
    if (this.state.search) {
      this.state.data.filter((row) => {
        return (
          row.firstName.includes(this.state.search) ||
          row.lastName.includes(this.state.search) ||
          String(row.age).includes(this.state.search)
        );
      });
    }
    return (
      <GridContainer style={{ marginTop: "-3%" }}>
        {this.state.alert}
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <ReactTable
                data={this.state.data}
                filterable
                columns={[
                  {
                    Header: switchIntl(this.props.toggleLng).project.table.no,
                    accessor: "no",
                    sortable: true,
                    filterable: false,
                  },
                  {
                    Header: switchIntl(this.props.toggleLng).project.table
                      .projectid,
                    accessor: "refcode",
                    filterMethod: (filter, row) => {
                      return row.refcode.indexOf(filter.value) >= 0;
                    },
                  },

                  {
                    Header: switchIntl(this.props.toggleLng).project.table
                      .projectname,
                    accessor: "projectname",
                    filterMethod: (filter, row) => {
                      return row.projectname.indexOf(filter.value) >= 0;
                    },
                  },
                  {
                    Header: switchIntl(this.props.toggleLng).project.table
                      .projecttype,
                    accessor: "project_type",
                  },
                  {
                    Header: switchIntl(this.props.toggleLng).project.table.zone,
                    accessor: "zone",
                  },
                  {
                    Header: switchIntl(this.props.toggleLng).project.table.prov,
                    accessor: "prov",
                  },
                  {
                    Header: switchIntl(this.props.toggleLng).project.table
                      .amphoe,
                    accessor: "amphoe",
                  },
                  {
                    Header: switchIntl(this.props.toggleLng).project.table
                      .tambon,
                    accessor: "tambon",
                  },
                  {
                    Header: switchIntl(this.props.toggleLng).project.table
                      .status,
                    accessor: "cstats_name",
                    width: 150,
                  },
                  {
                    Header: switchIntl(this.props.toggleLng).project.table
                      .approval,
                    accessor: "approval",
                  },
                  {
                    Header: switchIntl(this.props.toggleLng).project.table
                      .setting,
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    width: 150,
                  },
                ]}
                defaultPageSize={5}
                showPaginationTop
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
ReactTables.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  setCatType1: (payload) => dispatch(setCatType1(payload)),
  setCatType2: (payload) => dispatch(setCatType2(payload)),
  setCatType3: (payload) => dispatch(setCatType3(payload)),
  setCatType4: (payload) => dispatch(setCatType4(payload)),
  setCatType5: (payload) => dispatch(setCatType5(payload)),
  setLanguage: (payload) => dispatch(setLanguage(payload)),
  toggleSurvey: (payload) => dispatch(toggleSurvey(payload)),
  surveyType: (payload) => dispatch(surveyType(payload)),
  project_datatable: (payload) => dispatch(project_datatable(payload)),
  loading_datatable: (payload) => dispatch(loading_datatable(payload)),
  notiToggle: (payload) => dispatch(notiToggle(payload)),
  setProjectid: (payload) => dispatch(setProjectid(payload)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(sweetAlertStyle)(ReactTables));
