/* eslint-disable no-console */
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import CryptoJS from "crypto-js";
import { connect } from "react-redux";
import io from "socket.io-client";
import * as config from "configure/configServer.js";

import setLoading from "actions/setLoading.js";
import setLanguage from "actions/setLanguage";
import adminModal from "actions/adminModal";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";

import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
// import Dashboard from "@material-ui/icons/Dashboard";
// import Search from "@material-ui/icons/Search";

// switch language function
import switchIntl from "configure/switchIntl.js";

// core components
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import adminNavbarLinksStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

class HeaderLinks extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    setLoading: PropTypes.object.isRequired,
    setLanguage: PropTypes.string.isRequired,
    adminModal: PropTypes.object.isRequired,
  };
  state = {
    openNotification: false,
    openProfile: false,
    isLoading: false,
    countNoti: [],
    member_id: "",
  };
  static propTypes = {
    toggleLng: PropTypes.string.isRequired,
  };
  countNoti(data) {
    let noti_array = this.state.countNoti;
    noti_array.push(data[0]);
    this.setState({
      countNoti: noti_array,
    });
  }
  componentDidMount() {
    this.props.setLoading(false);

    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("sessionP");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);

          this.setState({
            member_id: jsonDec.member_id,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });

    setTimeout(() => {
      const socket = io.connect(config.requestUrl, {
        transports: ["websocket", "polling"],
        forceNew: true,
      });

      socket.on("connect", () => {
        const that = this;
        const memID = this.state.member_id;

        socket.on("noti:" + memID, function(data) {
          that.countNoti(data);
        });
      });
    }, 1000);
  }
  handleClickNotification = () => {
    this.setState({ openNotification: !this.state.openNotification });
  };
  handleCloseNotification = () => {
    this.setState({ openNotification: false });
  };
  handleClickProfile = () => {
    this.setState({ openProfile: !this.state.openProfile });
  };
  handleCloseProfile = () => {
    this.setState({ openProfile: false });
  };

  handleLogout = () => {
    this.props.adminModal(
      <SweetAlert
        type="warning"
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        customClass="modalContent"
        title={switchIntl(this.props.toggleLng).sweetalert.onLogout.title}
        onConfirm={() => this.confirmLogout()}
        onCancel={() => this.props.adminModal(null)}
        confirmBtnCssClass="modalBt"
        cancelBtnCssClass="modalBt modalBtCancel"
        confirmBtnText={
          switchIntl(this.props.toggleLng).sweetalert.onLogout.conBtTxt
        }
        cancelBtnText={
          switchIntl(this.props.toggleLng).sweetalert.onLogout.canBtTxt
        }
        showCancel={true}
      >
        {switchIntl(this.props.toggleLng).sweetalert.onLogout.content}
      </SweetAlert>
    );
  };

  confirmLogout() {
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("sessionP");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);

          var tokenData = jsonDec.token;
          setTimeout(() => {
            var request = new Request(config.API_logout, {
              method: "POST",
              headers: new Headers({
                "Content-Type": "application/json",
                token: tokenData,
              }),
            });

            fetch(request)
              .then((response) => response.json())
              .then((responseJson) => {
                if (responseJson.code === 200) {
                  this.props.adminModal(
                    <SweetAlert
                      type="success"
                      style={{
                        display: "block",
                        color: "black",
                        fontSize: "18px",
                      }}
                      onConfirm={() => undefined}
                      customClass="modalContent"
                      title={
                        switchIntl(this.props.toggleLng).sweetalert
                          .confirmLogout.title
                      }
                      showCancel={false}
                      showConfirm={false}
                    >
                      {
                        switchIntl(this.props.toggleLng).sweetalert
                          .confirmLogout.content
                      }
                    </SweetAlert>
                  );
                  setTimeout(() => {
                    this.props.adminModal(null);
                  }, 1000);
                  setTimeout(() => {
                    this.props.setLoading(true);
                  }, 1000);
                  localStorage.removeItem("sessionP");
                  setTimeout(() => {
                    window.location.replace("/auth/");
                  }, 2000);
                }
              })
              .catch((error) => {
                // console.log(error);
              });
          }, 500);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  handleProfile = () => {
    this.props.history.push("/user-page");
  };

  render() {
    const { classes, rtlActive } = this.props;
    const { openNotification, openProfile, countNoti } = this.state;
    // const searchButton =
    //   classes.top +
    //   " " +
    //   classes.searchButton +
    //   " " +
    //   classNames({
    //     [classes.searchRTL]: rtlActive
    //   });
    const dropdownItem = classNames(
      classes.dropdownItem,
      classes.primaryHover,
      { [classes.dropdownItemRTL]: rtlActive }
    );
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive,
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true,
    });
    let optionItems = this.state.countNoti.map((com, index) => (
      <MenuItem
        classes={{ root: classes.selectMenuItem }}
        key={index}
        value={com.projectid}
        style={{
          color: com.approved === 1 ? "green" : "red",
          backgroundColor: "#fff",
        }}
      >
        {com.approved === 1
          ? "โครงการ " + com.projectid + " ผ่านการอนุมัติแล้ว"
          : "โครงการ " + com.projectid + " ไม่ผ่านการอนุมัติ"}
      </MenuItem>
    ));
    return (
      <div className={wrapper}>
        {/* <CustomInput
          rtlActive={rtlActive}
          formControlProps={{
            className: classes.top + " " + classes.search
          }}
          inputProps={{
            placeholder: rtlActive ? "بحث" : "Search",
            inputProps: {
              "aria-label": rtlActive ? "بحث" : "Search",
              className: classes.searchInput
            }
          }}
        />
        <Button
          color="white"
          aria-label="edit"
          justIcon
          round
          className={searchButton}
        >
          <Search
            className={classes.headerLinksSvg + " " + classes.searchIcon}
          />
        </Button> */}
        {/* <Button
          color="transparent"
          simple
          aria-label="Dashboard"
          justIcon
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
        >
          <Dashboard
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>
              {rtlActive ? "لوحة القيادة" : "Dashboard"}
            </span>
          </Hidden>
        </Button> */}
        <div className={managerClasses}>
          <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={openNotification ? "notification-menu-list" : null}
            aria-haspopup="true"
            onClick={() => this.props.setLanguage("TH")}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : "",
            }}
            buttonRef={(node) => {
              this.anchorNotification = node;
            }}
          >
            <span
              style={{
                fontSize: "14px",
                fontWeight: this.props.toggleLng === "TH" ? "600" : "normal",
              }}
            >
              ไทย
            </span>
          </Button>
        </div>
        <div className={managerClasses}>
          <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={openNotification ? "notification-menu-list" : null}
            aria-haspopup="true"
            onClick={() => this.props.setLanguage("EN")}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : "",
            }}
            buttonRef={(node) => {
              this.anchorNotification = node;
            }}
          >
            <span
              style={{
                fontSize: "14px",
                fontWeight: this.props.toggleLng === "EN" ? "600" : "normal",
              }}
            >
              ENG
            </span>
          </Button>
        </div>
        <div className={managerClasses}>
          <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={openNotification ? "notification-menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleClickNotification}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : "",
            }}
            buttonRef={(node) => {
              this.anchorNotification = node;
            }}
          >
            <Notifications
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
            <span className={classes.notifications}>{countNoti.length}</span>
            <Hidden mdUp implementation="css">
              <span
                onClick={this.handleClickNotification}
                className={classes.linkText}
              >
                {switchIntl(this.props.toggleLng).basic_word.notification}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={openNotification}
            anchorEl={this.anchorNotification}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openNotification,
              [classes.popperResponsive]: true,
              [classes.popperNav]: true,
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="notification-menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleCloseNotification}>
                    <MenuList role="menu">{optionItems}</MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>

        <div className={managerClasses}>
          <Button
            color="transparent"
            aria-label="Person"
            justIcon
            aria-owns={openNotification ? "profile-menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleClickProfile}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : "",
            }}
            buttonRef={(node) => {
              this.anchorProfile = node;
            }}
          >
            <Person
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
            <Hidden mdUp implementation="css">
              <span
                onClick={this.handleClickProfile}
                className={classes.linkText}
              >
                {switchIntl(this.props.toggleLng).basic_word.profile}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={openProfile}
            anchorEl={this.anchorProfile}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openProfile,
              [classes.popperResponsive]: true,
              [classes.popperNav]: true,
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleCloseProfile}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={this.handleProfile}
                        className={dropdownItem}
                      >
                        <div style={{ color: "black" }}>
                          {switchIntl(this.props.toggleLng).basic_word.profile}
                        </div>
                      </MenuItem>
                      <Divider light />
                      <MenuItem
                        onClick={this.handleLogout}
                        className={dropdownItem}
                      >
                        <div style={{ color: "black" }}>
                          {switchIntl(this.props.toggleLng).basic_word.logout}
                        </div>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (bool) => dispatch(setLoading(bool)),
  setLanguage: (payload) => dispatch(setLanguage(payload)),
  adminModal: (islogout) => dispatch(adminModal(islogout)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(adminNavbarLinksStyle)(HeaderLinks)));
