export default (state, action) => {
  switch (action.type) {
    case "bgChange":
      return {
        ...state,
        toggleLng: action.payload,
      };
    case "loading":
      return {
        ...state,
        loadingSet: action.bool,
      };
    case "memberStatus":
      return {
        ...state,
        memberStatus: action.payload,
      };
    case "profileImg":
      return {
        ...state,
        getBase64: action.base64,
      };
    case "logoutmodal":
      return {
        ...state,
        logoutModal: action.islogout,
      };
    case "hometypeChange":
      return {
        ...state,
        hometype: action.payload,
      };
    case "addHouseLatLonChange":
      return {
        ...state,
        latlon: action.payload,
      };
    case "setProv":
      return {
        ...state,
        prov_amphoe_tambon: action.payload,
      };
    case "minmaxhouseChange":
      return {
        ...state,
        minmax_house: action.payload,
      };
    case "boundaryChange":
      return {
        ...state,
        bbox: action.payload,
      };
    case "openSruvey":
      return {
        ...state,
        isToggle: action.payload,
      };
    case "typeofsurvey":
      return {
        ...state,
        surveytype: action.payload,
      };
    case "timeoutmodal":
      return {
        ...state,
        sessionPModal: action.payload,
      };
    case "surveycondition":
      return {
        ...state,
        surveyCondition: action.payload,
      };
    case "houseInitial":
      return {
        ...state,
        house_initial_data: action.payload,
      };
    case "housesec1part1":
      return {
        ...state,
        housesec1part1object: action.payload,
      };

    case "housesec2":
      return {
        ...state,
        housesec2object: action.payload,
      };
    case "housesec3":
      return {
        ...state,
        housesec3object: action.payload,
      };
    case "housesec4":
      return {
        ...state,
        housesec4object: action.payload,
      };
    case "condosec1part1":
      return {
        ...state,
        condosec1part1object: action.payload,
      };
    case "condosec2":
      return {
        ...state,
        condosec2object: action.payload,
      };
    case "condosec3":
      return {
        ...state,
        condosec3object: action.payload,
      };
    case "condosec4":
      return {
        ...state,
        condosec4object: action.payload,
      };
    case "villasec1part1":
      return {
        ...state,
        villasec1part1object: action.payload,
      };
    case "villaTypeList":
      return {
        ...state,
        villaTypeList: action.payload,
      };
    case "villasec1part1loc":
      return {
        ...state,
        villasec1part1locobject: action.payload,
      };
    case "villasec1part2":
      return {
        ...state,
        villasec1part2object: action.payload,
      };
    case "villasec1part3":
      return {
        ...state,
        villasec1part3object: action.payload,
      };
    case "villasec1part4":
      return {
        ...state,
        villasec1part4object: action.payload,
      };
    case "villasec2":
      return {
        ...state,
        villasec2object: action.payload,
      };
    case "villasec3":
      return {
        ...state,
        villasec3object: action.payload,
      };
    case "villasec4":
      return {
        ...state,
        villasec4object: action.payload,
      };
    case "roomTypeList":
      return {
        ...state,
        roomTypeList: action.payload,
      };
    case "houseTypeList":
      return {
        ...state,
        houseTypeList: action.payload,
      };
    case "housesec1part1loc":
      return {
        ...state,
        housesec1part1locobject: action.payload,
      };
    case "housesec1part2":
      return {
        ...state,
        housesec1part2object: action.payload,
      };
    case "housesec1part3":
      return {
        ...state,
        housesec1part3object: action.payload,
      };
    case "housesec1part4":
      return {
        ...state,
        housesec1part4object: action.payload,
      };
    case "householdSec1":
      return {
        ...state,
        householdSec1Object: action.payload,
      };
    case "householdSec2":
      return {
        ...state,
        householdSec2Object: action.payload,
      };
    case "householdSec3":
      return {
        ...state,
        householdSec3Object: action.payload,
      };
    case "villaSec1":
      return {
        ...state,
        villaSec1Object: action.payload,
      };
    case "villaSec2":
      return {
        ...state,
        villaSec2Object: action.payload,
      };
    case "villaSec3":
      return {
        ...state,
        villaSec3Object: action.payload,
      };
    case "savedata":
      return {
        ...state,
        press_save: action.payload,
      };
    case "googlemapbase64":
      return {
        ...state,
        googlemap_64: action.payload,
      };
    case "changesurvey":
      return {
        ...state,
        survey_modal: action.payload,
      };
    case "projectid":
      return {
        ...state,
        project_id: action.payload,
      };
    case "regyear":
      return {
        ...state,
        getregyear: action.payload,
      };
    case "reghalf":
      return {
        ...state,
        getreghalf: action.payload,
      };
    case "regquarter":
      return {
        ...state,
        getquarter: action.payload,
      };

    case "getnotification":
      return {
        ...state,
        noti_toggle: action.payload,
      };
    case "preprojectid":
      return {
        ...state,
        pre_proj: action.payload,
      };
    case "old_data":
      return {
        ...state,
        oldproj_data: action.payload,
      };
    case "noticount":
      return {
        ...state,
        count_noti: action.payload,
      };
    case "project_dataid":
      return {
        ...state,
        proj_dataTable: action.payload,
      };
    case "table_loading":
      return {
        ...state,
        loading_tabledata: action.payload,
      };
    case "img_uploading":
      return {
        ...state,
        imageUploading: action.bool,
      };
    case "show_label":
      return {
        ...state,
        show_Label: action.payload,
      };
    //jan add
    case "condoTypeList":
      return {
        ...state,
        condoTypeList: action.payload,
      };
    case "condosec1part1loc":
      return {
        ...state,
        condosec1part1locobject: action.payload,
      };
    case "condosec1part2":
      return {
        ...state,
        condosec1part2object: action.payload,
      };
    case "condosec1part3":
      return {
        ...state,
        condosec1part3object: action.payload,
      };
    case "condosec1part4":
      return {
        ...state,
        condosec1part4object: action.payload,
      };
    case "condoSec1":
      return {
        ...state,
        condoSec1Object: action.payload,
      };
    case "condoSec2":
      return {
        ...state,
        condoSec2Object: action.payload,
      };
    case "condoSec3":
      return {
        ...state,
        condoSec3Object: action.payload,
      };
    case "BuildingAndUnit_sec2":
      return {
        ...state,
        BuildingAndUnit_sec2: action.payload,
      };
    case "BuildingAndUnit_sec3":
      return {
        ...state,
        BuildingAndUnit_sec3: action.payload,
      };
    case "catType1":
      return {
        ...state,
        cat_type_1: action.payload,
      };
    case "catType2":
      return {
        ...state,
        cat_type_2: action.payload,
      };
    case "catType3":
      return {
        ...state,
        cat_type_3: action.payload,
      };
    case "catType4":
      return {
        ...state,
        cat_type_4: action.payload,
      };
    case "catType5":
      return {
        ...state,
        cat_type_5: action.payload,
      };
    default:
      return state;
  }
};
