import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// core components
import Wizard from "components/Wizard/Wizard.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import * as config from "configure/configServer.js";

// condo
import CondoStep1 from "views/PSurvey/Condo/Condo_Section1.js";
import CondoStep2 from "views/PSurvey/Condo/Condo_Section2.js";
import CondoStep3 from "views/PSurvey/Condo/Condo_Section3.js";
import CondoStep4 from "views/PSurvey/Condo/Condo_Section4.js";

import surveyModal from "actions/survey/surveyModal.js";
import toggleSurvey from "actions/survey/toggleSurvey.js";
import CryptoJS from "crypto-js";
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import withStyles from "@material-ui/core/styles/withStyles";

class WizardCondo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      initial_value: {
        household_userid: 0,
        c_address: "N_A",
        c_district: "N_A",
        c_fax: "N_A",
        c_floor: "N_A",
        c_name: "N_A",
        c_namebuilding: "N_A",
        c_province: "N_A",
        c_road: "N_A",
        c_soi: "N_A",
        c_subdistrict: "N_A",
        c_tel: "N_A",
        household_address: "N_A",
        household_allocate: "N_A",
        household_brochuremapimg: [],
        household_currentconstructimg: [],
        household_dateconstruct: "01/01/1900",
        household_datefinish: "01/01/1900",
        household_datefirstsell: "01/01/1900",
        household_district: "1001",
        household_exhouseimg: [],
        household_explorerdate: "01/01/1900",
        household_explorers: "N_A",
        household_filebrochure: "N_A",
        household_filequotation: "N_A",
        household_filesurvey: "N_A",
        household_forelder: "N_A",
        household_frontprojectplaceimg: [],
        household_googlemapimg: [],
        household_maxprice: 0,
        household_minprice: 0,
        household_moo: "N_A",
        household_nameeng: "N_A",
        household_nameth: "N_A",
        household_ngan: 0,
        household_note: "N_A",
        household_postalcode: "N_A",
        household_projectid: "N_A",
        household_projectplanimg: [],
        household_projectprice: 0,
        household_projectstatus: 0,
        household_projectwebsite: "N_A",
        household_province: "10",
        household_rai: 0,
        household_road: "N_A",
        household_saleimprove: "N_A",
        household_saleofficeimg: [],
        household_soi: "N_A",
        household_sqwa: 0,
        household_subdistrict: "100101",
        household_tel_saleoffice: "N_A",
        household_typearea: "N_A",
        household_typeowner: "N_A",
        household_typesell: "N_A",
        lat: 0,
        lon: 0,
        unity_detail: [],
        cat_type: [],
        cat_floor: [],
        cat_width: [],
        cat_minarea: [],
        cat_maxarea: [],
        cat_minuseablearea: [],
        cat_maxuseablearea: [],
        cat_bedroom: [],
        cat_bathroom: [],
        cat_minprice: [],
        cat_maxprice: [],
        cat_discount: [],
        cat_allunitinproject: [],
        stc_type: [],
        stc_floor: [],
        stc_pricelenid: [],
        stc_unit: [],
        stc_sellsigned: [],
        stc_remaining: [],
        stc_newsigned: [],
        stc_transfered: [],
        stc_newtransfer: [],
        stc_notconstruct: [],
        stc_underconstruct: [],
        stc_finished: [],
        stc_newconstruct: [],
        stc_newfinished: [],
        stc_sellnotconstruct: [],
        stc_sellunderconstruct: [],
        stc_sellfinished: [],
        stc_rmnotconstruct: [],
        stc_rmunderconstruct: [],
        stc_rmfinished: [],
      },
    };
    this.submitSurvey = this.submitSurvey.bind(this);
  }

  componentDidMount() {
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("sessionP");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);
          this.setState({
            token: jsonDec.token,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }
  static propTypes = {
    surveytype: PropTypes.string.isRequired,
  };

  isEquivalent(a, b) {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }

    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];

      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
  }

  alertModal(validity) {
    this.props.surveyModal(
      <SweetAlert
        type={validity === "success" ? "success" : "warning"}
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={
          validity === "success"
            ? "ส่งแบบสอบถามสำเร็จ"
            : "ไม่สามารถบันทึกข้อมูลได้"
        }
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      />
    );
    if (validity === "success") {
      setTimeout(() => {
        this.props.surveyModal(null);
        this.props.toggleSurvey(false);
        window.location.replace("/survey");
      }, 1500);
    } else {
      setTimeout(() => {
        this.props.surveyModal(null);
      }, 1500);
    }
  }

  merge_data() {
    const target = this.props.condo_initial_data;
    const condo_data_sec1 = this.props.condoSec1Object;
    const condo_data_sec2 = this.props.condoSec2Object;
    const condo_data_sec3 = this.props.condosec3object;
    const condo_data_sec4 = this.props.condosec4object;
    // console.log(this.props.condoSec1Object);
    // console.log(this.props.condoSec2Object);
    // console.log(this.props.condosec3object)
    // console.log(this.props.condosec4object)
    const body = Object.assign(
      target,
      condo_data_sec1,
      condo_data_sec2,
      condo_data_sec3,
      condo_data_sec4
    );
    return body;
  }

  async call_marge_all() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.merge_data());
      }, 1000);
    });
  }

  async submitSurvey() {
    const body = await this.call_marge_all();
    const token = this.state.token;
    // console.log(body);
    this.props.surveyModal(
      <SweetAlert
        type={"warning"}
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => this.confirm_submit(body, token)}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={"ยืนยันการส่งข้อมูล"}
        showCancel={true}
        showConfirm={true}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.default
        }
        closeOnClickOutside={true}
        confirmBtnText={"ยืนยัน"}
        cancelBtnText={"ตรวจสอบใหม่"}
      >
        ท่านยืนยันที่จะทำการส่งแบบสอบถามใช่หรือไม่?
      </SweetAlert>
    );
  }

  confirm_submit = (body, token) => {
    // console.log(token)
    // console.log(body)
    // console.log(JSON.stringify(body))
    let log_condo_send = { event: "Send condo project to admin" };
    body = Object.assign(body, log_condo_send);
    // console.log(body);

    var request = new Request(config.API_submit_condo, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: token,
      }),
      body: JSON.stringify(body),
    });
    // console.log(body);
    // console.log(JSON.stringify(body))
    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
        if (responseJson.code === 200) {
          this.alertModal("success");
        } else {
          this.alertModal("warning");
        }
      })
      .catch(() => {
        this.setState({
          btLoading: false,
        });
      });
  };
  successAlert(title, message, status) {
    this.setState({
      alert: (
        <SweetAlert
          {...status}
          className="SweetAlert"
          title={<div>{title}</div>}
          onConfirm={() => this.setState({ alert: null })}
          //confirmBtnCssClass={}
          confirmBtnCssClass="modalBt"
          cancelBtnCssClass="modalBt modalBtCancel"
          confirmBtnText={"ยืนยัน"}
          cancelBtnText={"ยกเลิก"}
        >
          {message}
        </SweetAlert>
      ),
    });
  }

  render() {
    return (
      <GridContainer justify="center">
        {this.state.alert}

        <GridItem xs={12} sm={12}>
          {this.props.toggleSurvey ? (
            <Wizard
              color="ghbank"
              validate
              steps={[
                {
                  stepName: "ส่วนที่ 1",
                  stepComponent: CondoStep1,
                  stepId: "CondoStep1",
                },

                {
                  stepName: "ส่วนที่ 2",
                  stepComponent: CondoStep2,
                  stepId: "CondoStep2",
                },
                {
                  stepName: "ส่วนที่ 3",
                  stepComponent: CondoStep3,
                  stepId: "CondoStep3",
                },
                {
                  stepName: "ส่วนที่ 4",
                  stepComponent: CondoStep4,
                  stepId: "CondoStep4",
                },
              ]}
              title="โครงการอาคารชุด"
              subtitle=""
              previousButtonText="ย้อนกลับ"
              nextButtonText="ต่อไป"
              finishButtonText={"ส่งข้อมูลโครงการ"}
              showFinishButton={false}
              finishButtonClick={() => this.submitSurvey()}
            />
          ) : null}
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  surveyModal: (payload) => dispatch(surveyModal(payload)),
  toggleSurvey: (payload) => dispatch(toggleSurvey(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(sweetAlertStyle)(WizardCondo));
