/* eslint-disable react/prop-types */
import * as React from "react";

import CryptoJS from "crypto-js";
import * as config from "configure/configServer.js";

import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import "react-leaflet-markercluster/dist/styles.min.css"; // sass

import { connect } from "react-redux";
import setLatlon from "actions/survey/setLatlon.js";
import setProv from "actions/survey/setProvAmphoeTambon.js";
import setRegYear from "actions/survey/setRegYear.js";
import setRegQuarter from "actions/survey/setRegQuarter.js";
import setRegHalf from "actions/survey/setRegHalf.js";
import googlemapSave from "actions/survey/googleImage.js";
import setProjectid from "actions/survey/setProjectId.js";
import notiToggle from "actions/survey/notificationToggle.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

import domtoimage from "dom-to-image";

import { API_address_code, API_add_image } from "configure/configServer.js";

import "leaflet-switch-scale-control";
import "leaflet-switch-scale-control/src/L.Control.SwitchScaleControl.css";
import "leaflet.locatecontrol";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      path: require("assets/img/icon/HR.png"),
      bbox_layer: L.layerGroup(),

      markerLayer: L.layerGroup(),
      error: false,
      noti: false,
      key: undefined,
      storeData: "",
      bbox_data: "",
    };
  }

  componentDidMount() {
    this.props.onRef(this);

    let self = this;
    self.props.googlemapSave({ villa_googlemapimg: [""] });

    let osm_map = L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      maxZoom: 19,
      minZoom: 8,
    });

    // let url_heremap =
    //   "https://1.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png?app_id=" +
    //   config.heremap_appID +
    //   "&app_code=" +
    //   config.heremap_appCode;

    let url_heremap = `https://2.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/512/png8?apiKey=${config.heremap_API_key}&ppi=320`;

    // let url_heremap = "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}";

    let sattelite = L.tileLayer(url_heremap, {
      attribution:
        '&copy; <a href="https://www.here.com">HERE Technologies | HERE </a>',
      maxZoom: 19,
      minZoom: 8,
    });

    self.map = L.map(self.refs.map, {
      layers: [sattelite],
    }).setView([13.7563, 100.5018], 17);

    let baseMaps = {
      // eslint-disable-next-line prettier/prettier
      ภาพดาวเทียม: sattelite,
      // eslint-disable-next-line prettier/prettier
      OpenStreetMap: osm_map,
    };

    self.state.markerLayer.addTo(self.map);

    self.state.bbox_layer.addTo(self.map);

    self.map.addControl(
      new L.Control.SwitchScaleControl({
        ratioCustomItemText: "1: ระบุเลข scale",
        updateWhenIdle: true,
        scales: [
          1000,
          2500,
          5000,
          10000,
          20000,
          40000,
          70000,
          140000,
          300000,
          600000,
          1200000,
          2300000,
        ],
      })
    );

    L.control.locate().addTo(self.map);

    L.control.layers(baseMaps).addTo(self.map);

    self.map.on("click", function(e) {
      self.state.markerLayer.clearLayers();
      var popLocation = {
        lat: e.latlng.lat.toFixed(6),
        lng: e.latlng.lng.toFixed(6),
      };

      var popupContent =
        "{ " + e.latlng.lat.toFixed(6) + " " + e.latlng.lng.toFixed(6) + " }";
      var marker = L.marker(popLocation);
      marker.bindPopup(popupContent);

      self.state.markerLayer.addLayer(marker);

      self.props.setLatlon(popLocation);
      self.resetErrorState();
    });

    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          this.setState({
            key: responseJson.data,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  makeImage() {
    // eslint-disable-next-line react/no-string-refs
    var node = this.refs.map; // get the DOM node
    let that = this;
    domtoimage
      .toPng(node)
      .then(function(dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        if (that.state.error) {
          that.showNotification("danger", "ปักหมุดไม่ถูกต้อง");
        } else {
          that.setState({
            storeData: dataUrl.substring(22),
          });
          // console.log(that.props.proj_dataTable)
          // console.log(that.props.oldproj_data)
          if (
            that.props.proj_dataTable === undefined &&
            !(
              that.props.proj_dataTable &&
              that.props.proj_dataTable.villa_projectid
            )
          ) {
            that.showNotification("success", "ปักหมุดถูกต้อง");
          } else {
            that.showNotification(
              "warning",
              "ปักหมุดถูกต้อง คำเตือน:การแก้ข้อมูลที่ตั้ง อาจทำให้ข้อมูลคลาดเคลื่อนกับเลขที่แบบสอบถามได้"
            );
          }
        }
      })
      .catch(function(error) {
        // eslint-disable-next-line no-console
        console.error("oops, something went wrong!", error);
      });
  }

  mapState = () => {
    // console.log(this.state.error)
    return !this.state.error;
  };
  getImagePath(projectId) {
    let base64 = this.state.storeData;
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("sessionP");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    let body = {
      projectid: projectId,
      img: [base64],
      cat: "google_map",
      form: "villa",
    };

    var request = new Request(API_add_image, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify(body),
    });
    fetch(request)
      .then((response) => {
        return response.json();
      })
      .then((responseJson) => {
        if (responseJson.code === 200) {
          this.props.googlemapSave({
            villa_googlemapimg: [responseJson.data[0]],
          });
        } else {
          this.props.googlemapSave({ villa_googlemapimg: [""] });
        }
      });
  }

  showNotification(status, message) {
    this.setState({
      noti: true,
    });
    this.props.notiToggle(
      <Snackbar
        place="tc"
        color={status}
        icon={AddAlert}
        message={message}
        open={this.state.noti}
      />
    );

    setTimeout(() => {
      this.setState({
        noti: false,
      });
      this.props.notiToggle(
        <Snackbar
          place="tc"
          color={status}
          icon={AddAlert}
          message={message}
          open={this.state.noti}
        />
      );
    }, 1000);
  }

  componentDidUpdate(previousProps) {
    if (previousProps.bbox !== this.props.bbox) {
      this.zoomto();
    }
    if (
      previousProps.latlon.lat !== this.props.latlon.lat ||
      previousProps.latlon.lng !== this.props.latlon.lng ||
      (this.state.popLocation === undefined &&
        this.props.latlon.lat !== 0 &&
        this.props.latlon.lng !== 0)
    ) {
      this.state.markerLayer.clearLayers();
      try {
        var popLocation = {
          lat: this.props.latlon.lat,
          lng: this.props.latlon.lng,
        };
        this.setState({ popLocation });

        var popupContent =
          "ละติจูด(lat) : " +
          this.props.latlon.lat +
          " ลองจิจูด(lon) :" +
          this.props.latlon.lng;
        var marker;
        marker = L.marker(popLocation);
        marker.bindPopup(popupContent).openPopup();
        this.state.markerLayer.addLayer(marker);
        this.resetErrorState();
        this.getAddressCode(this.props.latlon.lat, this.props.latlon.lng);
        this.map.setView(popLocation, 17);
        this.makeImage();
      } catch {
        return;
      }
    }
  }

  getAddressCode(lat, lon) {
    let self = this;
    let data = {
      lat: lat,
      lon: lon,
    };
    var request = new Request(API_address_code, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(data),
    });
    fetch(request)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.code === 200) {
          let address = data.data[0];
          self.setAddress(address);
          self.getBbox(address);
        } else {
          self.setState({
            error: true,
          });
        }
      });
  }

  resetErrorState() {
    this.setState({
      error: false,
    });
  }

  setAddress(address) {
    let info = {
      prov_id: address.pro_id,
      amp_id: address.amp_id,
      tam_id: address.tam_id,
    };
    this.props.setProv(info);
  }

  getBbox(address) {
    let body = {
      prov_id: address.pro_id,
      amp_id: address.amp_id,
      tam_id: address.tam_id,
    };

    var request = new Request(config.API_bbox_address, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          let pro_zone = responseJson.data.features[0].properties.zone;
          let pro_period = responseJson.data.features[0].properties.period;
          this.setState({
            zone: responseJson.data.features[0].properties.zone,
            period: responseJson.data.features[0].properties.period,
          });

          var projId_data = {
            form: "villa",
            zone_id: pro_zone === null ? 0 : pro_zone,
            period_id: pro_period === null ? 0 : pro_period,
            pro_id: this.props.prov_amphoe_tambon.prov_id,
            current_id:
              this.props.oldproj_data &&
              this.props.oldproj_data.data &&
              this.props.oldproj_data.data.length > 0 &&
              "villa_projectid" in this.props.oldproj_data.data[0] &&
              this.props.oldproj_data.data[0].villa_projectid
                ? this.props.oldproj_data.data[0].villa_projectid
                : this.props.project_id === undefined
                ? 0
                : this.props.project_id,
          };
          // console.log(projId_data)
          this.setState({
            bbox_data: projId_data,
          });
          if (this.props.proj_dataTable === undefined) {
            this.sneaky_projectID(projId_data);
          } else {
            this.props.setProjectid(
              this.props.proj_dataTable.data[0].villa_projectid
            );
          }
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        // console.log(error);
      });
  }

  sneaky_projectID(bbox_data) {
    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("sessionP");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    var request = new Request(config.API_genprojid, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: tokenData,
      }),
      body: JSON.stringify(bbox_data),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson)
        if (responseJson.code === 200) {
          this.props.setProjectid(responseJson.data);
          this.props.setRegYear(responseJson.reg_year);
          this.props.setRegHalf(responseJson.reg_half);
          this.props.setRegQuarter(responseJson.reg_quarter);
          this.getImagePath(responseJson.data);
        } else {
          this.props.setProjectid(undefined);
        }
      })
      .catch((error) => {
        this.props.setProjectid(undefined);
        // eslint-disable-next-line no-console
        // console.log(error);
      });
  }

  getProjectId() {
    let value = this.state.bbox_data;

    const { key } = this.state;
    if (!key) {
      return;
    }
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);

    var aesStr = localStorage.getItem("sessionP");
    const dec = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
      keyutf,
      {
        iv: iv,
      }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    const jsonDec = JSON.parse(decStr);
    const tokenData = jsonDec.token;

    if (value === "") {
      //
    } else {
      var body = {
        form: "villa",
        zone_id: value.zone_id === null ? 0 : value.zone_id,
        period_id: value.pro_period === null ? 0 : value.pro_period,
        pro_id: this.props.prov_amphoe_tambon.prov_id,
        current_id:
          this.props.project_id === undefined ? 0 : this.props.project_id,
      };

      var request = new Request(config.API_genprojid, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          token: tokenData,
        }),
        body: JSON.stringify(body),
      });

      fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.code === 200) {
            this.props.setProjectid(responseJson.data);
            this.getImagePath(responseJson.data);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          // console.log(error);
        });
    }
  }

  zoomto() {
    let self = this;
    try {
      let geojson = L.geoJson(JSON.parse(self.props.bbox));
      // console.log(self.props.bbox);
      self.state.bbox_layer.clearLayers();
      geojson.addTo(self.state.bbox_layer);
      self.map.fitBounds(geojson.getBounds());
    } catch (err) {
      self.state.bbox_layer.clearLayers();
    }
  }

  render() {
    return (
      <div style={{ position: "relative" }}>
        {/* {//console.log(this.props.oldproj_data.villa_projectid)} */}
        {this.state.error && (
          <p style={{ color: "red" }}>กรุณาปักหมุดภายในประเทศไทย</p>
        )}
        <div
          // eslint-disable-next-line react/no-string-refs
          ref="map"
          style={{
            height: "400px",
            width: "100%",
            position: "relative",
            borderRadius: "6px",
            marginBottom: "22px",
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setLatlon: (payload) => dispatch(setLatlon(payload)),
  setProv: (payload) => dispatch(setProv(payload)),
  googlemapSave: (payload) => dispatch(googlemapSave(payload)),
  setProjectid: (payload) => dispatch(setProjectid(payload)),
  notiToggle: (payload) => dispatch(notiToggle(payload)),
  setRegYear: (payload) => dispatch(setRegYear(payload)),
  setRegHalf: (payload) => dispatch(setRegHalf(payload)),
  setRegQuarter: (payload) => dispatch(setRegQuarter(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapContainer);
