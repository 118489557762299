const setLatlon = payload => {
  return {
    type: "addHouseLatLonChange",
    payload:{
      lat:"lat" in payload?parseFloat(payload.lat):0,
      lng:"lng" in payload?parseFloat(payload.lng):0,
    }
  };
};

export default setLatlon;
