/* eslint-disable no-useless-escape */
import React from "react";
import { ClipLoader } from "react-spinners";

import CustomInput from "components/CustomInput/CustomInputTooltips.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "components/CustomButtons/Button.js";
import * as config from "configure/configServer.js";
import surveyModal from "actions/survey/surveyModal.js";
import _ from "lodash";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "components/Snackbar/Snackbar.js";
import Add from "@material-ui/icons/Add";

// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import Typography from '@material-ui/core/Typography';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import "react-bootstrap-table/dist/react-bootstrap-table.min.css";
import "./CondoTable.css";
import SweetAlert from "react-bootstrap-sweetalert";
import withStyles from "@material-ui/core/styles/withStyles";
import CryptoJS from "crypto-js";
import MaterialTable from "material-table";

import numeral from "numeral";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { connect } from "react-redux";
import condo_sec2Object from "actions/survey/condo/condo_sec2Data.js";
import condoSec2_Object from "actions/survey/condo/condo_sec2Obj.js";
import condo_sec3Object from "actions/survey/condo/condo_sec3Data.js";
import condo_sec4Object from "actions/survey/condo/condo_sec4Data.js";
import condoSec3_Object from "actions/survey/condo/condo_sec3Obj.js";
import setBuildingAndUnit_sec2 from "actions/survey/condo/BuildingAndUnit_sec2.js";
import setBuildingAndUnit_sec3 from "actions/survey/condo/BuildingAndUnit_sec3.js";
import PropTypes from "prop-types";
import surveyInfo from "actions/survey/surveyInfo.js";

const floor_type = [
  { id: 1, name: "ห้องชั้นเดียว" },
  { id: 2, name: "ห้องมีชั้นลอย (Loft)" },
  { id: 3, name: "ห้องชุด 2 ชั้น (Duplex)" },
];

const room_type = [
  { id: 1, name: "สตูดิโอ" },
  { id: 2, name: "1 ห้องนอน" },
  { id: 3, name: "2 ห้องนอน" },
  { id: 4, name: "3 ห้องนอนขึ้นไป" },
];
class CondoStep3 extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      memberStatus: this.props.memberStatus ? this.props.memberStatus : 1,

      // count number of all unit
      unit_all: 0,
      // new state start here
      part2_cattype: [],
      part2_catfloor: [],
      part2_catprice: [],
      part2_allunit: [],
      // noti core
      noti: false,
      open: false,
      color: "info",
      message: "Hello",
      place: "tr",
      //
      token: "",
      btLoading: false,
      value: 0,
      createModal: false,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      markerPosition: { lat: null, lng: null },
      welfareCheck: [],
      openNotification: false,
      language: "th",

      // stc_detail:
      // main stc_detail
      stc_detail: [],
      stc_pricerange: [],
      stc_selectunit: [],
      disabled_stcUnit: true,
      istype_land: false,
      // stc detail state change
      stc_type_State: "",
      stc_floor_Data: "",
      stc_floor_State: "",
      stc_pricelenid_State: "",
      stc_unit_State: "",
      stc_sellsigned_State: "",
      stc_remaining_State: "",
      stc_newsigned_State: "",
      stc_transfered_State: "",
      stc_newtransfer_State: "",
      stc_notconstruct_State: "",
      stc_underconstruct_State: "",
      stc_finished_State: "",
      stc_newconstruct_State: "",
      stc_newfinished_State: "",
      stc_sellnotconstruct_State: "",
      stc_sellunderconstruct_State: "",
      stc_sellfinished_State: "",
      stc_rmnotconstruct_State: "",
      stc_rmunderconstruct_State: "",
      stc_rmfinished_State: "",
      // tooltip state value
      tooltip_stc_sellsigned: "",
      tooltip_stc_remaining: "",
      tooltip_stc_newsigned: "",
      tooltip_stc_transfered: "",
      tooltip_stc_newtransfer: "",
      tooltip_stc_notconstruct: "",
      tooltip_stc_underconstruct: "",
      tooltip_stc_finished: "",
      tooltip_stc_newconstruct: "",
      tooltip_stc_newfinished: "",
      tooltip_stc_sellnotconstruct: "",
      tooltip_stc_sellunderconstruct: "",
      tooltip_stc_sellfinished: "",
      tooltip_stc_rmnotconstruct: "",
      tooltip_stc_rmunderconstruct: "",
      tooltip_stc_rmfinished: "",
      tooltip_sellroom_discount: "",
      tooltip_sellroom_maxarealicense: "",
      tooltip_sellroom_maxarea: "",
      tooltip_sellroom_maxprice: "",
      tooltip_sellroom_minarealicense: "",
      tooltip_sellroom_minarea: "",
      tooltip_sellroom_minprice: "",
      tooltip_sellroom_allunitinproject_State: "",

      // start of column render
      column: [
        {
          title: "ชื่ออาคาร",
          field: "sellroom_buildingname",
          lookup: {},
          type: "string",
          editable: "onAdd",
        },
        {
          title: "ประเภทชั้นของห้องชุด",
          field: "sellroom_floortype",
          type: "string",
          editable: "onAdd",
          render: (rowData) => (
            <label>
              {floor_type.map((props) => {
                // eslint-disable-next-line react/prop-types
                if (rowData && props.id === rowData.sellroom_floortype)
                  return props.name;
                else return null;
              })}
            </label>
          ),
          editComponent: (props) => (
            <FormControl
              fullWidth
              className={this.props.classes.selectFormControl}
              style={{
                margin: "0 0 17px 0",
                position: "relative",
                paddingTop: "27px",
                verticalAlign: "unset",
              }}
            >
              <InputLabel
                className={this.props.classes.selectLabel}
                style={{
                  top: "10px",
                  color:
                    this.state.cat_room_type_State === ""
                      ? "#AAAAAA"
                      : this.state.cat_room_type_State === "success"
                      ? "green"
                      : "red",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "1.42857",
                }}
              >
                โปรดเลือก
              </InputLabel>
              <Select
                style={{
                  marginTop: "0px",
                  textAlign: "start",
                  fontSize: "16px",
                }}
                MenuProps={{
                  className: this.props.classes.selectMenu,
                }}
                classes={{
                  select: this.props.classes.select,
                }}
                value={props.value ? props.value : ""}
                onChange={(event) => this.change(event, props, "cat_room_type")}
              >
                {}
                <MenuItem
                  disabled={this.checkDisabledFloorType(props, 1)}
                  value="1"
                >
                  ห้องชั้นเดียว
                </MenuItem>
                <MenuItem
                  disabled={this.checkDisabledFloorType(props, 2)}
                  value="2"
                >
                  ห้องมีชั้นลอย (Loft)
                </MenuItem>
                <MenuItem
                  disabled={this.checkDisabledFloorType(props, 3)}
                  value="3"
                >
                  ห้องชุด 2 ชั้น (Duplex)
                </MenuItem>
              </Select>
            </FormControl>
          ),
        },
        {
          title: "ประเภทห้องชุด",
          field: "sellroom_type",
          type: "string",
          editable: "onAdd",
          render: (rowData) => (
            <label>
              {room_type.map((props) => {
                // eslint-disable-next-line react/prop-types
                if (rowData && props.id === rowData.sellroom_type)
                  return props.name;
                else return null;
              })}
            </label>
          ),
          editComponent: (props) => (
            <FormControl
              fullWidth
              className={this.props.classes.selectFormControl}
              style={{
                margin: "0 0 17px 0",
                position: "relative",
                paddingTop: "27px",
                verticalAlign: "unset",
              }}
            >
              <InputLabel
                className={this.props.classes.selectLabel}
                style={{
                  top: "10px",
                  color:
                    this.state.cat_room_type_State === ""
                      ? "#AAAAAA"
                      : this.state.cat_room_type_State === "success"
                      ? "green"
                      : "red",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "1.42857",
                }}
              >
                โปรดเลือก
              </InputLabel>
              <Select
                style={{
                  marginTop: "0px",
                  textAlign: "start",
                  fontSize: "16px",
                }}
                MenuProps={{
                  className: this.props.classes.selectMenu,
                }}
                classes={{
                  select: this.props.classes.select,
                }}
                value={props.value ? props.value : ""}
                onChange={(event) => this.change(event, props, "cat_room_type")}
              >
                {}
                <MenuItem disabled={this.checkDisabledType(props, 1)} value="1">
                  สตูดิโอ
                </MenuItem>
                <MenuItem disabled={this.checkDisabledType(props, 2)} value="2">
                  1 ห้องนอน
                </MenuItem>
                <MenuItem disabled={this.checkDisabledType(props, 3)} value="3">
                  2 ห้องนอน
                </MenuItem>
                <MenuItem disabled={this.checkDisabledType(props, 4)} value="4">
                  3 ห้องนอนขึ้นไป
                </MenuItem>
              </Select>
            </FormControl>
          ),
        },
        {
          title: "ต่ำสุด",
          field: "sellroom_minarealicense",
          emptyValue: "0",
          render: (rowData) =>
            numeral(rowData.sellroom_minarealicense).format("0,0.[00]"),
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.sellroom_minarealicense_State === "error"
                  ? "**"
                  : undefined
              }
              success={this.state.sellroom_minarealicense_State === "success"}
              error={this.state.sellroom_minarealicense_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "sellroom_minarealicense"),
                value: props.value ? props.value : "",
              }}
              tooltipText={this.state.tooltip_sellroom_minarealicense}
            />
          ),
        },
        {
          title: "สูงสุด",
          field: "sellroom_maxarealicense",
          emptyValue: 0,
          render: (rowData) =>
            numeral(rowData.sellroom_maxarealicense).format("0,0.[00]"),
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.sellroom_maxarealicense_State === "error"
                  ? "**"
                  : undefined
              }
              success={this.state.sellroom_maxarealicense_State === "success"}
              error={this.state.sellroom_maxarealicense_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "sellroom_maxarealicense"),
                value: props.value ? props.value : "",
              }}
              tooltipText={this.state.tooltip_sellroom_maxarealicense}
            />
          ),
        },
        {
          title: "ต่ำสุด",
          field: "sellroom_minarea",
          emptyValue: "0",
          render: (rowData) =>
            numeral(rowData.sellroom_minarea).format("0,0.[00]"),
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.sellroom_minarea_State === "error" ? "**" : undefined
              }
              success={this.state.sellroom_minarea_State === "success"}
              error={this.state.sellroom_minarea_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "sellroom_minarea"),
                value: props.value ? props.value : "",
              }}
              tooltipText={this.state.tooltip_sellroom_minarea}
            />
          ),
        },
        {
          title: "สูงสุด",
          field: "sellroom_maxarea",
          emptyValue: 0,
          render: (rowData) =>
            numeral(rowData.sellroom_maxarea).format("0,0.[00]"),
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.sellroom_maxarea_State === "error" ? "**" : undefined
              }
              success={this.state.sellroom_maxarea_State === "success"}
              error={this.state.sellroom_maxarea_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "sellroom_maxarea"),
                value: props.value ? props.value : "",
              }}
              tooltipText={this.state.tooltip_sellroom_maxarea}
            />
          ),
        },
        {
          title: "ต่ำสุด",
          field: "sellroom_minprice",
          initialEditValue: 0,
          emptyValue: 0,
          render: (rowData) => numeral(rowData.sellroom_minprice).format("0,0"),
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.sellroom_minprice_State === "error"
                  ? "**"
                  : undefined
              }
              success={this.state.sellroom_minprice_State === "success"}
              error={this.state.sellroom_minprice_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "sellroom_minprice"),
                value: numeral(props.value).format("0,0"),
              }}
              tooltipText={this.state.tooltip_sellroom_minprice}
            />
          ),
        },
        {
          title: "สูงสุด",
          field: "sellroom_maxprice",
          render: (rowData) => numeral(rowData.sellroom_maxprice).format("0,0"),
          emptyValue: "0",
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.sellroom_maxprice_State === "error"
                  ? "**"
                  : undefined
              }
              success={this.state.sellroom_maxprice_State === "success"}
              error={this.state.sellroom_maxprice_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "sellroom_maxprice"),
                value: numeral(props.value).format("0,0"),
              }}
              tooltipText={this.state.tooltip_sellroom_maxprice}
            />
          ),
        },
        {
          title: "ส่วนลด (ถ้ามี)",
          field: "sellroom_discount",
          type: "numeric",
          initialEditValue: "0",
          emptyValue: "0",
          render: (rowData) => numeral(rowData.sellroom_discount).format("0,0"),
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.sellroom_discount_State === "error"
                  ? "**"
                  : undefined
              }
              success={this.state.sellroom_discount_State === "success"}
              error={this.state.sellroom_discount_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "sellroom_discount"),
                value: numeral(props.value).format("0,0"),
              }}
              tooltipText={this.state.tooltip_sellroom_discount}
            />
          ),
        },
        {
          title: "หน่วยในผังโครงการ",
          field: "sellroom_allunitinproject",
          type: "numeric",
          initialEditValue: 0,
          emptyValue: 0,
          render: (rowData) =>
            numeral(rowData.sellroom_allunitinproject).format("0,0"),
          editComponent: (props) => (
            <CustomInput
              labelText={
                this.state.sellroom_allunitinproject_State === "error"
                  ? "**"
                  : undefined
              }
              success={this.state.sellroom_allunitinproject_State === "success"}
              error={this.state.sellroom_allunitinproject_State === "error"}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) =>
                  this.change(event, props, "sellroom_allunitinproject"),
                value: props.value ? props.value : "",
              }}
              tooltipText={this.state.tooltip_sellroom_allunitinproject_State}
            />
          ),
        },
      ],
      data: [],
      index: -1,
    };
  }

  static propTypes = {
    condo_sec3Object: PropTypes.object.isRequired,
  };
  checkDisabledFloorType(curRow, val) {
    // console.log(this.state.stc_detail);
    let SelectRow;
    if (!("sellroom_floortype" in curRow.rowData)) {
      // console.log("this is add type");
      SelectRow = this.state.stc_detail.filter((stcRow) => {
        return (
          stcRow.sellroom_buildingname ===
            curRow.rowData.sellroom_buildingname &&
          stcRow.sellroom_floortype === val &&
          (stcRow.sellroom_type === 1 ||
            stcRow.sellroom_type === 2 ||
            stcRow.sellroom_type === 3 ||
            stcRow.sellroom_type === 4)
        );
      });
    } else {
      // console.log("this is edit type");
      try {
        // console.log(curRow.columnDef.tableData.id)
        // console.log(curRow.rowData.tableData.id)
        SelectRow = this.state.stc_detail.filter((stcRow, id) => {
          // console.log(stcRow)
          return (
            stcRow.sellroom_buildingname ===
              curRow.rowData.sellroom_buildingname &&
            stcRow.sellroom_floortype === val &&
            (stcRow.sellroom_type === 1 ||
              stcRow.sellroom_type === 2 ||
              stcRow.sellroom_type === 3 ||
              stcRow.sellroom_type === 4) &&
            curRow.rowData.tableData.id !== id
          );
        });
      } catch {
        SelectRow = this.state.stc_detail.filter((stcRow, id) => {
          // console.log(stcRow)
          return (
            stcRow.sellroom_buildingname ===
              curRow.rowData.sellroom_buildingname &&
            stcRow.sellroom_floortype === val &&
            (stcRow.sellroom_type === 1 ||
              stcRow.sellroom_type === 2 ||
              stcRow.sellroom_type === 3 ||
              stcRow.sellroom_type === 4)
          );
        });
      }
    }
    // console.log(SelectRow);
    if (SelectRow.length === 4) {
      // console.log("Floor True");
      return true;
    } else {
      // console.log("Floor False");
      return false;
    }
  }
  checkDisabledType(curRow, val) {
    // console.log(this.state.stc_detail)
    let SelectRow;
    if (!("sellroom_type" in curRow.rowData)) {
      // console.log("this is add type");
      SelectRow = this.state.stc_detail.filter((stcRow) => {
        return (
          stcRow.sellroom_buildingname ===
            curRow.rowData.sellroom_buildingname &&
          stcRow.sellroom_floortype === curRow.rowData.sellroom_floortype &&
          stcRow.sellroom_type === val
        );
      });
    } else {
      // console.log("this is edit type");
      try {
        // console.log(curRow.columnDef.tableData.id)
        // console.log(curRow.rowData.tableData.id)
        SelectRow = this.state.stc_detail.filter((stcRow, id) => {
          // console.log(stcRow)
          return (
            stcRow.sellroom_buildingname ===
              curRow.rowData.sellroom_buildingname &&
            stcRow.sellroom_floortype === curRow.rowData.sellroom_floortype &&
            stcRow.sellroom_type === val &&
            curRow.rowData.tableData.id !== id
          );
        });
      } catch {
        SelectRow = this.state.stc_detail.filter((stcRow, id) => {
          // console.log("catch");
          // console.log(stcRow)
          return (
            stcRow.sellroom_buildingname ===
              curRow.rowData.sellroom_buildingname &&
            stcRow.sellroom_floortype === curRow.rowData.sellroom_floortype &&
            stcRow.sellroom_type === val
          );
        });
      }
    }
    // console.log(SelectRow)
    if (SelectRow.length > 0) {
      // console.log("Type True");
      return true;
    } else {
      // console.log("Type False");
      return false;
    }
  }
  change(event, props, stateName) {
    let value = parseInt(event.target.value);
    // console.log(stateName)
    // console.log(value)
    switch (stateName) {
      case "cat_room_type":
        if (value === 1 || value === 2) {
          // console.log('set')
          this.setState({
            tooltip_sellroom_minarea:
              "มีค่าตั้งแต่ 15 ตร.ม. ขึ้นไป และต้องไม่เกินค่าสูงสุด",
            tooltip_sellroom_maxarea:
              "มีค่าตั้งแต่ 15 ตร.ม. ขึ้นไป และต้องไม่น้อยกว่าค่าต่ำสุด",
            tooltip_sellroom_minarealicense:
              "มีค่าตั้งแต่ 15 ตร.ม. ขึ้นไป และต้องไม่เกินค่าสูงสุด",
            tooltip_sellroom_maxarealicense:
              "มีค่าตั้งแต่ 15 ตร.ม. ขึ้นไป และต้องไม่น้อยกว่าค่าต่ำสุด",
            cat_room_type_State: "success",
          });
        } else if (value === 3) {
          // console.log('set2')

          this.setState({
            tooltip_sellroom_minarea:
              "มีค่าตั้งแต่ 20 ตร.ม. ขึ้นไป และต้องไม่เกินค่าสูงสุด",
            tooltip_sellroom_maxarea:
              "มีค่าตั้งแต่ 20 ตร.ม. ขึ้นไป และต้องไม่น้อยกว่าค่าต่ำสุด",
            tooltip_sellroom_minarealicense:
              "มีค่าตั้งแต่ 20 ตร.ม. ขึ้นไป และต้องไม่เกินค่าสูงสุด",
            tooltip_sellroom_maxarealicense:
              "มีค่าตั้งแต่ 20 ตร.ม. ขึ้นไป และต้องไม่น้อยกว่าค่าต่ำสุด",
            cat_room_type_State: "success",
          });
        } else if (value === 4) {
          // console.log('set3')

          this.setState({
            tooltip_sellroom_minarea:
              "มีค่าตั้งแต่ 30 ตร.ม. ขึ้นไป และต้องไม่เกินค่าสูงสุด",
            tooltip_sellroom_maxarea:
              "มีค่าตั้งแต่ 30 ตร.ม. ขึ้นไป และต้องไม่น้อยกว่าค่าต่ำสุด",
            tooltip_sellroom_minarealicense:
              "มีค่าตั้งแต่ 30 ตร.ม. ขึ้นไป และต้องไม่เกินค่าสูงสุด",
            tooltip_sellroom_maxarealicense:
              "มีค่าตั้งแต่ 30 ตร.ม. ขึ้นไป และต้องไม่น้อยกว่าค่าต่ำสุด",
            cat_room_type_State: "success",
          });
        } else {
          // console.log('se4')

          this.setState({
            cat_room_type_State: "",
          });
        }
        props.onChange(value);
        if (value !== props.rowData.sellroom_type) {
          this.setState({
            sellroom_minarealicense_State: "error",
            sellroom_maxarealicense_State: "error",
            sellroom_minarea_State: "error",
            sellroom_maxarea_State: "error",
            sellroom_minprice_State: "error",
            sellroom_maxprice_State: "error",
            sellroom_allunitinproject_State: "error",
            sellroom_discount_State: "",
          });
        }
        break;
      case "sellroom_minarealicense":
        if (this.checkAreaMin(event.target.value, props)) {
          this.checkAreaMinMax(
            props.rowData.sellroom_floortype,
            event.target.value,
            props.rowData.sellroom_maxarealicense,
            props.rowData.sellroom_minarea,
            props.rowData.sellroom_maxarea
          );
          // console.log("checkAreaMin")
          //   if (
          //     this.checkAreaMinMax(
          //       props.rowData.sellroom_floortype,
          //       event.target.value,
          //       props.rowData.sellroom_maxarealicense,
          //       props.rowData.sellroom_minarea,
          //       props.rowData.sellroom_maxarea
          //     )
          //   ) {
          //     this.setState({ [stateName + "_State"]: "success" });
          //   } else {
          //     this.setState({ [stateName + "_State"]: "error" });
          //   }
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "sellroom_maxarealicense":
        if (this.checkAreaMax(event.target.value, props)) {
          this.checkAreaMinMax(
            props.rowData.sellroom_floortype,
            props.rowData.sellroom_minarealicense,
            event.target.value,
            props.rowData.sellroom_minarea,
            props.rowData.sellroom_maxarea
          );
          // if (
          //   this.checkAreaMinMax(
          //     props.rowData.sellroom_floortype,
          //     props.rowData.sellroom_minarealicense,
          //     event.target.value,
          //     props.rowData.sellroom_minarea,
          //     props.rowData.sellroom_maxarea
          //   )
          // ) {
          //   this.setState({ [stateName + "_State"]: "success" });
          // } else {
          //   this.setState({ [stateName + "_State"]: "error" });
          // }
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "sellroom_minarea":
        if (this.checkAreaMin(event.target.value, props)) {
          this.checkAreaMinMax(
            props.rowData.sellroom_floortype,
            props.rowData.sellroom_minarealicense,
            props.rowData.sellroom_maxarealicense,
            event.target.value,
            props.rowData.sellroom_maxarea
          );
          // console.log("checkAreaMin")
          // if (
          //   this.checkAreaMinMax(
          //     props.rowData.sellroom_floortype,
          //     props.rowData.sellroom_minarealicense,
          //     props.rowData.sellroom_maxarealicense,
          //     event.target.value,
          //     props.rowData.sellroom_maxarea
          //   )
          // ) {
          //   this.setState({ [stateName + "_State"]: "success" });
          // } else {
          //   this.setState({ [stateName + "_State"]: "error" });
          // }
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "sellroom_maxarea":
        if (this.checkAreaMax(event.target.value, props)) {
          this.checkAreaMinMax(
            props.rowData.sellroom_floortype,
            props.rowData.sellroom_minarealicense,
            props.rowData.sellroom_maxarealicense,
            props.rowData.sellroom_minarea,
            event.target.value
          );
          // if (
          //   this.checkAreaMinMax(
          //     props.rowData.sellroom_floortype,
          //     props.rowData.sellroom_minarealicense,
          //     props.rowData.sellroom_maxarealicense,
          //     props.rowData.sellroom_minarea,
          //     event.target.value
          //   )
          // ) {
          //   this.setState({ [stateName + "_State"]: "success" });
          // } else {
          //   this.setState({ [stateName + "_State"]: "error" });
          // }
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "sellroom_minprice":
        if (this.checkMinPrice(numeral(event.target.value).value(), props)) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "sellroom_maxprice":
        if (this.checkMaxPrice(numeral(event.target.value).value(), props)) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "sellroom_discount":
        if (this.checkNumber(numeral(event.target.value).value(), props)) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      case "sellroom_allunitinproject":
        if (this.checkNumber(event.target.value, props)) {
          this.setState({ [stateName + "_State"]: "success" });
        } else {
          this.setState({ [stateName + "_State"]: "error" });
        }
        break;
      default:
        break;
    }
  }

  checkNumber = (value, props) => {
    var numberRegex = /^[0-9]+$/;
    if (numberRegex.test(value)) {
      props.onChange(value);
      return true;
    } else if (!value) {
      props.onChange(0);
      return true;
    }
    props.onChange("");
    return false;
  };

  checkAreaMin = (value, props) => {
    let min = 0;
    let currentVal = parseFloat(value);

    switch (props.rowData.sellroom_type) {
      case 1:
        min = 15;
        break;
      case 2:
        min = 15;
        break;
      case 3:
        min = 20;
        break;
      case 4:
        min = 30;
        break;
      default:
        min = 15;
        break;
    }

    var areaRegex = /^[0-9]+(\.)?([0-9]?)([0-9]?)?$/;
    if (areaRegex.test(value)) {
      props.onChange(value);
      if (currentVal < min) {
        return false;
      } else {
        return true;
      }
    }
    props.onChange(value);
  };
  errorModal(validity) {
    this.props.surveyModal(
      <SweetAlert
        type={validity === "400" ? "warning" : "danger"}
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={validity === "400" ? "บันทึกไม่สำเร็จ" : "การเชื่อมต่อมีปัญหา"}
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      />
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 1500);
  }
  checkAreaMax = (value, props) => {
    let min = 0;
    let currentVal = parseFloat(value);
    switch (props.rowData.sellroom_type) {
      case 1:
        min = 15;
        break;
      case 2:
        min = 15;
        break;
      case 3:
        min = 20;
        break;
      case 4:
        min = 30;
        break;
      default:
        min = 15;
        break;
    }

    var areaRegex = /^[0-9]+(\.)?([0-9]?)([0-9]?)?$/;
    if (areaRegex.test(value)) {
      props.onChange(value);
      if (currentVal < min) {
        return false;
      } else {
        return true;
      }
    }
    props.onChange(value);
  };

  checkAreaMinMax(floortype, minarealicense, maxarealicense, minarea, maxarea) {
    let minlicense = parseFloat(minarealicense);
    let maxlicense = parseFloat(maxarealicense);
    let min = parseFloat(minarea);
    let max = parseFloat(maxarea);
    // console.log(floortype);
    if (floortype === 2) {
      // console.log("floor more than", floortype);
      if (minlicense <= maxlicense) {
        // console.log("minlicense < maxlicense");
        this.setState({
          sellroom_minarealicense_State: "success",
          sellroom_maxarealicense_State: "success",
        });
      } else {
        // console.log("minlicense >= maxlicense");
        this.setState({
          sellroom_minarealicense_State: "error",
          sellroom_maxarealicense_State: "error",
        });
        return;
      }
      if (min <= max) {
        // console.log("min < max");
        this.setState({
          sellroom_minarea_State: "success",
          sellroom_maxarea_State: "success",
        });
      } else {
        // console.log("max >= min");
        this.setState({
          sellroom_minarea_State: "error",
          sellroom_maxarea_State: "error",
        });
        return;
      }
      if (minlicense < min) {
        // console.log("minlicense < min");
        this.setState({
          sellroom_minarealicense_State: "success",
          sellroom_minarea_State: "success",
        });
      } else {
        // console.log("minlicense >= min");
        this.setState({
          sellroom_minarealicense_State: "error",
          sellroom_minarea_State: "error",
        });
        return;
      }
      if (maxlicense < max) {
        // console.log("maxlicense < max");
        this.setState({
          sellroom_maxarealicense_State: "success",
          sellroom_maxarea_State: "success",
        });
      } else {
        // console.log("maxlicense >= max");
        this.setState({
          sellroom_maxarealicense_State: "error",
          sellroom_maxarea_State: "error",
        });
        return;
      }
      return;
    } else {
      // console.log("floor", "equal", floortype);
      if (minlicense > maxlicense) {
        // console.log("test minlicense > maxlicense");
        this.setState({
          sellroom_minarealicense_State: "error",
          sellroom_maxarealicense_State: "error",
        });
        return;
      } else {
        // console.log("test minlicense <= maxlicense");
        this.setState({
          sellroom_minarealicense_State: "success",
          sellroom_maxarealicense_State: "success",
        });
      }
      if (min > max) {
        // console.log("test min > max");
        this.setState({
          sellroom_minarea_State: "error",
          sellroom_maxarea_State: "error",
        });
        return;
      } else {
        // console.log("test min <= max");
        this.setState({
          sellroom_minarea_State: "success",
          sellroom_maxarea_State: "success",
        });
      }
      if (minlicense === min) {
        // console.log("test minlicense === min");
        this.setState({
          sellroom_minarealicense_State: "success",
          sellroom_min_State: "success",
        });
      } else {
        // console.log("test minlicense !== min");
        this.setState({
          sellroom_minarealicense_State: "error",
          sellroom_min_State: "error",
        });
        return;
      }
      if (maxlicense === max) {
        // console.log("test maxlicense === max");
        this.setState({
          sellroom_maxarealicense_State: "success",
          sellroom_maxarea_State: "success",
        });
      } else {
        // console.log("test maxlicense !== max");
        this.setState({
          sellroom_maxarealicense_State: "error",
          sellroom_maxarea: "error",
        });
        return;
      }
      return;
    }
  }

  checkMinPrice = (value, props) => {
    let maxPrice = parseFloat(props.rowData.sellroom_maxprice);
    var priceRegex = /^[0-9]+$/;
    if (priceRegex.test(value)) {
      props.onChange(value);
      if (value <= maxPrice) {
        this.setState({ sellroom_maxprice_State: "success" });
        return true;
      } else {
        this.setState({ sellroom_maxprice_State: "error" });
        return false;
      }
    }
    props.onChange("");
  };

  checkMaxPrice = (value, props) => {
    let minPrice = parseFloat(props.rowData.sellroom_minprice);
    var priceRegex = /^[0-9]+$/;
    if (priceRegex.test(value)) {
      props.onChange(value);
      if (value >= minPrice) {
        this.setState({ sellroom_minprice_State: "success" });
        return true;
      } else {
        this.setState({ sellroom_minprice_State: "error" });
        return false;
      }
    }
    props.onChange("");
  };

  get_projectData = (value) => {
    const status = value.status;
    this.setState({
      projectStatus: status,
      view_psurvey: value.view_psurvey,
    });
    let tmp = value.sellroom || [];

    let unit_all = 0;
    for (let i = 0; i < tmp.length; i++) {
      let val = tmp[i].sellroom_allunitinproject;
      if (val) {
        unit_all += parseInt(val);
      }
    }
    this.setState({ unit_all: unit_all });
    // console.log(tmp)
    this.setState(
      {
        stc_detail: tmp,
      },
      () => {
        this.merge_data();
      }
    );
    this.props.setBuildingAndUnit_sec3(tmp);
  };

  sumallUnit(unit) {
    const arr = unit;
    let sum_unit = 0;
    for (let i = 0; i < arr.length; i++) {
      sum_unit = parseInt(sum_unit) + parseInt(arr[i].bs_totalunit);
    }

    this.setState({
      total_unit: sum_unit,
    });
  }

  componentDidMount() {
    this.getSecretKey();
    this.stc_setWarning();
    if (
      this.props.proj_dataTable === undefined ||
      this.props.proj_dataTable.data.length <= 0
    ) {
      // ignore
    } else {
      if (
        !(
          this.props.proj_dataTable.sellroom &&
          this.props.proj_dataTable.sellroom.length === 1 &&
          "sellroom_buildingname" in this.props.proj_dataTable.sellroom[0] &&
          (this.props.proj_dataTable.sellroom[0].sellroom_buildingname === "" ||
            this.props.proj_dataTable.sellroom[0].sellroom_buildingname ===
              "N_A")
        )
      ) {
        this.get_projectData(this.props.proj_dataTable);
      }
    }
  }

  getProjectStatus() {
    if (this.state.project_status === 1) {
      return "โครงการปิดการขาย";
    } else if (this.state.project_status === 2) {
      return "อยู่ระหว่างขาย";
    } else if (this.state.project_status === 3) {
      return "เปิดขายใหม่ในช่วงสำรวจ";
    } else if (this.state.project_status === 4) {
      return "โครงการอนาคต";
    } else if (this.state.project_status === 5) {
      return "โครงการชะลอการขาย";
    } else if (this.state.project_status === 6) {
      return "โครงการเปิดและปิดการขายในช่วงสำรวจ";
    }
  }

  deleteSection() {
    let projectid =
      this.props.condo_initial_data.condo_projectid === "N_A"
        ? this.props.proj_dataTable
          ? this.props.proj_dataTable.data[0].condo_projectid
          : this.props.condoSec1Object.condo_projectid
        : this.props.condo_initial_data.condo_projectid;
    let refcode =
      this.props.condo_initial_data.condo_refcode === undefined
        ? this.props.proj_dataTable
          ? this.props.proj_dataTable.data[0].condo_refcode
          : this.props.condoSec1Object.condo_refcode
        : this.props.condo_initial_data.condo_refcode;
    var param = {
      projectid: projectid,
      refcode: refcode,
      event: "Delete condo section 3 and 4",
    };
    var request = new Request(config.API_sendevent, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        token: this.state.token,
      }),
      body: JSON.stringify(param),
    });
    fetch(request)
      .then((response) => {
        response.json().then((data) => {
          if (data.code === 200) {
            // console.log('Delete Success')
            // this.setState({
            //   deleteAlert: (
            //     <SweetAlert
            //       success="true"
            //       style={{ display: "block", marginTop: "-100px" }}
            //       title="ส่วนที่ 4 ถูกลบเรียบร้อย"
            //       showConfirm={false}
            //     ></SweetAlert>
            //   ),
            // });
          } else {
            this.setState({
              deleteAlert: (
                <SweetAlert
                  warning
                  style={{ display: "block", marginTop: "-100px" }}
                  title="ทำรายการล้มเหลว กรุณาทำรายการใหม่"
                  showConfirm={false}
                ></SweetAlert>
              ),
            });
          }
          setTimeout(() => {
            this.setState({ deleteAlert: null });
          }, 1500);
        });
      })
      .catch(function(err) {
        // console.log(err);
      });
  }

  stc_setWarning() {
    this.setState({
      tooltip_stc_sellsigned:
        "หน่วย ทำสัญญาจะซื้อจะขาย + เหลือขาย ต้องเท่ากับ หน่วยในผังโครงการ",
      tooltip_stc_remaining:
        "หน่วย เหลือขาย + ทำสัญญาจะซื้อจะขาย ต้องเท่ากับ หน่วยในผังโครงการ",
      tooltip_stc_newsigned:
        "หน่วย ทำสัญญาจะซื้อจะขายใหม่ ฯ ต้องน้อยกว่าหรือเท่ากับ ทำสัญญาจะซื้อจะขายแล้ว",
      tooltip_stc_transfered:
        "หน่วย โอนกรรมสิทธิ์แล้ว ต้องน้อยกว่าหรือเท่ากับ ทำสัญญาจะซื้อจะขายแล้ว",
      tooltip_stc_newtransfer:
        "หน่วย โอนกรรมสิทธิ์ใหม่ ฯ ต้องน้อยกว่าหรือเท่ากับ โอนกรรมสิทธิ์แล้ว",
      tooltip_stc_notconstruct:
        "หน่วย ยังไม่ก่อสร้าง + อยู่ระหว่างก่อสร้าง + สร้างเสร็จ ต้องเท่ากับ หน่วยในผังโครงการ",
      tooltip_stc_underconstruct:
        "หน่วย อยู่ระหว่างก่อสร้าง + ยังไม่ก่อสร้าง + สร้างเสร็จ ต้องเท่ากับ หน่วยในผังโครงการ",
      tooltip_stc_finished:
        "หน่วย สร้างเสร็จ + อยู่ระหว่างก่อสร้าง + ยังไม่ก่อสร้าง ต้องเท่ากับ หน่วยในผังโครงการ",
      tooltip_stc_newconstruct: "",
      tooltip_stc_newfinished:
        "หน่วย ก่อสร้างเสร็จใหม่ ฯ ต้องน้อยกว่าหรือเท่ากับ สร้างเสร็จ",
      tooltip_stc_sellnotconstruct:
        "หน่วย ยังไม่ก่อสร้าง + อยู่ระหว่างก่อสร้าง + สร้างเสร็จ ต้องเท่ากับ ขายได้",
      tooltip_stc_sellunderconstruct:
        "หน่วย อยู่ระหว่างก่อสร้าง + ยังไม่ก่อสร้าง + สร้้างเสร็จ ต้องเท่ากับ ขายได้",
      tooltip_stc_sellfinished:
        "หน่วย สร้างเสร็จ + ยังไม่ก่อสร้าง + อยู่ระหว่างก่อสร้าง ต้องเท่ากับ ขายได้",
      tooltip_stc_rmnotconstruct:
        "หน่วย ยังไม่ก่อสร้าง + อยู่ระหว่างก่อสร้าง + สร้างเสร็จ ต้องเท่ากับ เหลือขาย",
      tooltip_stc_rmunderconstruct:
        "หน่วย อยู่ระหว่างก่อสร้าง + ยังไม่ก่อสร้าง + สร้้างเสร็จ ต้องเท่ากับ เหลือขาย",
      tooltip_stc_rmfinished:
        "หน่วย สร้างเสร็จ + ยังไม่ก่อสร้าง + อยู่ระหว่างก่อสร้าง ต้องเท่ากับ เหลือขาย",
    });
  }

  getBuildingName() {
    return this.state.building_name_list;
  }

  loadingButton(isLoading) {
    if (isLoading) {
      return (
        <ClipLoader
          css={"margin-right: 10px;"}
          sizeUnit={"px"}
          size={20}
          color={"#ffffff"}
          loading={this.state.loading}
        />
      );
    } else {
      return null;
    }
  }

  alertModal(validity) {
    this.props.surveyModal(
      <SweetAlert
        type={validity === "success" ? "success" : "warning"}
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={
          validity === "success" ? "บันทึกสำเร็จ" : "ไม่สามารถบันทึกข้อมูลได้"
        }
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      />
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 1500);
  }

  surveyModal(validity) {
    this.props.surveyModal(
      <SweetAlert
        type="error"
        style={{
          display: "block",
          color: "black",
          fontSize: "18px",
        }}
        onConfirm={() => null}
        onCancel={() => this.props.surveyModal(null)}
        customClass="modalContent"
        title={
          validity === "save"
            ? "ไม่สามารถบันทึกเอกสารได้"
            : "ไม่สามารถส่งเอกสารได้"
        }
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={true}
      >
        {validity === "save"
          ? "กรุณาตรวจสอบและกรอกข้อมูลเอกสารที่จำเป็นให้ครบถ้วนก่อนทำการบันทึก"
          : "กรุณาตรวจสอบและกรอกข้อมูลเอกสารที่จำเป็นให้ครบถ้วนก่อนทำการส่ง"}
      </SweetAlert>
    );
    setTimeout(() => {
      this.props.surveyModal(null);
    }, 1500);
  }

  getSecretKey() {
    var request = new Request(config.API_gen_secretkey, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 200) {
          const key = responseJson.data;
          const keyutf = CryptoJS.enc.Utf8.parse(key);
          const iv = CryptoJS.enc.Base64.parse(key);

          var aesStr = localStorage.getItem("sessionP");
          const dec = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(aesStr) },
            keyutf,
            {
              iv: iv,
            }
          );
          const decStr = CryptoJS.enc.Utf8.stringify(dec);
          const jsonDec = JSON.parse(decStr);
          this.setState({
            token: jsonDec.token,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  componentDidUpdate(previousProps) {
    if (
      JSON.stringify(previousProps.condosec3object) !==
      JSON.stringify(this.props.condosec3object)
    ) {
      if (this.props.condosec3object.length === 0) {
        this.setState({ stc_detail: [] });
      }
    }
    if (
      JSON.stringify(previousProps.condoSec2Object) !==
      JSON.stringify(this.props.condoSec2Object)
    ) {
      if (this.props.condoSec2Object.bs_name) {
        let buildingname = this.props.condoSec2Object.bs_name;
        var column = this.state.column;

        let buildingname_obj = buildingname.reduce((acc, elem) => {
          acc[elem] = elem;
          return acc;
        }, {});

        column[0].lookup = buildingname_obj;
        this.setState({ column }, () => {});
      }
    }

    if (
      previousProps.BuildingAndUnit_sec2 !== this.props.BuildingAndUnit_sec2
    ) {
      this.setState({
        BuildingAndUnit_sec2: this.props.BuildingAndUnit_sec2,
      });
    }
  }

  // generateCat_type(part2Data) {
  //   let stc_detail_obj = [];
  //   const arraycat_type = part2Data.cat_type;
  //   const arraycat_floor = part2Data.cat_floor;
  //   const arraycat_width = part2Data.cat_width;

  //   var i;
  //   for (i = 0; i < arraycat_type.length; i++) {
  //     let merge_name = "";
  //     if (arraycat_type[i] === 1) {
  //       merge_name =
  //         "บ้านเดี่ยว " +
  //         arraycat_floor[i] +
  //         " ชั้น กว้าง " +
  //         arraycat_width[i] +
  //         " ม.";
  //     } else if (arraycat_type[i] === 2) {
  //       merge_name =
  //         "บ้านแฝด " +
  //         arraycat_floor[i] +
  //         " ชั้น กว้าง " +
  //         arraycat_width[i] +
  //         " ม.";
  //     } else if (arraycat_type[i] === 3) {
  //       merge_name =
  //         "ทาวน์เฮ้าส์ " +
  //         arraycat_floor[i] +
  //         " ชั้น กว้าง " +
  //         arraycat_width[i] +
  //         " ม.";
  //     } else if (arraycat_type[i] === 4) {
  //       merge_name =
  //         "อาคารพาณิชย์ " +
  //         arraycat_floor[i] +
  //         " ชั้น กว้าง " +
  //         arraycat_width[i] +
  //         " ม.";
  //     } else {
  //       merge_name = "ที่ดินเปล่า";
  //     }

  //     stc_detail_obj.push({
  //       value: i + 1,
  //       label: merge_name,
  //       cat_type: arraycat_type[i],
  //     });
  //     this.setState({
  //       part2_cattype: stc_detail_obj,
  //     });
  //   }
  // }

  // generateCat_floor(part2Data) {
  //   let stc_detail_obj = [];
  //   const arraycat_type = part2Data.cat_type;
  //   const arraycat_floor = part2Data.cat_floor;

  //   var i;
  //   for (i = 0; i < arraycat_type.length; i++) {
  //     let merge_floor = arraycat_floor[i];

  //     stc_detail_obj.push({ value: i + 1, label: merge_floor });
  //     this.setState({
  //       part2_catfloor: stc_detail_obj,
  //     });
  //   }
  // }

  // generateCat_price(part2Data) {
  //   let stc_detail_obj = [];
  //   const arraycat_type = part2Data.cat_type;
  //   const arraycat_minprice = part2Data.cat_minprice;
  //   const arraycat_maxprice = part2Data.cat_maxprice;

  //   var i;
  //   for (i = 0; i < arraycat_type.length; i++) {
  //     stc_detail_obj.push({
  //       value: i + 1,
  //       price_min: arraycat_minprice[i],
  //       price_max: arraycat_maxprice[i],
  //       price_type: arraycat_type[i],
  //     });
  //     this.setState({
  //       part2_catprice: stc_detail_obj,
  //     });
  //   }
  // }

  // generateCat_unit(part2Data) {
  //   let stc_detail_obj = [];
  //   const arraycat_type = part2Data.cat_type;
  //   const arraycat_allunit = part2Data.cat_allunitinproject;

  //   var i;
  //   for (i = 0; i < arraycat_type.length; i++) {
  //     stc_detail_obj.push({
  //       value: i + 1,
  //       unit_type: arraycat_allunit[i],
  //     });
  //     this.setState({
  //       part2_allunit: stc_detail_obj,
  //     });
  //   }
  // }

  check_cattype(type) {
    const arraycat_type = this.state.part2_cattype;

    const match_val = arraycat_type.filter(function(obj) {
      return obj.value === type;
    });

    if (match_val[0].cat_type === 5) {
      this.setState({
        istype_land: true,
      });
    } else {
      this.setState({
        istype_land: false,
      });
    }

    return match_val[0].label;
  }

  // render_roomtype(type) {
  //   const arraycat_type = this.state.stc_pricerange;
  //   // console.log(arraycat_type);

  //   const match_val = arraycat_type.filter(function(obj) {
  //     return obj.srpprice_id === type;
  //   });
  //   // console.log(match_val);

  //   return match_val[0].srpprice_info;
  //   // return (
  //   //   numeral(match_val[0].srpprice_min).format("0,0.[0]") +
  //   //   " - " +
  //   //   numeral(match_val[0].srpprice_max).format("0,0.[0]")
  //   // );
  // }

  // stc_selectType = (event, props) => {
  //   props.onChange(event);

  //   this.stc_priceRange(event);
  //   this.stc_setUnit(event);
  //   this.check_cattype(event);
  //   return true;
  // };

  // stc_selectPrice = (event, props) => {
  //   props.onChange(event);
  //   return true;
  // };

  // stc_typeUnit = (event, props) => {
  //   props.onChange(event);

  //   this.setState({
  //     disabled_stcUnit: false,
  //     stc_sellsigned_State: "error",
  //     stc_remaining_State: "error",
  //     stc_newsigned_State: "error",
  //     stc_transfered_State: "error",
  //     stc_newtransfer_State: "error",
  //   });
  //   return true;
  // };

  // stc_typeSellsigned = (event, props, cval) => {
  //   const val = parseInt(event);
  //   const maxunit = parseInt(props.rowData.stc_unit);
  //   const compare_val = parseInt(cval);

  //   var phoneRex = /^[0-9]+$/;
  //   if (phoneRex.test(val)) {
  //     props.onChange(numeral(event).format("0"));
  //     if (val > maxunit) {
  //       this.showNotification("warning", "ห้ามเกินหน่วยในผังโครงการทั้งหมด");
  //       this.setState({
  //         stc_sellsigned_State: "error",
  //         stc_remaining_State: "error",
  //         stc_newsigned_State: "error",
  //         stc_transfered_State: "error",
  //         stc_newtransfer_State: "error",
  //       });
  //       return false;
  //     }
  //     if (maxunit === val + compare_val) {
  //       this.setState({
  //         stc_sellsigned_State: "success",
  //         stc_remaining_State: "success",
  //       });
  //       return true;
  //     }
  //     this.setState({
  //       stc_sellsigned_State: "error",
  //       stc_remaining_State: "error",
  //       stc_newsigned_State: "error",
  //       stc_transfered_State: "error",
  //       stc_newtransfer_State: "error",
  //     });
  //     return false;
  //   }
  //   props.onChange("");
  //   return false;
  // };

  // stc_typeRemaining = (event, props, cval) => {
  //   const val = parseInt(event);
  //   const maxunit = parseInt(props.rowData.stc_unit);
  //   const compare_val = parseInt(cval);

  //   var phoneRex = /^[0-9]+$/;
  //   if (phoneRex.test(val)) {
  //     props.onChange(numeral(event).format("0"));
  //     if (val > maxunit) {
  //       this.showNotification("warning", "ห้ามเกินหน่วยในผังโครงการทั้งหมด");
  //       this.setState({
  //         stc_sellsigned_State: "error",
  //         stc_remaining_State: "error",
  //       });
  //       return false;
  //     }
  //     if (maxunit === val + compare_val) {
  //       this.setState({
  //         stc_sellsigned_State: "success",
  //         stc_remaining_State: "success",
  //       });
  //       return true;
  //     }
  //     this.setState({
  //       stc_sellsigned_State: "error",
  //       stc_remaining_State: "error",
  //     });
  //     return false;
  //   }
  //   props.onChange("");
  //   return false;
  // };

  // stc_typeNewsigned = (event, props, cval) => {
  //   const val = parseInt(event);
  //   const compare_val = parseInt(cval);

  //   var phoneRex = /^[0-9]+$/;
  //   if (phoneRex.test(val)) {
  //     props.onChange(numeral(event).format("0"));
  //     if (val <= compare_val) {
  //       return true;
  //     }
  //     return false;
  //   }
  //   props.onChange("");
  //   return false;
  // };

  // stc_typeTransfered = (event, props, cval) => {
  //   const val = parseInt(event);
  //   const compare_val = parseInt(cval);

  //   var phoneRex = /^[0-9]+$/;
  //   if (phoneRex.test(val)) {
  //     props.onChange(numeral(event).format("0"));
  //     if (val <= compare_val) {
  //       return true;
  //     }
  //     return false;
  //   }
  //   props.onChange("");
  //   return false;
  // };

  // stc_typeNewtransfer = (event, props, cval) => {
  //   const val = parseInt(event);
  //   const compare_val = parseInt(cval);

  //   var phoneRex = /^[0-9]+$/;
  //   if (phoneRex.test(val)) {
  //     props.onChange(numeral(event).format("0"));
  //     if (val <= compare_val) {
  //       return true;
  //     }
  //     return false;
  //   }
  //   props.onChange("");
  //   return false;
  // };

  // stc_typeNotconstruct = (event, props) => {
  //   const val = parseInt(event);
  //   const maxunit = parseInt(props.rowData.stc_unit);
  //   const compare_val1 = parseInt(props.rowData.stc_underconstruct);
  //   const compare_val2 = parseInt(props.rowData.stc_finished);

  //   var phoneRex = /^[0-9]+$/;
  //   if (phoneRex.test(val)) {
  //     props.onChange(numeral(event).format("0"));
  //     if (val > maxunit) {
  //       this.showNotification("warning", "ห้ามเกินหน่วยในผังโครงการทั้งหมด");
  //       return false;
  //     }
  //     if (maxunit === val + compare_val1 + compare_val2) {
  //       this.setState({
  //         stc_notconstruct_State: "success",
  //         stc_underconstruct_State: "success",
  //         stc_finished_State: "success",
  //       });
  //       return true;
  //     }
  //     this.setState({
  //       stc_notconstruct_State: "error",
  //       stc_underconstruct_State: "error",
  //       stc_finished_State: "error",
  //     });
  //     return false;
  //   }
  //   props.onChange("");
  //   return false;
  // };

  // stc_typeUnderconstruct = (event, props) => {
  //   const val = parseInt(event);
  //   const maxunit = parseInt(props.rowData.stc_unit);
  //   const compare_val1 = parseInt(props.rowData.stc_notconstruct);
  //   const compare_val2 = parseInt(props.rowData.stc_finished);

  //   var phoneRex = /^[0-9]+$/;
  //   if (phoneRex.test(val)) {
  //     props.onChange(numeral(event).format("0"));
  //     if (val > maxunit) {
  //       this.showNotification("warning", "ห้ามเกินหน่วยในผังโครงการทั้งหมด");
  //       return false;
  //     }
  //     if (maxunit === val + compare_val1 + compare_val2) {
  //       this.setState({
  //         stc_notconstruct_State: "success",
  //         stc_underconstruct_State: "success",
  //         stc_finished_State: "success",
  //       });
  //       return true;
  //     }
  //     this.setState({
  //       stc_notconstruct_State: "error",
  //       stc_underconstruct_State: "error",
  //       stc_finished_State: "error",
  //     });
  //     return false;
  //   }
  //   props.onChange("");
  //   return false;
  // };

  // stc_typeFinished = (event, props) => {
  //   const val = parseInt(event);
  //   const maxunit = parseInt(props.rowData.stc_unit);
  //   const compare_val1 = parseInt(props.rowData.stc_notconstruct);
  //   const compare_val2 = parseInt(props.rowData.stc_underconstruct);

  //   var phoneRex = /^[0-9]+$/;
  //   if (phoneRex.test(val)) {
  //     props.onChange(numeral(event).format("0"));
  //     if (val > maxunit) {
  //       this.showNotification("warning", "ห้ามเกินหน่วยในผังโครงการทั้งหมด");
  //       return false;
  //     }
  //     if (maxunit === val + compare_val1 + compare_val2) {
  //       this.setState({
  //         stc_notconstruct_State: "success",
  //         stc_underconstruct_State: "success",
  //         stc_finished_State: "success",
  //       });
  //       return true;
  //     }
  //     this.setState({
  //       stc_notconstruct_State: "error",
  //       stc_underconstruct_State: "error",
  //       stc_finished_State: "error",
  //     });
  //     return false;
  //   }
  //   props.onChange("");
  //   return false;
  // };

  // stc_typeNewconstruct = (event, props) => {
  //   const val = parseInt(event);

  //   var phoneRex = /^[0-9]+$/;
  //   if (phoneRex.test(val)) {
  //     props.onChange(numeral(event).format("0"));
  //     return true;
  //   }
  //   props.onChange("");
  //   return false;
  // };

  // stc_typeNewfinished = (event, props, cval) => {
  //   const val = parseInt(event);
  //   const compare_val = parseInt(cval);

  //   var phoneRex = /^[0-9]+$/;
  //   if (phoneRex.test(val)) {
  //     props.onChange(numeral(event).format("0"));
  //     if (val <= compare_val) {
  //       return true;
  //     }
  //     return false;
  //   }
  //   props.onChange("");
  //   return false;
  // };

  // stc_typeRmnotconstruct = (event, props) => {
  //   const val = parseInt(event);
  //   const maxunit = parseInt(props.rowData.stc_remaining);
  //   const compare_val1 = parseInt(props.rowData.stc_rmunderconstruct);
  //   const compare_val2 = parseInt(props.rowData.stc_rmfinished);

  //   var phoneRex = /^[0-9]+$/;
  //   if (phoneRex.test(val)) {
  //     props.onChange(numeral(event).format("0"));
  //     if (val > maxunit) {
  //       this.showNotification("warning", "ห้ามเกินหน่วยในผังโครงการทั้งหมด");
  //       return false;
  //     }
  //     if (maxunit === val + compare_val1 + compare_val2) {
  //       this.setState({
  //         stc_rmnotconstruct_State: "success",
  //         stc_rmunderconstruct_State: "success",
  //         stc_rmfinished_State: "success",
  //       });
  //       return true;
  //     }
  //     this.setState({
  //       stc_rmnotconstruct_State: "error",
  //       stc_rmunderconstruct_State: "error",
  //       stc_rmfinished_State: "error",
  //     });
  //     return false;
  //   }
  //   props.onChange("");
  //   return false;
  // };

  isResubmiting = () => {
    return this.state.projectStatus === 2;
  };
  isEditable = () => {
    return !(
      this.state.view_psurvey === true ||
      this.state.projectStatus === 1 ||
      this.state.projectStatus === 3 ||
      this.state.memberStatus === 2
    );
  };

  // stc_typeRmunderconstruct = (event, props) => {
  //   const val = parseInt(event);
  //   const maxunit = parseInt(props.rowData.stc_remaining);
  //   const compare_val1 = parseInt(props.rowData.stc_rmnotconstruct);
  //   const compare_val2 = parseInt(props.rowData.stc_rmfinished);

  //   var phoneRex = /^[0-9]+$/;
  //   if (phoneRex.test(val)) {
  //     props.onChange(numeral(event).format("0"));
  //     if (val > maxunit) {
  //       this.showNotification("warning", "ห้ามเกินหน่วยในผังโครงการทั้งหมด");
  //       return false;
  //     }
  //     if (maxunit === val + compare_val1 + compare_val2) {
  //       this.setState({
  //         stc_rmnotconstruct_State: "success",
  //         stc_rmunderconstruct_State: "success",
  //         stc_rmfinished_State: "success",
  //       });
  //       return true;
  //     }
  //     this.setState({
  //       stc_rmnotconstruct_State: "error",
  //       stc_rmunderconstruct_State: "error",
  //       stc_rmfinished_State: "error",
  //     });
  //     return false;
  //   }
  //   props.onChange("");
  //   return false;
  // };

  // stc_typeRmfinished = (event, props) => {
  //   const val = parseInt(event);
  //   const maxunit = parseInt(props.rowData.stc_remaining);
  //   const compare_val1 = parseInt(props.rowData.stc_rmnotconstruct);
  //   const compare_val2 = parseInt(props.rowData.stc_rmunderconstruct);

  //   var phoneRex = /^[0-9]+$/;
  //   if (phoneRex.test(val)) {
  //     props.onChange(numeral(event).format("0"));
  //     if (val > maxunit) {
  //       this.showNotification("warning", "ห้ามเกินหน่วยในผังโครงการทั้งหมด");
  //       return false;
  //     }
  //     if (maxunit === val + compare_val1 + compare_val2) {
  //       this.setState({
  //         stc_rmnotconstruct_State: "success",
  //         stc_rmunderconstruct_State: "success",
  //         stc_rmfinished_State: "success",
  //       });
  //       return true;
  //     }
  //     this.setState({
  //       stc_rmnotconstruct_State: "error",
  //       stc_rmunderconstruct_State: "error",
  //       stc_rmfinished_State: "error",
  //     });
  //     return false;
  //   }
  //   props.onChange("");
  //   return false;
  // };

  // trigger function
  // stc_priceRange = (event) => {
  //   const arraycat_price = this.state.part2_catprice;
  //   // part2_stcprice
  //   // stc_pricerange
  //   const match_val = arraycat_price.filter(function(obj) {
  //     return obj.value === event;
  //   });

  //   var body = {
  //     srpprice_cat: 2,
  //     srpprice_max: match_val[0].price_max,
  //     srpprice_min: match_val[0].price_min,
  //   };

  //   var request = new Request(config.API_getprice, {
  //     method: "POST",
  //     headers: new Headers({ "Content-Type": "application/json" }),
  //     body: JSON.stringify(body),
  //   });

  //   fetch(request)
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       const response_array = responseJson.data;

  //       switch (responseJson.code) {
  //         case 200:
  //           if (response_array.length === 0) {
  //           } else {
  //             this.setState({
  //               stc_pricerange: response_array,
  //             });
  //           }
  //           break;
  //         default:
  //           break;
  //       }
  //     })
  //     .catch((error) => {
  //       // console.log(error);
  //     });
  // };

  // stc_setUnit = (event) => {
  //   const arraycat_unit = this.state.part2_allunit;

  //   const match_val = arraycat_unit.filter(function(obj) {
  //     return obj.value === event;
  //   });

  //   this.setState({
  //     stc_selectunit: match_val,
  //   });
  // };

  // filter_typeval = (data) => {
  //   const arraycat_type = this.state.part2_cattype;
  //   const match_val = arraycat_type.filter(function(obj) {
  //     return obj.value === data;
  //   });
  //   return match_val[0].label;
  // };

  checkDataInRowBeforeAdd = (data) => {
    const {
      sellroom_buildingname_State,
      sellroom_type_State,
      sellroom_minarealicense_State,
      sellroom_maxarealicense_State,
      sellroom_minarea_State,
      sellroom_maxarea_State,
      sellroom_minprice_State,
      sellroom_maxprice_State,
    } = this.state;

    if (
      !data.sellroom_buildingname ||
      !data.sellroom_floortype ||
      !data.sellroom_type ||
      sellroom_buildingname_State === "error" ||
      sellroom_type_State === "error" ||
      sellroom_minarealicense_State === "error" ||
      sellroom_maxarealicense_State === "error" ||
      sellroom_minarea_State === "error" ||
      sellroom_maxarea_State === "error" ||
      sellroom_minprice_State === "error" ||
      sellroom_maxprice_State === "error"
    ) {
      return false;
    } else {
      return true;
    }
  };

  async call_merge_sec3() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.merge_data());
      }, 1000);
    });
  }

  merge_data() {
    let stc_detail_obj = {};
    // console.log(this.state.stc_detail);
    if (this.state.stc_detail.length > 0) {
      this.state.stc_detail.map(function(value) {
        return Object.keys(value).forEach(function(i) {
          if (!stc_detail_obj[i]) {
            stc_detail_obj[i] = [];
          }
          stc_detail_obj[i].push(value[i]);
        });
      });
    }
    let N = this.state.stc_detail.length;
    let condodata_section3 = {
      sellroom_buildingname:
        stc_detail_obj && "sellroom_buildingname" in stc_detail_obj
          ? stc_detail_obj["sellroom_buildingname"]
          : Array(N).fill(""),
      st_roomtype:
        stc_detail_obj && "sellroom_type" in stc_detail_obj
          ? stc_detail_obj["sellroom_type"]
          : Array(N).fill(1),
      sellroom_floortype:
        stc_detail_obj && "sellroom_floortype" in stc_detail_obj
          ? stc_detail_obj["sellroom_floortype"]
          : Array(N).fill(1),
      sellroom_type:
        stc_detail_obj && "sellroom_type" in stc_detail_obj
          ? stc_detail_obj["sellroom_type"]
          : Array(N).fill(1),
      sellroom_minarealicense:
        stc_detail_obj && "sellroom_minarealicense" in stc_detail_obj
          ? stc_detail_obj["sellroom_minarealicense"]
          : Array(N).fill(0),
      sellroom_maxarealicense:
        stc_detail_obj && "sellroom_maxarealicense" in stc_detail_obj
          ? stc_detail_obj["sellroom_maxarealicense"]
          : Array(N).fill(0),
      sellroom_minarea:
        stc_detail_obj && "sellroom_minarea" in stc_detail_obj
          ? stc_detail_obj["sellroom_minarea"]
          : Array(N).fill(0),
      sellroom_maxarea:
        stc_detail_obj && "sellroom_maxarea" in stc_detail_obj
          ? stc_detail_obj["sellroom_maxarea"]
          : Array(N).fill(0),
      sellroom_minprice:
        stc_detail_obj && "sellroom_minprice" in stc_detail_obj
          ? stc_detail_obj["sellroom_minprice"]
          : Array(N).fill(0),
      sellroom_maxprice:
        stc_detail_obj && "sellroom_maxprice" in stc_detail_obj
          ? stc_detail_obj["sellroom_maxprice"]
          : Array(N).fill(0),
      sellroom_discount:
        stc_detail_obj && "sellroom_discount" in stc_detail_obj
          ? stc_detail_obj["sellroom_discount"]
          : Array(N).fill(0),
      sellroom_allunitinproject:
        stc_detail_obj && "sellroom_allunitinproject" in stc_detail_obj
          ? stc_detail_obj["sellroom_allunitinproject"]
          : Array(N).fill(0),
    };
    this.props.condo_sec3Object(condodata_section3);
    // console.log(condodata_section3);
    return condodata_section3;
  }

  checkUnitAndBuilding = () => {
    let unit_and_building = this.props.BuildingAndUnit_sec2.map((props) => {
      let newData = {
        name: props.bs_name,
        unit: parseFloat(props.bs_totalunit),
      };
      return newData;
    });
    let buildingname_obj = this.state.stc_detail.map((props) => {
      let newData = {
        name: props.sellroom_buildingname,
        unit: parseFloat(props.sellroom_allunitinproject),
      };
      return newData;
    }, {});
    let result_sec2 = unit_and_building.reduce((c, v) => {
      c[v.name] = (c[v.name] || 0) + v.unit;
      return c;
    }, {});

    let result_sec3 = buildingname_obj.reduce((c, v) => {
      c[v.name] = (c[v.name] || 0) + v.unit;
      return c;
    }, {});

    if (_.isEqual(result_sec2, result_sec3)) {
      return true;
    }
    return false;
  };

  async isValidated() {
    if (await this.checkUnitAndBuilding()) {
      const body = await this.call_merge_sec3();

      if (
        body.sellroom_buildingname.length === 0 ||
        body.sellroom_floortype.length === 0 ||
        body.sellroom_type.length === 0 ||
        body.sellroom_minarea.length === 0 ||
        body.sellroom_maxarea.length === 0 ||
        body.sellroom_minprice.length === 0 ||
        body.sellroom_maxprice.length === 0 ||
        body.sellroom_discount.length === 0 ||
        body.sellroom_allunitinproject.length === 0
      ) {
        this.surveyModal("error");
        return false;
      } else {
        this.props.setBuildingAndUnit_sec3(this.state.stc_detail);
        return true;
      }
    } else {
      this.showNotification(
        "warning",
        "หน่วยรวมไม่ตรงกับส่วนที่ 2 หรือไม่ได้ใส่รายละเอียดในบางอาคาร กรุณาตรวจสอบหน่วย และอาคารให้ตรงกับส่วนที่ 2"
      );
      return false;
    }
  }

  async submit_save() {
    this.setState({
      btLoading: true,
    });
    const token = this.state.token;
    const part1 = this.props.condoSec1Object;
    const part2 = this.props.condoSec2Object;
    const part4 = this.props.condosec4object;
    const body = await this.call_merge_sec3();
    if (
      body.sellroom_buildingname.length === 0 ||
      body.sellroom_floortype.length === 0 ||
      body.sellroom_type.length === 0 ||
      body.sellroom_minarea.length === 0 ||
      body.sellroom_maxarea.length === 0 ||
      body.sellroom_minprice.length === 0 ||
      body.sellroom_maxprice.length === 0 ||
      body.sellroom_discount.length === 0 ||
      body.sellroom_allunitinproject.length === 0
    ) {
      this.surveyModal("save");
      this.setState({
        btLoading: false,
      });
    } else {
      const source = {
        ...part1,
        ...part2,
        ...part4,
        ...body,
      };
      // console.log(source);
      const target = this.props.condo_initial_data;
      const log_condo_sec_3 = { event: "Insert or update condo section 3" };
      const mergebody = Object.assign(target, source, log_condo_sec_3);
      // console.log(mergebody);
      var request = new Request(config.API_save_condo, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          token: token,
        }),
        body: JSON.stringify(mergebody),
      });

      fetch(request)
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            btLoading: false,
          });
          if (responseJson.code === 200) {
            this.alertModal("success");
          } else {
            this.errorModal("400");
          }
        })
        .catch(() => {
          this.setState({
            btLoading: false,
          });
        });
    }
  }

  successAlert(title, message, status) {
    let trashStyle = {
      paddingTop: "10px",
      color: "#e74c3c",
    };
    let messageStyle = {
      color: "#e74c3c",
      fontWeight: "bold",
      fontSize: "16px",
    };
    this.setState({
      alert: (
        <SweetAlert
          {...status}
          custom
          className="SweetAlert"
          title={<div>{title}</div>}
          showCancel
          showConfirm
          confirmBtnCssClass="modalBtDelete"
          cancelBtnCssClass="modalBt modalBtExit"
          customIcon={
            <div style={trashStyle}>
              <i className="fa fa-trash fa-4x" />
            </div>
          }
          confirmBtnText={"ยืนยัน"}
          cancelBtnText={"ยกเลิก"}
        >
          {<span style={messageStyle}>{message}</span>}
        </SweetAlert>
      ),
    });
  }

  handleResetSt = (idx) => () => {
    var tempBs = this.state.stc_detail;

    for (var prop in tempBs[idx]) {
      if (
        prop !== "id" &&
        prop !== "stc_type" &&
        prop !== "stc_floor" &&
        prop !== "stc_width" &&
        prop !== "stc_pricelenid" &&
        prop !== "stc_minprice" &&
        prop !== "stc_maxprice"
      ) {
        tempBs[idx][prop] = "";
      }
    }

    this.setState({
      stc_detail: tempBs,
    });
  };

  showNotification(type, message) {
    this.setState({ open: true, color: type, message: message });
    setTimeout(
      function() {
        this.setState({ open: false });
      }.bind(this),
      6000
    );
  }

  sendState() {
    return this.state;
  }

  countTotalUnit = (newData) => {
    // count number of unit
    let unit_all = 0;
    for (let i = 0; i < this.state.stc_detail.length; i++) {
      let val = this.state.stc_detail.stc_detail[i].sellroom_allunitinproject;
      if (val) {
        unit_all += parseInt(val);
      }
    }

    if (newData.sellroom_allunitinproject) {
      unit_all += parseInt(newData.sellroom_allunitinproject);
    }

    if (unit_all <= this.props.condosec2object.bs_totalunit) {
      this.setState({ unit_all: unit_all });

      return true;
    } else {
      this.showNotification(
        "warning",
        "กรุณาตรวจสอบจำนวนหน่วยทั้งหมด หน่วยทั้งหมดต้องไม่เกินที่ระบุไว้ในส่วนที่ 2"
      );
      return false;
    }
  };
  blockEdit(isEdit) {
    if (isEdit) {
      return (
        <div
          style={{
            position: "absolute",
            backgroundColor: "rgba(0,0,0,0)",
            zIndex: "10000",
            width: "100%",
            height: "100%",
            left: 0,
            right: 0,
            cursor: "not-allowed",
          }}
        ></div>
      );
    } else {
      //
    }
  }
  render() {
    var self = this;
    const bt_style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "120px",
      height: "40px",
      fontSize: "16px",
      borderRadius: "0.2rem",
      backgroundColor: "#384D97",
      color: "white",
    };
    const StyleHeader = {
      border: "1px solid #dddddd",
      textAlign: "center",
      borderLeft: "none",
    };
    const StyleTHeader = {
      fontSize: "14px",
      width: "100%",
      borderCollapse: "collapse",
      whiteSpace: "nowrap",
      textAlign: "center",
    };
    return (
      <GridContainer style={{ padding: "15px" }}>
        {/* <ListItem xs={6}>
            <ListItemText
              primary={<Typography variant="caption">รหัสอ้างอิง</Typography>}
              secondary={this.state.refcode}>
            </ListItemText>
            <ListItemText
              primary={<Typography variant="caption">ชื่อโครงการ(ไทย)</Typography>}
              secondary={this.state.project_nameth}>
            </ListItemText>
            <ListItemText
              primary={<Typography variant="caption">ชื่อโครงการ(อังกฤษ)</Typography>}
              secondary={this.state.project_nameen}>
            </ListItemText>
            <ListItemText
              primary={<Typography variant="caption">สถานะโครงการ</Typography>}
              secondary={this.getProjectStatus()}>
            </ListItemText>
          </ListItem> */}
        <Snackbar
          place={this.state.place}
          color={this.state.color}
          message={this.state.message}
          open={this.state.open}
          closeNotification={() => this.setState({ open: false })}
          close
        />
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <h4>
            <b>ข้อมูลการขาย การโอนกรรมสิทธิ์ และการก่อสร้าง</b>
          </h4>
          {this.state.alert}
          {this.state.deleteAlert}
          <label>
            เปิดขายทั้งหมด{" "}
            <span>{numeral(this.state.unit_all).format("0,0")}</span> หน่วย
          </label>
          <MaterialTable
            style={{
              width: "100%",
              backgroundColor: "#fff",
              textAlign: "center",
            }}
            icons={{
              Add: () => (
                <div style={bt_style}>
                  <Add style={{ marginRight: "10px" }} />
                  เพิ่มข้อมูล
                </div>
              ),
            }}
            components={{
              Header: (props) => (
                <thead style={StyleTHeader}>
                  <tr>
                    <td
                      rowSpan="2"
                      style={{
                        border: "1px solid #dddddd",
                        textAlign: "center",
                        borderLeft: "none",
                      }}
                    >
                      ชื่ออาคาร
                    </td>
                    <td
                      rowSpan="2"
                      style={{
                        border: "1px solid #dddddd",
                        textAlign: "center",
                        borderLeft: "none",
                      }}
                    >
                      ประเภทชั้นของห้องชุด
                    </td>
                    <td
                      rowSpan="2"
                      style={{
                        border: "1px solid #dddddd",
                        textAlign: "center",
                        borderLeft: "none",
                      }}
                    >
                      ประเภทห้องชุด
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        border: "1px solid #dddddd",
                        textAlign: "center",
                        borderLeft: "none",
                      }}
                    >
                      พื้นที่ตามเอกสารสิทธิ์ (ตร.ม.)
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        border: "1px solid #dddddd",
                        textAlign: "center",
                        borderLeft: "none",
                      }}
                    >
                      พื้นที่ใช้สอยจริง (ตร.ม.)
                    </td>
                    <td colSpan="2" style={StyleHeader}>
                      ราคาขาย / ยูนิต (บาท)
                    </td>
                    <td rowSpan="2" style={StyleHeader}>
                      ส่วนลด
                    </td>
                    <td style={StyleHeader}>หน่วยในผังโครงการ</td>
                    <td
                      rowSpan="2"
                      style={{
                        border: "1px solid #dddddd",
                        textAlign: "center",
                        borderRight: "none",
                      }}
                    >
                      การจัดการ
                    </td>
                  </tr>
                  <tr>
                    <td style={StyleHeader}>ต่ำสุด</td>
                    <td style={StyleHeader}>สูงสุด</td>
                    <td style={StyleHeader}>ต่ำสุด</td>
                    <td style={StyleHeader}>สูงสุด</td>
                    <td style={StyleHeader}>ต่ำสุด</td>
                    <td style={StyleHeader}>สูงสุด</td>
                    <td style={StyleHeader}>
                      รวม {numeral(this.state.unit_all).format("0,0")} หน่วย
                    </td>
                  </tr>
                </thead>
              ),
            }}
            localization={{
              header: {
                actions: "การจัดการ",
              },
              body: {
                addTooltip: "เพิ่มข้อมูล",
                editTooltip: "แก้ไขข้อมูล",
                deleteTooltip: "ลบข้อมูล",
                emptyDataSourceMessage: "ไม่มีข้อมูล",
                editRow: {
                  deleteText: "ยืนยันการลบข้อมูล",
                  saveTooltip: "บันทึก",
                  cancelTooltip: "ยกเลิก",
                },
              },
              toolbar: {
                searchTooltip: "ค้นหา",
                searchPlaceholder: "ค้นหา",
              },
              pagination: {
                labelRowsSelect: "แถว",
                labelDisplayedRows: " {from}-{to} จาก {count}",
                firstTooltip: "หน้าแรก",
                previousTooltip: "ก่อนหน้า",
                nextTooltip: "ถัดไป",
                lastTooltip: "สุดท้าย",
              },
            }}
            title=""
            columns={this.state.column}
            data={this.state.stc_detail}
            options={{
              pageSize: this.state.stc_detail
                ? this.state.stc_detail.length
                : 10,
              pageSizeOptions: [
                10,
                20,
                30,
                this.state.stc_detail ? this.state.stc_detail.length : 50,
              ],
              actionsColumnIndex: -1,
              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.tableData.id % 2 === 0
                    ? "rgba(0, 0, 0, 0.03)"
                    : "#FFF",
              }),
              headerStyle: {
                fontSize: "0.9rem",
                fontWeight: "600",
              },
            }}
            editable={
              !(
                this.state.view_psurvey === true ||
                this.state.projectStatus === 1 ||
                this.state.projectStatus === 3 ||
                this.state.memberStatus === 2
              )
                ? {
                    onRowAdd: (newData) =>
                      new Promise((resolve, reject) => {
                        this.successAlert(
                          "ยืนยันการเพิ่มข้อมูล",
                          "หากมีการแก้ไข ข้อมูลส่วนที่ 4 จะถูกลบทั้งหมด",
                          {
                            status: "warning",
                            onConfirm: () => {
                              self.setState({ alert: null });
                              if (self.checkDataInRowBeforeAdd(newData)) {
                                setTimeout(() => {
                                  {
                                    const stc_detail = this.state.stc_detail;
                                    newData.id = stc_detail.length;
                                    stc_detail.push(newData);
                                    this.setState({ stc_detail }, async () => {
                                      await this.call_merge_sec3();
                                      resolve();
                                    });

                                    //count number of unit
                                    let unit_all = 0;
                                    for (
                                      let i = 0;
                                      i < stc_detail.length;
                                      i++
                                    ) {
                                      let val =
                                        stc_detail[i].sellroom_allunitinproject;
                                      if (val) {
                                        unit_all += parseInt(val);
                                      }
                                    }
                                    this.setState({ unit_all: unit_all });
                                  }

                                  resolve();

                                  this.props.condo_sec4Object([]);
                                }, 1000);
                              } else {
                                this.showNotification(
                                  "warning",
                                  "กรุณาตรวจสอบข้อมูลก่อนทำการบันทึก"
                                );
                                reject();
                              }
                            },
                            onCancel: () => {
                              reject();
                              this.setState({ alert: null });
                            },
                          }
                        );
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        this.successAlert(
                          "ยืนยันการแก้ไขข้อมูล",
                          "หากมีการแก้ไข ข้อมูลส่วนที่ 4 จะถูกลบทั้งหมด",
                          {
                            status: "warning",
                            onConfirm: () => {
                              self.setState({ alert: null });
                              // console.log('onRowUpdate')
                              setTimeout(() => {
                                if (self.checkDataInRowBeforeAdd(newData)) {
                                  // console.log(this.state.stc_detail)
                                  const stc_detail = this.state.stc_detail;
                                  const index = stc_detail.indexOf(oldData);
                                  stc_detail[index] = newData;
                                  this.setState({ stc_detail }, async () => {
                                    await this.call_merge_sec3();
                                    resolve();
                                  });

                                  // count number of unit
                                  let unit_all = 0;
                                  for (let i = 0; i < stc_detail.length; i++) {
                                    let val =
                                      stc_detail[i].sellroom_allunitinproject;
                                    if (val) {
                                      unit_all += parseInt(val);
                                    }
                                  }
                                  this.setState({ unit_all: unit_all });
                                } else {
                                  this.showNotification(
                                    "warning",
                                    "กรุณาตรวจสอบข้อมูลก่อนทำการบันทึก"
                                  );
                                  reject();
                                }

                                resolve();
                                // console.log("condo_sec4Object")
                                this.props.condo_sec4Object([]);
                                this.deleteSection();
                              }, 1000);
                            },
                            onCancel: () => {
                              reject();
                              this.setState({ alert: null });
                            },
                          }
                        );
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve, reject) => {
                        this.successAlert(
                          "ยืนยันการแก้ไขข้อมูล",
                          "หากมีการแก้ไข ข้อมูลส่วนที่ 4 จะถูกลบทั้งหมด",
                          {
                            status: "warning",
                            onConfirm: () => {
                              self.setState({ alert: null });
                              setTimeout(() => {
                                {
                                  let stc_detail = this.state.stc_detail;
                                  const index = stc_detail.indexOf(oldData);
                                  stc_detail.splice(index, 1);
                                  this.setState({ stc_detail }, async () => {
                                    await this.call_merge_sec3();
                                    resolve();
                                  });

                                  // count number of unit
                                  let unit_all = 0;

                                  for (let i = 0; i < stc_detail.length; i++) {
                                    let val =
                                      stc_detail[i].sellroom_allunitinproject;
                                    if (val) {
                                      unit_all += parseInt(val);
                                    }
                                  }
                                  this.setState({ unit_all: unit_all });
                                }
                                resolve();
                                this.props.condo_sec4Object([]);
                              }, 500);
                            },
                            onCancel: () => {
                              reject();
                              this.setState({ alert: null });
                            },
                          }
                        );
                      }),
                    onRowDataChange: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        this.successAlert(
                          "ยืนยันการแก้ไขข้อมูล",
                          "หากมีการแก้ไข ข้อมูลส่วนที่ 4 จะถูกลบทั้งหมด",
                          {
                            status: "warning",
                            onConfirm: () => {
                              self.setState({ alert: null });
                              // console.log('onRowDataChange')
                              setTimeout(() => {
                                {
                                  const stc_detail = this.state.stc_detail;
                                  const index = stc_detail.indexOf(oldData);
                                  stc_detail[index] = newData;
                                  this.setState({ stc_detail }, () => {
                                    resolve();
                                  });
                                }
                                resolve();
                                this.props.condo_sec4Object([]);
                              }, 500);
                            },
                            onCancel: () => {
                              reject();
                              this.setState({ alert: null });
                            },
                          }
                        );
                      }),
                  }
                : {}
            }
          />
        </GridItem>

        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
            padding: "0 15px",
            marginTop: "10px",
          }}
        >
          {(this.state.projectStatus !== 3 ||
            this.state.view_psurvey === false) &&
          this.isEditable() &&
          !this.isResubmiting() ? (
            <Button
              color="info"
              size="sm"
              style={{ fontSize: "16px" }}
              onClick={() => this.submit_save()}
              disabled={this.state.btLoading}
            >
              {this.loadingButton(this.state.btLoading)}
              บันทึก
            </Button>
          ) : null}
        </div>
      </GridContainer>
    );
  }
}

CondoStep3.propTypes = {
  classes: PropTypes.object,
  condoSec1Object: PropTypes.any,
  condoSec2Object: PropTypes.any,
  condoSec3_Object: PropTypes.any,
  condo_initial_data: PropTypes.any,
  setBuildingAndUnit_sec2: PropTypes.any,
  setBuildingAndUnit_sec3: PropTypes.any,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  surveyInfo: (payload) => dispatch(surveyInfo(payload)),
  condo_sec2Object: (payload) => dispatch(condo_sec2Object(payload)),
  condoSec2_Object: (payload) => dispatch(condoSec2_Object(payload)),
  condo_sec3Object: (payload) => dispatch(condo_sec3Object(payload)),
  condo_sec4Object: (payload) => dispatch(condo_sec4Object(payload)),
  condoSec3_Object: (payload) => dispatch(condoSec3_Object(payload)),
  setBuildingAndUnit_sec2: (payload) =>
    dispatch(setBuildingAndUnit_sec2(payload)),
  setBuildingAndUnit_sec3: (payload) =>
    dispatch(setBuildingAndUnit_sec3(payload)),
  surveyModal: (payload) => dispatch(surveyModal(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(regularFormsStyle)(CondoStep3));
