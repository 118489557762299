/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import Recaptcha from "react-recaptcha";
import { connect } from "react-redux";
import * as config from "configure/configServer.js";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import SweetAlert from "react-bootstrap-sweetalert";
import { ClipLoader } from "react-spinners";

import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

// GHBank assets
import GHBankLogo from "assets/img/psurveylogo.svg";

// switch language function
import switchIntl from "configure/switchIntl.js";

class ForgetPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      alert: null,
      show: false,
      // reset password form
      resetEmail: "",
      resetEmailState: "",
      resetCaptcha: "",
      resetCaptchaState: "",
      // bt loading
      btLoading: false,
      disButton: false,
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.recaptchaCallback = this.recaptchaCallback.bind(this);
    this.checkbutton = this.checkbutton.bind(this);
    this.validateLogin = this.validateLogin.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }

  static propTypes = {
    toggleLng: PropTypes.string.isRequired,
  };

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }

  recaptchaCallback() {
    return null;
  }

  checkbutton() {
    // console.log("resetEmail => " + this.state.resetEmail);
  }

  // === start of handle input forms ===
  change(event, stateName, type) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [stateName + "State"]: "success",
            [stateName]: event.target.value,
          });
        } else {
          this.setState({ [stateName + "State"]: "error", [stateName]: null });
        }
        break;
      default:
        break;
    }
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // handle trigger function
  validateLogin() {
    if (this.state.resetEmailState === "") {
      this.setState({ resetEmailState: "error" });
    }
    if (this.state.resetCaptcha === "") {
      this.setState({ resetCaptchaState: "error" });
    }
    if (
      this.state.resetEmailState === "success" &&
      this.state.resetCaptchaState === "success"
    ) {
      this.requestResetPassword();
    } else {
      this.dynamicAlert(
        "warning",
        switchIntl(this.props.toggleLng).sweetalert.resetCaptcha.title,
        switchIntl(this.props.toggleLng).sweetalert.resetCaptcha.content,
        switchIntl(this.props.toggleLng).sweetalert.resetCaptcha.conBtTxt,
        "",
        false,
        this.hideAlert
      );
    }
  }

  // start of recaptcha callback function
  recaptchaCallback = () => {
    return null;
  };
  verifyCallback = (recaptchaToken) => {
    if (recaptchaToken != null) {
      this.setState({
        resetCaptcha: recaptchaToken,
        resetCaptchaState: "success",
      });
    }
  };
  expiredCallback = () => {
    this.setState({ resetCaptcha: "", resetCaptchaState: "error" });
  };
  // end of recaptcha callback function

  // start of dynamic alert handled
  dynamicAlert(
    alertType,
    title,
    content,
    conTxt,
    canTxt,
    showCancel,
    confirmFunction,
    cancelFunction
  ) {
    this.setState({
      alert: (
        <SweetAlert
          type={alertType}
          style={{
            display: "block",
            color: "black",
            fontSize: "18px",
          }}
          customClass="modalContent"
          title={title}
          onConfirm={confirmFunction}
          onCancel={cancelFunction}
          confirmBtnCssClass="modalBt"
          cancelBtnCssClass="modalBt modalBtCancel"
          confirmBtnText={conTxt}
          cancelBtnText={canTxt}
          showCancel={showCancel}
        >
          {content}
        </SweetAlert>
      ),
    });
  }
  hideAlert() {
    this.setState({
      alert: null,
    });
  }
  // end of dynamic alert handled

  // loading function
  loadingButton(isLoading) {
    if (isLoading) {
      return (
        <ClipLoader
          css={"margin-right: 10px;"}
          sizeUnit={"px"}
          size={20}
          color={"#ffffff"}
          loading={this.state.loading}
        />
      );
    } else {
      return null;
    }
  }
  // end of loading function

  // request reset password
  requestResetPassword() {
    this.setState({ btLoading: true, disButton: true });
    var body = {
      member_email: this.state.resetEmail,
    };

    var request = new Request(config.API_sendresetpassword, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    });

    fetch(request)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ btLoading: false, disButton: false });
        if (responseJson.code === 200) {
          this.dynamicAlert(
            "success",
            switchIntl(this.props.toggleLng).sweetalert.resetPass.title,
            switchIntl(this.props.toggleLng).sweetalert.resetPass.content,
            switchIntl(this.props.toggleLng).sweetalert.resetPass.conBtTxt,
            "",
            false,
            this.hideAlert
          );
        } else {
          this.dynamicAlert(
            "error",
            switchIntl(this.props.toggleLng).sweetalert.noAccountReset.title,
            switchIntl(this.props.toggleLng).sweetalert.noAccountReset.content,
            switchIntl(this.props.toggleLng).sweetalert.noAccountReset.conBtTxt,
            "",
            false,
            this.hideAlert
          );
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }
  // end of request reset password

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        {this.state.alert}
        <GridContainer justify="center" style={{ marginBottom: "20px" }}>
          <GridItem xs={12} sm={6} md={4}>
            <img src={GHBankLogo} width="100%" alt="ghlogo" />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  style={{ background: "#283F88" }}
                  color="info"
                >
                  <h4 className={classes.cardTitle}>
                    {switchIntl(this.props.toggleLng).resetpassword.header}
                  </h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    success={this.state.resetEmailState === "success"}
                    error={this.state.resetEmailState === "error"}
                    labelText={
                      switchIntl(this.props.toggleLng).login.emailInput
                    }
                    id="resetEmail"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) =>
                        this.change(event, "resetEmail", "email"),
                      type: "email",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div className="recaptchaContainer">
                    <Recaptcha
                      // hl={this.props.toggleLng}
                      sitekey={config.recaptchaKey}
                      render="explicit"
                      onloadCallback={this.recaptchaCallback}
                      verifyCallback={this.verifyCallback}
                      expiredCallback={this.expiredCallback}
                    />
                  </div>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <NavLink
                    to={"#"}
                    style={{ width: "100%", paddingRight: "5px" }}
                    onClick={this.state.disButton ? null : this.validateLogin}
                  >
                    <Button
                      size="lg"
                      fullWidth={true}
                      className="fixedBTColorLogin"
                      disabled={this.state.disButton}
                    >
                      {this.loadingButton(this.state.btLoading)}
                      {switchIntl(this.props.toggleLng).resetpassword.resetBt}
                    </Button>
                  </NavLink>
                  <NavLink
                    to={"/auth/login-page"}
                    style={{ width: "100%", paddingLeft: "5px" }}
                  >
                    <Button
                      simple
                      size="lg"
                      fullWidth={true}
                      className="fixedBTColorReg"
                    >
                      {switchIntl(this.props.toggleLng).resetpassword.backBT}
                    </Button>
                  </NavLink>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ForgetPasswordPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(
  withStyles(loginPageStyle)(ForgetPasswordPage)
);
